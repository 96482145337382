import { useReducer } from 'react';

import { appCustomerDesignsContextSlice } from './customer-designs/context-slice';

// Define the state type
interface State {
	customerDesigns: ReturnType<typeof appCustomerDesignsContextSlice.reducer>
}

// Define the action type
type AppActions = appCustomerDesignsContextSlice.Actions;

export const AppActionTypes = {
	...appCustomerDesignsContextSlice.ActionTypes
};

const mainReducer = (state: State, action: AppActions): State => {
	return {
		customerDesigns: appCustomerDesignsContextSlice.reducer(state.customerDesigns, action as appCustomerDesignsContextSlice.Actions)
	};
};

export const initializeContext = () => {
	const [appState, appDispatch] = useReducer(mainReducer, {
		customerDesigns: appCustomerDesignsContextSlice.stateInitializer()
	});

	// console.log('context: initializeContext', appState);
	return { appState, appDispatch };
};
