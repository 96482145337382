export const actionPanels = {
	title: {
		home: 'Home',
		island: 'Islands',
		crm: 'HubSpot',
		navigation: 'Navigation'
	},
	tooltip: {
	},
	message: {
	}
};
