/* eslint-disable max-lines-per-function */
import { ComponentController as BpController } from '@rta/rta-blueprint-component';
import { BlueprintInstanceType, CornerSize, Island, Panel, Size, useSubscription } from '@rta/rta-blueprint-library';
import { Box, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { ContextPanelContent } from './context-panel-content';
import { InlineNumberInput } from './controls';
import { lang } from '@lang';
import { useDesign } from '@hooks';

export type IslandPropertiesPanelProps = {
	id: string | null;
};

export const IslandPropertiesPanel = ({ id }: IslandPropertiesPanelProps): JSX.Element => {
	const [controller] = useDesign<BpController>();
	const [island, setIsland] = useState<Island | null>(null);
	const [standardSize, setStandardSize] = useState<Size>();
	const [cornerSize, setCornerSize] = useState<CornerSize>();

	const readonly = island?.sizeLocked ?? true;

	useEffect(() => {
		if (controller) {
			let refMap = controller.kitchen?.getReferenceMap(id ?? '');
			if (refMap.type === BlueprintInstanceType.Countertop) refMap = controller.kitchen?.getReferenceMap(refMap.countertop.islandIds[0]);
			setIsland(refMap.island);
			if (refMap.island.isCornerIsland) {
				setCornerSize(refMap.island.size as CornerSize);
			} else {
				setStandardSize(refMap.island.size as Size);
			}
		}
	}, [controller, id]);

	if (!controller || !island || !(standardSize || cornerSize)) return <></>;

	const { kitchen } = controller;
	const islands = kitchen?.design.islands.children ?? [];
	const leftPanel: Panel | null = island.frontPanel.getLeftPanel();
	const rightPanel: Panel | null = island.frontPanel.getRightPanel();

	const width = island.isCornerIsland ? cornerSize?.width : standardSize?.width;
	const height = island.isCornerIsland ? cornerSize?.height : standardSize?.height;

	const depthInputs = island.isCornerIsland
		? <>
			<InlineNumberInput
				label="Left Depth" min={2} max={240}
				enabled={islands.length > 0 && !island.sizeLocked}
				// value={ cornerSize?.depth.left ?? 0 }
				value={ (leftPanel?.size.width ?? 0) + island.frontPanel.thickness}
				onChange={(valueString) => {
					if (!cornerSize) return;
					setCornerSize({ ...cornerSize, depth: { ...cornerSize.depth, left: parseFloat(valueString) }} as CornerSize);
					island.size = { depth: { left: parseFloat(valueString) }};
				}}
			/>
			<InlineNumberInput
				label="Right Depth" min={2} max={240}
				enabled={islands.length > 0 && !island.sizeLocked}
				value={ (rightPanel?.size.width ?? 0) + island.frontPanel.thickness}
				// value={ cornerSize?.depth.right ?? 0 }
				onChange={(valueString) => {
					if (!cornerSize) return;
					setCornerSize({ depth: { ...cornerSize.depth, right: parseFloat(valueString) }} as CornerSize);
					island.size = { depth: { right: parseFloat(valueString)	}};
				}}
			/>
		</>
		: <>
			<InlineNumberInput
				label="Depth" min={2} max={240}
				enabled={islands.length > 0 && !island.sizeLocked}
				value={ standardSize?.depth ?? 0 }
				onChange={(valueString) => {
					if (!standardSize) return;
					setStandardSize({ ...standardSize, depth: parseFloat(valueString) });
					island.size = { depth: parseFloat(valueString) };
				}}
			/>
		</>;

	return <ContextPanelContent title={lang.contextPanels.title.islandProperties}>
		<Box width="100%" mb="5px" mt="5px" padding="5px" textAlign="left" borderLeft="1px solid grey" borderBottom="1px solid darkgrey">
			<Stack spacing="12px">
				<InlineNumberInput
					label="Width" min={2} max={240}
					enabled={islands.length > 0 && !island.sizeLocked}
					value={ width ?? 0 }
					onChange={(valueString) => {
						if (island.isCornerIsland && cornerSize) {
							setCornerSize({ ...cornerSize, width: parseFloat(valueString) });
							island.size = { width: parseFloat(valueString) };
						} else if (standardSize) {
							setStandardSize({ ...standardSize, width: parseFloat(valueString) });
							island.size = { width: parseFloat(valueString) };
						}
					}}
				/>
				{ depthInputs }
				<InlineNumberInput
					label="Height" min={2} max={60}
					enabled={islands.length > 0}
					value={ height ?? 0 }
					onChange={(valueString) => {
						if (island.isCornerIsland && cornerSize) {
							setCornerSize({ ...cornerSize, height: parseFloat(valueString) });
							island.size = { height: parseFloat(valueString) };
						} else if (standardSize) {
							setStandardSize({ ...standardSize, height: parseFloat(valueString) });
							island.size = { height: parseFloat(valueString) };
						}
					}}
				/>
			</Stack>
		</Box>
	</ContextPanelContent>;
};
