import { TbLetterK } from 'react-icons/tb';

import { lang } from '@lang';
import { useDispatch } from 'react-redux';
import { ContentPanelMode, ContextPanelMode, pushContextPanel, selectContentPanelMode, useAppSelector } from '@state';
import { Feature, FeatureHoookParams } from '..';

type UseKitchenPropertiesFeatureParams = FeatureHoookParams;
export const useKitchenPropertiesFeature = (_params: UseKitchenPropertiesFeatureParams = {}): Feature<null> => {
	const dispatch = useDispatch();
	const contentMode: ContentPanelMode = useAppSelector(selectContentPanelMode);

	const [disabled, tooltip] = (() => {
		if (contentMode === ContentPanelMode.Designer || contentMode === ContentPanelMode.ThreeD) return [false, lang.view.tooltip.kitchenProperties];
		return [true, lang.view.tooltip.onlyOnComponent];
	})();

	return {
		name: 'kitchen-properties',
		label: lang.view.label.kitchenProperties,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <TbLetterK {...props} />,
		disabled,
		value: null,
		hidden: false,
		handler: () => {
			dispatch(pushContextPanel({ mode: ContextPanelMode.KitchenProperties }));
		}
	};
};
