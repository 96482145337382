import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type IslandOverlapFeatureParams = FeatureHoookParams;
export const useShowIslandOverlapFeature = (_params: IslandOverlapFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, { options: { showOverlap: boolean }}>();
	useSubscription(controller, false, [['layer-option-overlap', '*']]);

	const controllerSupportsFeature = (controller?.options?.showOverlap ?? null) !== null;
	const showLayer = controller?.options?.showOverlap ?? true;

	const tooltip = (() => {
		const showHide = showLayer ? lang.layer.tooltip.hide : lang.layer.tooltip.show;
		return `${showHide} ${lang.layer.label.islanadOverlaps} ${lang.layer.layer}`;
	})();

	return {
		name: 'showIslandOverlaps',
		label: lang.layer.label.islanadOverlaps,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled: !controllerSupportsFeature,
		value: showLayer,
		hidden: false,
		handler: () => {
			if (controller) {
				controller.options.showOverlap = !showLayer;
			}
		}
	};
};
