/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
import { useDispatch } from 'react-redux';
import { Box, Center, FormLabel, Image, Input, Select, Stack } from '@chakra-ui/react';
import { Component, ReferenceMap, Vertex2Data } from '@rta/rta-blueprint-library';
import { useEffect, useState } from 'react';

import { calculatePriceDelta } from './helpers';
import { ContextPanelContent } from './context-panel-content';
import { formatPrice } from '@helpers';
import { lang } from '@lang';
import { setContextPanelIsOpen } from '@state';
import { useDesign } from '@hooks';

interface AddAppliancePanelProps {
	id: string | null;
	position?: Vertex2Data;
}

export const AddAppliancePanel: React.FC<AddAppliancePanelProps> = (props: AddAppliancePanelProps): JSX.Element => {
	const { id, position = { x: 0, y: 0 }} = props;

	const dispatch = useDispatch();
	const [controller] = useDesign();

	const kitchen = controller?.kitchen ?? null;
	const vendors = kitchen?.context.vendors;
	const categories = kitchen?.context.categories;
	const components = kitchen?.context.components ?? [];

	const [refMap, setRefMap] = useState<ReferenceMap | null>(null);
	const [vendorId, setVendorId] = useState('');
	const [categoryId, setCategoryId] = useState('');
	const [applianceList, setApplianceList] = useState<Array<Component>>([]);
	const [searchAppliance, setSearchAppliance] = useState('');

	useEffect(() => {
		if (kitchen === null || id === null) return;

		// get the reference map for the provided id
		const map = kitchen?.getReferenceMap(id);
		if (map && map.id === id) {
			setRefMap(map);

			// default category and vendor to the current panel item, if passed
			if (map.panelItem) {
				setVendorId(map.panelItem.item.vendor.id);
				setCategoryId(map.panelItem.item.category.id);
			}
		}
	}, [kitchen, id]);

	useEffect(() => {
		if (refMap === null || refMap.panel === null) return;

		// get all appliances that are active and match the selected vendor and category
		const updatedList: Array<Component> = [];
		const { panel, panelItem } = refMap;

		// filter the list of appliances based on the selected vendor and category
		// note: array.filter didn't work here, so we're using a forEach loop
		components.forEach((component) => {
			const isCategoryActive = component.category?.active ?? false;
			const isVendorActive = component.vendor?.active ?? true;
			const isSkuActive = component.active ?? false;
			const isPlacementMatch = panelItem ? component.placement === panelItem.item.placement : true;
			const isVendorMatch = !vendorId || component.vendor?.id === vendorId;
			const isCategoryMatch = !categoryId || component.category?.id === categoryId;
			const isSearchMatch = !searchAppliance || component.description.toLowerCase().includes(searchAppliance.toLowerCase()) || component.sku.toLowerCase().includes(searchAppliance.toLowerCase());
			const showSkuItem = isSkuActive && isCategoryActive && isVendorActive && isPlacementMatch && isVendorMatch && isCategoryMatch && isSearchMatch;
			if (showSkuItem) updatedList.push(component);
		});

		setApplianceList(updatedList);
	}, [vendorId, categoryId, refMap, searchAppliance]);


	if (!kitchen || !refMap || !refMap.panel) return <></>;
	// console.log('render:AddAppliancePanel', id, position, refMap.panel, refMap.panelItem);

	const title = refMap.panelItem ? lang.contextPanels.title.swapAppliance : lang.contextPanels.title.addAppliance;

	return <ContextPanelContent title={title}>
		<Stack spacing="12px" p="5px" width="100%">
			<Box>
				<FormLabel htmlFor="vendor-filter" fontSize="small">Vendor :</FormLabel>
				<Select id="vendor-filter" placeholder="All" fontSize="small"
					value={vendorId}
					onChange={(e) => { setVendorId(e.target.value); }}
				>
					{vendors && vendors
						.filter((v) => v.active)
						.map((v, i) => <option key={i} value={v.id}>{v.displayValue}</option>)
					}
				</Select>
			</Box>
			<Box>
				<FormLabel htmlFor="category-filter" fontSize="small">Category :</FormLabel>
				<Select id="category-filter" placeholder="All" fontSize="small"
					value={categoryId}
					onChange={(e) => { setCategoryId(e.target.value); }}
				>
					{categories && categories.getActiveEntries()
						.map((c, i) => <option key={i} value={c.id}>{c.description}</option>)
					}
				</Select>
			</Box>
			<Box>
				<FormLabel htmlFor="appliance-search" fontSize="small">Search :</FormLabel>
				<Input id="appliance-search" placeholder="Search Appliance" fontSize="small" size="sm" value={searchAppliance} onChange={(e) => { console.log(e.target.value); setSearchAppliance(e.target.value); }} />
			</Box>
			{ applianceList.map((skuItem, i) => <Box key={i} maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" p="2">
				<Center key={i}>{
					skuItem.svg?.item
						? <svg
							height="100px"
							viewBox={`0 0 ${skuItem.size.width} ${skuItem.size.height}`}
							onDoubleClick={() => {
								if (refMap && !refMap.panelItemGroup && refMap.panel) {
									((rMap, payload) => {
										const panelItemGroup = rMap.panel.panelItemGroups.new();
										panelItemGroup.center.x = position.x;
										panelItemGroup.items.newFromSku(payload.sku);
										panelItemGroup.centerItemsVertically();
									})(refMap, { sku: skuItem.sku });
								} else if (refMap && refMap.panelItemGroup && refMap.panel) {
									const panelItem = refMap.panelItemGroup.items.newFromSku(skuItem.sku);
									// if refMap is PanelItem then we are in swap, delete old item.
									if (refMap.panelItem) {
										panelItem.center.y = refMap.panelItem.center.y;
										refMap.panelItemGroup.items.delete(refMap.panelItem.id);
									} else {
										panelItem.center.y = -position.y;
									}
									refMap.panelItemGroup.centerItemsVertically();
								}


								// Close Drawer
								dispatch(setContextPanelIsOpen(false));
							}}
						>{ skuItem.svg.item({ x: 0, y: 0 })}</svg>
						: <Image alt={skuItem.sku}
							src={`${process.env.REACT_APP_ASSETS_BASE_URL}/products/${skuItem.sku}.png`}
							h="100px"
							align="center"
							onDoubleClick={() => {
								if (refMap && !refMap.panelItemGroup && refMap.panel) {
									((rMap, payload) => {
										const panelItemGroup = rMap.panel.panelItemGroups.new();
										panelItemGroup.center.x = position.x;
										panelItemGroup.items.newFromSku(payload.sku);
										panelItemGroup.centerItemsVertically();
									})(refMap, { sku: skuItem.sku });
								} else if (refMap && refMap.panelItemGroup && refMap.panel) {
									const panelItem = refMap.panelItemGroup.items.newFromSku(skuItem.sku);
									// if refMap is PanelItem then we are in swap, delete old item.
									if (refMap.panelItem) {
										panelItem.center.y = refMap.panelItem.center.y;
										refMap.panelItemGroup.items.delete(refMap.panelItem.id);
									} else {
										panelItem.center.y = -position.y;
									}
									refMap.panelItemGroup.centerItemsVertically();
								}
								// Close Drawer
								dispatch(setContextPanelIsOpen(false));
							}}
						/>
				}</Center>
				<Box p="2">
					<Box
						color="gray.500"
						fontWeight="semibold"
						letterSpacing="wide"
						fontSize="xs"
						textTransform="uppercase"
						ml="2">
						{skuItem.sku}
					</Box>
					<Box
						color="gray.500"
						fontWeight="semibold"
						letterSpacing="wide"
						fontSize="xs"
						textTransform="uppercase"
						ml="2">
						w {skuItem.size.width}{'"'} / h {skuItem.size.height}{'"'} / d {skuItem.size.depth}{'"'}
					</Box>
					<Box
						color="gray.500"
						fontWeight="semibold"
						letterSpacing="wide"
						fontSize="xs"
						textTransform="uppercase"
						ml="2">
						{formatPrice(skuItem.msrp)} { refMap.panelItem && (
							(() => {
								const { delta, color } = calculatePriceDelta(skuItem.msrp, refMap.panelItem.item.msrp);
								const formattedDelta = Math.abs(delta);
								const sign = delta > 0 ? '+' : '-';
								const isCurrentItem = skuItem.id === refMap.panelItem.item.id;
								return (
									<span style={{ color, marginLeft: '4px' }}>
											[{isCurrentItem ? 'current item' : `${sign}${formatPrice(formattedDelta)}`}]
									</span>
								);
							})()
						)}
					</Box>
					<Box
						mt="1"
						fontWeight="semibold"
						as="h4"
						lineHeight="tight"
					>
						{skuItem.description}
					</Box>
				</Box>
			</Box>)}
		</Stack>
	</ContextPanelContent>;
};
