import type { PayloadAction, ReducerCreators } from '@reduxjs/toolkit';

import { createAppSlice } from '../create-app-slice';
import { customerFeatureStateSlice } from '@features/customer';
import { State } from './state.type';
import { stateInitializer } from './state-initializer';
import { ViewMode } from '@features/view/view-mode.enum';

// If you are not using async thunks you can use the standalone `createSlice`.
export const slice = createAppSlice({
	name: 'feature',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState: stateInitializer(),
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: (create) => ({
		setViewMode: create.reducer((state, action: PayloadAction<ViewMode>) => {
			state.viewMode.viewMode = action.payload;
		}),
		setAutoZoom: create.reducer((state, action: PayloadAction<boolean>) => {
			state.zoom.autoZoom = action.payload;
		}),
		setPromotionalSkuDropdown: create.reducer((state, action: PayloadAction<boolean>) => {
			state.promotionalSku.showPromotionalDropdown = action.payload;
		}),
		setSelectedPromotionalSku: create.reducer((state, action: PayloadAction<string[]>) => {
			state.promotionalSku.selectedPromotionalSku = action.payload;
		}),
		setRemovedPromotionalSku: create.reducer((state, action: PayloadAction<string>) => {
			state.promotionalSku.removedPromotionalSku = action.payload;
		}),
		setPricePromotionalSku: create.reducer((state, action: PayloadAction<any[]>) => {
			state.promotionalSku.pricePromotionalSku = action.payload;
		}),
		...(customerFeatureStateSlice.reducers<State>(create))
	}),
	// You can define your selectors here. These selectors receive the slice
	// state as their first argument.
	selectors: {
		...customerFeatureStateSlice.selectors,
		selectFeatureState: (state) => state,
		selectFeatureLayerState: (state) => state.layer,
		selectFeatureValidationState: (state) => state.validation,
		selectGetNewCustomerData: (state) => state.customer.modals.getNewCustomerData,
		selectZoomFeatureState: (state) => state.zoom,
		selectViewMode: (state) => state.viewMode.viewMode,
		selectAutoZoom: (state) => state.zoom.autoZoom,
		selectPromotionalSkuDropdown: (state) => state.promotionalSku.showPromotionalDropdown,
		selectSelectedPromotionalSku: (state) => state.promotionalSku.selectedPromotionalSku,
		selectRemovedPromotionalSku: (state) => state.promotionalSku.removedPromotionalSku,
		selectPriceChangePromotionalSku: (state) => state.promotionalSku.pricePromotionalSku
	}
});
