export const zoom = {
	label: {
		autozoom: 'Auto Zoom',
		recenter: 'Center Kitchen',
		zoomIn: 'Zoom In',
		zoomOut: 'Zoom Out'
	},
	tooltip: {
		autozoom: 'Auto Zoom',
		disabledNotSupportCurrentView: 'Auto Zoom is not supported in the current view',
		recenter: 'Center Kitchen',
		zoomIn: 'Zoom In',
		zoomOut: 'Zoom Out'
	},
	message: {
		minZoomAcheived: 'Minimum zoom level achieved',
		maxZoomAcheived: 'Maximum zoom level achieved',
		disabled4AutoZoom: 'Zoom is disabled when Auto Zoom is enabled'
	}
};
