export const social = {
	label: {
		shareLink: 'Share Link',
		generateDxf: 'Generate DXF'
	},
	tooltip: {
		noEmail: 'Customer email is required to share link',
		noDesign: 'Design Selection is required to share link',
		shareLink: 'Copy a shareable view-only link to the clipboard',
		snapshot: 'Take a Snapshot',
		generateDxf: 'Generate DXF file',
		noGenerateDxf: 'Design Selection is required to generate DXF file'
	},
	message: {
		copiedToClipboard: 'Link copied to clipboard!',
		downloadSnapshot: 'Snapshot download started!',
		generateDxf: 'DXF file generation started!'
	}
};
