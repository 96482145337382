import { lang } from '@lang';

import { Feature, FeatureHoookParams } from '..';
import { selectPromotionalSkuDropdown, setPromotionalSkuDropdown, useAppDispatch, useAppSelector } from '@state';

type PromotionalSkuFeatureParams = FeatureHoookParams;
export const useShowPromotionalSkuDropdownFeature = (_params: PromotionalSkuFeatureParams = {}): Feature<boolean> => {
	const dispatch = useAppDispatch();
	const promotionalSkuDropdown = useAppSelector(selectPromotionalSkuDropdown);

	const [disabled, tooltip, label] = (() => {
		if (!promotionalSkuDropdown) return [false, lang.promotionalSku.tooltip.addPromotionalSku, lang.promotionalSku.label.promotionalSku];
		return [true, lang.promotionalSku.tooltip.hidePromotionalSku, lang.promotionalSku.label.hidePromotionalSku];
	})();

	return {
		name: 'show-promotional-sku-dropdown',
		label,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled,
		value: true,
		hidden: false,
		handler: () => {
			dispatch(setPromotionalSkuDropdown(!promotionalSkuDropdown));
		}
	};
};
