import { MdOutlineZoomIn } from 'react-icons/md';
import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { ControllerAutoZoomSupport } from './types';
import { defaultConfig } from './default-config';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type ZoomInFeatureParams = FeatureHoookParams;
export const useZoomInFeature = (_params: ZoomInFeatureParams = {}): Feature<number> => {
	const [controller] = useDesign<Controller, ControllerAutoZoomSupport>();

	const controllerSupportsAutozoomFeature = (controller?.options?.autoZoom ?? null) !== null;
	const autozoom = controllerSupportsAutozoomFeature ? controller?.options.autoZoom ?? false : false;

	useSubscription(controller, false, [['zoom', '*']]);

	const [enabled, tooltip] = (() => {
		if (autozoom) return [false, lang.zoom.message.disabled4AutoZoom];
		if (!controller || controller.zoom >= defaultConfig.maxZoom) return [false, lang.zoom.message.maxZoomAcheived];
		return [true, lang.zoom.tooltip.zoomIn];
	})();

	return {
		name: 'zoomIn',
		label: lang.zoom.tooltip.zoomIn,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <MdOutlineZoomIn {...props} />,
		disabled: !enabled,
		value: controller?.zoom ?? 0,
		hidden: false,
		handler: () => {
			const newZoom = (controller?.zoom ?? 100) + defaultConfig.zoomStep;
			if (controller) controller.zoom = newZoom > defaultConfig.maxZoom ? defaultConfig.maxZoom : newZoom;
		}
	};
};
