import { Image, ImageProps, useColorMode } from '@chakra-ui/react';

import logoDark from '@assets/logo/logo.dark.svg';
import logoLight from '@assets/logo/logo.light.svg';

export const Logo = (props: ImageProps): JSX.Element => {
	const { colorMode } = useColorMode();

	return (
		<Image
			{...props}
			display="inline-block"
			margin="0 0 0 0"
			// height={'45px'}
			// htmlWidth="100px"
			objectFit="cover"
			src={ colorMode === 'light' ? logoLight : logoDark }
			alt="RTA Outdoor Solutions Logo"
		/>
	);
};
