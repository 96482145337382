import { ComponentController as BpController } from '@rta/rta-blueprint-component';
import { useSubscription } from '@rta/rta-blueprint-library';
import { useContext, useEffect, useState } from 'react';

import { lang } from '@lang';
import { useDesign } from '@hooks';
import { AppContext, DesignActionTypes } from '@context';
import { Feature, FeatureHoookParams } from '..';
import { SaveDirtyKitchenLocally, saveDirtyKitchenLocally } from './helpers';
import { selectNavigation, useAppSelector } from '@state';

type UseAutoSaveFeatureParams = FeatureHoookParams & {
	subscribe?: boolean;
};

export const useAutoSaveFeature = (params: UseAutoSaveFeatureParams = {}): Feature<SaveDirtyKitchenLocally | null> => {
	const { subscribe = true } = params;
	const [controller] = useDesign();

	const { appDispatch } = useContext(AppContext);
	const appNav = useAppSelector(selectNavigation);

	const [value, setValue] = useState<SaveDirtyKitchenLocally | null>(null);

	const autoSaveId = controller?.kitchen?.context?.blueprint?.id ?? '';

	// console.log('useAutoSaveFeature', { designData, autoSaveId, controller });

	const [updateEvent] = useSubscription(controller?.kitchen ?? null, false, (subscribe
		? [
			['*', 'added'],
			['*', 'deleted'],
			['*', 'updated'],
			['*', 'moved'],
			['panel_item', 'loaded'],
			['breakpoint', 'loaded']
		]
		: []
	));

	// save to local storage on every update
	useEffect(() => {
		if (updateEvent) {
			if (controller && appNav.designId !== null) {
				appDispatch({ type: DesignActionTypes.setIsDirty, payload: { id: appNav.designId, isDirty: true }});
				setValue(saveDirtyKitchenLocally(appNav.email, controller as BpController, false));
			}
		}
	}, [updateEvent]);

	useEffect(() => {
		const savedValue = localStorage.getItem(`autosave-${autoSaveId}`) ?? null;
		if (savedValue) setValue(JSON.parse(savedValue));
	}, []);

	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		if (design?.isDirty && !saveFeature.disabled) {
	// 			saveFeature.handler();
	// 			if (appNav.designId !== null) {
	// 				appDispatch({ type: DesignActionTypes.setIsDirty, payload: { id: appNav.designId, isDirty: false }});
	// 			}
	// 		}
	// 	}, 10000);
	// 	return () => clearInterval(interval);
	// }, [design?.isDirty]);

	return {
		name: 'autosaveFeature',
		label: lang.customer.label.autoSaveSubmission,
		shortcut: '',
		tooltip: lang.customer.label.autoSaveSubmission,
		icon: () => <></>,
		disabled: false,
		value,
		hidden: false,
		handler: () => {
			localStorage.removeItem(`autosave-${autoSaveId}`);
			setValue(null);
		}
	};
};
