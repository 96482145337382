import axios from 'axios';

import { emptyPagedDataRecord } from './helpers';
import { Customer, ExternalError, PagedData } from './types';

export type FetchCustomerParams = {
	filter: string,
	days?: string,
	limit?: number,
	getAccessTokenSilently: () => Promise<string>,
	setData?: (data: PagedData<Customer>) => void,
	setError?: (error: string) => void
};

export const fetchCustomers = async (params: FetchCustomerParams): Promise<[PagedData<Customer> | null, ExternalError | null]> => {
	const { getAccessTokenSilently, filter, days = 0, limit = 20, setData, setError } = params;

	console.log('external:fetchCustomer', filter, days, limit);

	if (filter !== '') {
		const token = await getAccessTokenSilently();
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/customers?filter=${filter}&days=${days}&limit=${limit}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).catch((error) => {
			console.warn('fetchBlueprints (failed)----', error.response);
			return error.response;
		});

		if (response.status === 200) {
			if (setData) setData(response.data);
			return [response.data, null];
		} else if (response.status === 204) {
			if (setData) setData(emptyPagedDataRecord<Customer>());
			return [emptyPagedDataRecord<Customer>(), null];
		}

		if (setError) setError('Unable to fetch blueprints for the selected customer.');
		return [null, { status: response.status, message: response.data.message }];
	}

	return [null, null];
};


