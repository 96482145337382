import { customerFeatureStateSlice } from '@features/customer';
import { defaultState as defaultDebugState } from '@features/debug/default-state';
import { defaultState as defaultLayerState } from '@features/layer/default-state';
import { defaultState as defaultNavigationState } from '@features/navigation/default-state';
import { defaultState as defaultPromotionalSkuState } from '@features/promotional-sku/default-state';
import { defaultState as defaultValidationState } from '@features/validation/default-state';
import { defaultState as defaultViewModeState } from '@features/view/default-state';
import { defaultState as defaultZoomState } from '@features/zoom/default-state';


import { State } from './state.type';

export const stateInitializer = (): State => {
	return {
		customer: customerFeatureStateSlice.initialState,
		debug: defaultDebugState,
		layer: defaultLayerState,
		navigation: defaultNavigationState,
		validation: defaultValidationState,
		viewMode: defaultViewModeState,
		zoom: defaultZoomState,
		promotionalSku: defaultPromotionalSkuState
	};
};
