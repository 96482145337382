import { Box, useBreakpointValue, useTheme } from '@chakra-ui/react';

import { dockConfig } from '../../../config';
import { HeaderBar } from '../../bars/header-bar';
import { OptionsBar } from '../../bars/options-bar';

export const TopDock = () => {
	const theme = useTheme();
	const { top: topConfig } = dockConfig;

	const screenWidthIsLow = useBreakpointValue({ base: true, md: false });

	const topDockHeight = screenWidthIsLow ? topConfig.header.height : topConfig.height;
	const optionsBar = screenWidthIsLow
		? null
		: <Box w={'100%'} h={`${topConfig.options.height}px`} borderBottom={'1px'}>
			<OptionsBar />
		</Box>;

	return <>
		<Box pos={'fixed'} top={0} w={'100%'} h={`${topDockHeight}px`} bg={topConfig.bgColor} shadow="xl" zIndex={theme.z.docked}>
			<Box w={'100%'} h={`${topConfig.header.height}px`} borderBottom={'1px'}>
				<HeaderBar />
			</Box>
			{optionsBar}
		</Box>
	</>;
};
