import { TbLetterC } from 'react-icons/tb';

import { lang } from '@lang';
import { ContentPanelMode, ContextPanelMode, pushContextPanel, selectContentPanelMode, useAppDispatch, useAppSelector } from '@state';
import { Feature, FeatureHoookParams } from '..';

type UseCountertopPropertiesFeatureParams = FeatureHoookParams;
export const useCountertopPropertiesFeature = (_params: UseCountertopPropertiesFeatureParams = {}): Feature<null> => {
	const dispatch = useAppDispatch();
	const contentMode: ContentPanelMode = useAppSelector(selectContentPanelMode);

	const [disabled, tooltip] = (() => {
		if (contentMode === ContentPanelMode.Designer || contentMode === ContentPanelMode.ThreeD) return [false, lang.view.tooltip.countertopProperties];
		return [true, lang.view.tooltip.onlyOnComponent];
	})();

	return {
		name: 'countertop-properties',
		label: lang.view.label.countertopProperties,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <TbLetterC {...props} />,
		disabled,
		value: null,
		hidden: false,
		handler: () => {
			dispatch(pushContextPanel({ mode: ContextPanelMode.CountertopProperties }));
		}
	};
};
