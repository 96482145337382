// import crypto from 'crypto';

// crypto is not working with webpack 5, defaulting to pure base64
export const decrypt = (base64: string | null) => {
	// let hash: { iv: string, content: string } | null = null;

	// try {
	// 	hash = JSON.parse(atob(base64));

	// 	if (hash === null) { throw new Error('hash is null'); }
	// } catch (e) {
	// 	console.error('Error parsing decryption string:', e);
	// 	return '';
	// }

	// const algorithm = 'aes-256-cbc';
	// const secretKey = process.env.REACT_APP_SECRET_KEY ?? null;

	// if (secretKey === null) { throw new Error('REACT_APP_SECRET_KEY is not defined'); }

	// const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'));
	// const decrypted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()]);

	// return atob(decrypted.toString());
	return base64 ? atob(base64) : '';
};
