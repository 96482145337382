export const crmLang = {
	label: {
		crm: 'Hubspot Options',
		finalizeSale: 'Finalize Sale',
		saveQuote: 'Save Quote'
	},
	tooltip: {
		disabledCrm: 'Load a customer record to enable CRM features.',
		disabledFinalizeSale: 'Create quote and receive deposit to finalize sale.',
		finalizeSale: 'Click to Finalize Sale',
		generateQuote: 'Click here to generate a quote',
		noDealId: 'Please create a deal to save a quote',
		noQuoteData: 'No quote data to save',
		openCrmFeatures: 'Open Hubspot Options'
	},
	message: {
		quoteCreated: 'Quote was created successfully',
		quoteCreationFailed: 'Quote creation failed'
	}
};
