import { Box, Divider, HStack, useBreakpointValue } from '@chakra-ui/react';

import { CommentControls } from '../../controls/comment-controls';
import { ShareControls } from '../../controls/share-controls';
import { UserProfileControls } from '../../controls/user-profile-controls';

export const CollaborationGroup = () => {
	const screenWidthIsLow = useBreakpointValue({ base: true, md: false });

	const commentControls = screenWidthIsLow
		? ''
		: <>
			<CommentControls />
			<Divider orientation="vertical" h={'50%'} />
		</>;

	const shareControls = screenWidthIsLow
		? ''
		: <>
			<ShareControls />
			<Divider orientation="vertical" h={'50%'} />
		</>;

	return (
		<Box h={'100%'} gap="5px" float={'right'} border={'0px'} >
			<HStack height={'100%'} verticalAlign={'middle'}>
				{commentControls}
				{shareControls}
				<UserProfileControls />
				<Box></Box>
			</HStack>
		</Box>
	);
};
