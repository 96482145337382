import { formatDate } from './helper';
import { styleSheet } from '../style-sheet';
import { Text, View } from '@react-pdf/renderer';

export type ProposalDocumentFooterProps = {
	page: number
}

export const ProposalDocumentPageFooter = ({ page }: ProposalDocumentFooterProps) => {
	return (
		<View style={styleSheet.header3Column}>
			<Text style={styleSheet.colorInfo}>{ formatDate(new Date()) }</Text>
			<Text style={styleSheet.colorInfo}>info@rtaoutdoor.com | 1-800-827-1830</Text>
			<Text style={styleSheet.colorInfo}>{page}</Text>
		</View>
	);
};
