import { appMode } from '../types';
import { lang } from '@lang';
import { State } from './state.type';

export const stateInitializer = (): State => {
	return {
		isBootstrapped: true,
		whatsLoading: lang.app.loading.bootstrap,
		nav: {
			appMode: appMode.rta,
			email: null,
			designId: null,
			designVersion: null,
			options: {}
		}
	};
};
