
import { FaCompass } from 'react-icons/fa';
import { ComponentController as BpController, DisplayMode } from '@rta/rta-blueprint-component';

import { Feature, FeatureParams, Require } from '..';

type showPanelFeatureParams = Require<FeatureParams, 'controller'>;
export const showPanelFeature = (params: showPanelFeatureParams):Feature<boolean> => {
	const { controller, label, panelId, Icon = FaCompass } = params;

	let displayMode: DisplayMode | null = null;

	const [disabled] = (() => {
		const isBlueprintController = controller instanceof BpController;
		if (isBlueprintController) {
			({ displayMode } = controller.navigation);
			if (displayMode === DisplayMode.Panel || displayMode === DisplayMode.Manufacturing) {
				return [false];
			}
		}
		return [true];
	})();

	return {
		name: 'show-panel',
		label,
		shortcut: '',
		tooltip: `Show ${label}`,
		icon: (props = {}) => <Icon { ...props } />,
		disabled,
		value: false,
		hidden: false,
		// eslint-disable-next-line consistent-return
		handler: () => {
			if (controller instanceof BpController) {
				if (panelId) {
					controller.navigation.panelId = panelId;
				} else {
					controller.navigation.panelId = null;
				}
			}
		}
	};
};

