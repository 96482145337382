import { metadata as bpMetadata } from '@rta/rta-blueprint-component';
import libraryMetadata from '@rta/rta-blueprint-library/lib/metadata.json';
import { metadata as tdMetadata } from '@rta/rta-blueprint-3d-component';
import {
	Box,
	Button,
	Flex,
	List,
	ListItem,
	Text,
	Tooltip,
	VStack
} from '@chakra-ui/react';

import { ContextPanelContent } from './context-panel-content';
import { lang } from '@lang';
import { releaseInfo } from '../../../config';
import { Feature, useDeleteAllDraftsFeature, useDeleteCustomerDraftsFeature, usePushSubmissionFeature } from '@features';

export const AboutPanel = (): JSX.Element => {
	const pushSubmissionFeature = usePushSubmissionFeature();
	const buttonOptions: Record<string, Feature<any>> = {
		deleteCustomerDrafts: useDeleteCustomerDraftsFeature(),
		deleteAllDrafts: useDeleteAllDraftsFeature()
	};
	return <ContextPanelContent title={lang.contextPanels.title.about}>
		<VStack spacing={0} align="stretch" h="100%" justifyContent="space-between">
			<Box px="10px" pt=".5em" mt="5px">
				<Text pb="1" fontSize={16} fontWeight={700}>Blueprint Tool</Text>
				<List spacing={0.25} fontSize={14}>
					<ListItem key="build">
						<Flex justifyContent={'space-between'}>
							<Text>Build</Text>
							<Text>{releaseInfo.build}</Text>
						</Flex>
					</ListItem>
					<ListItem key="commit">
						<Flex justifyContent={'space-between'}>
							<Text>Commit</Text>
							<Text>{releaseInfo.commit}</Text>
						</Flex>
					</ListItem>
				</List>
				<Text pb="1" fontSize={16} fontWeight={700} marginTop="15px">RTA Blueprint Component</Text>
				<List spacing={0.25} fontSize={14}>
					<ListItem key="component">
						<Flex justifyContent={'space-between'}>
							<Text>Version</Text>
							<Text>{bpMetadata.blueprintComponentVersion}</Text>
						</Flex>
					</ListItem>
				</List>

				<Text pb="1" fontSize={16} fontWeight={700} marginTop="15px">RTA 3D Blueprint Component</Text>
				<List spacing={0.25} fontSize={14}>
					<ListItem key="component">
						<Flex justifyContent={'space-between'}>
							<Text>Version</Text>
							<Text>{tdMetadata.blueprint3dComponentVersion}</Text>
						</Flex>
					</ListItem>
				</List>

				<Text pb="1" fontSize={16} fontWeight={700} marginTop="15px">RTA Blueprint Library</Text>
				<List spacing={0.25} fontSize={14}>
					<ListItem key="library">
						<Flex justifyContent={'space-between'}>
							<Text>Version</Text>
							<Text>{libraryMetadata.blueprintLibraryVersion}</Text>
						</Flex>
					</ListItem>
				</List>
				<Box display="flex" mt="50px" justifyContent="center">
					<Tooltip label={pushSubmissionFeature.tooltip} aria-label={pushSubmissionFeature.tooltip} placement="top">
						<Button size="sm" w="100%" onClick={pushSubmissionFeature.handler} isDisabled={pushSubmissionFeature.disabled} hidden={pushSubmissionFeature.hidden}>{pushSubmissionFeature.label}</Button>
					</Tooltip>
				</Box>
			</Box>
			<Box px="10px" pb=".5em" mb="5px" display="flex" gap="10px" alignItems="center" flexDirection="column">
				{ Object.keys(buttonOptions).map((key) => {
					const buttonItem = buttonOptions[key];
					return (
						<Tooltip key={key} label={buttonItem.tooltip} aria-label={buttonItem.tooltip}>
							<Button size="sm" w="100%" key={key} isDisabled={buttonItem.disabled} onClick={buttonItem.handler} hidden={buttonItem.hidden}>{buttonItem.label}</Button>
						</Tooltip>
					);
				})}
			</Box>
		</VStack>
	</ContextPanelContent>;
};
