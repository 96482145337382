export const navigation = {
	label: {
		panelMode: 'Panel View',
		inlineMode: 'Inline View',
		overheadMode: 'Overhead View',
		manufacturingMode: 'Manufacturing View'
	},
	tooltip: {
		disabledNotSupportCurrentView: 'Manufacturing view is not supported in the current view',
		manufacturingMode: 'Click here to toggle Manufacturing view',
		panelMode: 'Click here to switch to Panel Mode',
		inlineMode: 'Click here to switch to Inline Mode',
		overheadMode: 'Click here to toggle Overhead view',
		displayModeDesignerOnly: 'Only available in Designer Mode'
	},
	message: {
	}
};
