/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { FaDrawPolygon } from 'react-icons/fa';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { SiBlueprint } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Grid, GridItem, HStack, useDisclosure, useToast } from '@chakra-ui/react';
import { LuPanelRightClose, LuPanelRightOpen } from 'react-icons/lu';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '@context';
import { lang } from '@lang';
import { ContentPanelMode, selectContentPanelMode, selectContextPanelCount, selectContextPanelIsOpen, selectEstimatePanelIsOpen, setContentPanelMode, setContextPanelIsOpen, setEstimatePanelIsOpen, useAppDispatch, useAppSelector } from '@state';
import { RtaFeatureIconButton, RtaIconButton } from '@components';
import { showAboutFeature, useAutoZoomFeature, useCountertopPropertiesFeature, useCrmFeature, useFindCustomerFeature, useKitchenPropertiesFeature, useNewCustomerFeature, useSaveFeature, useShowRulersFeature } from '@features';

export const OptionsBar = () => {
	const { appState: { customerDesigns }} = useContext(AppContext);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [state, setState] = useState('');
	const [error, setError] = useState('');
	const [email, setEmail] = useState('');

	const navigate = useNavigate();
	const toast = useToast();

	const dispatch = useAppDispatch();
	const contentPanelMode = useAppSelector(selectContentPanelMode);
	const contextPanelCount = useAppSelector(selectContextPanelCount);
	const contextPanelIsOpen = useAppSelector(selectContextPanelIsOpen);
	const estimatePanelIsOpen = useAppSelector(selectEstimatePanelIsOpen);

	// const navigation = useAppSelector(selectNavigation);

	const rulerFeature = useShowRulersFeature();
	const autoZoomFeature = useAutoZoomFeature();
	const aboutFeature = showAboutFeature();
	const countertopPropertiesFeature = useCountertopPropertiesFeature();
	const kitchenPropertiesFeature = useKitchenPropertiesFeature();
	const saveFeature = useSaveFeature();
	const findCustomerFeature = useFindCustomerFeature();
	const newCustomerFeature = useNewCustomerFeature();
	const crmFeature = useCrmFeature();

	useEffect(() => {
		navigate(`/r/${email}`);
		onClose();
		setEmail('');
	}, [state]);

	useEffect(() => {
		if (error !== '') {
			toast({ title: lang.customer.message.errorFetchingUser, status: 'error', duration: 5000, isClosable: true });
		}
	}, [error]);


	return (
		<Box w={'calc(100% - 2px)'} h={'calc(100% - 2px)'} m={'1px'}>
			<Grid templateColumns={'5px auto 5px 70px'} w={'100%'} h={'100%'} gap={'0'}>
				<GridItem><Divider orientation={'vertical'} /></GridItem>
				<GridItem border="0px">
					<HStack>
						<RtaFeatureIconButton feature={findCustomerFeature} />
						<RtaFeatureIconButton feature={newCustomerFeature} />
						<RtaFeatureIconButton feature={saveFeature} />
						<Divider h={'30px'} my={'2px'} orientation={'vertical'}/>

						<RtaFeatureIconButton feature={crmFeature} />
						<Divider h={'30px'} my={'2px'} orientation={'vertical'}/>

						<RtaIconButton icon={<FaDrawPolygon />} label="Designer"
							onClick={() => dispatch(setContentPanelMode(ContentPanelMode.Designer))}
						/>
						<RtaIconButton icon={<SiBlueprint />} label="3D Viewer"
							onClick={() => dispatch(setContentPanelMode(ContentPanelMode.ThreeD))}
						/>
						<Divider h={'30px'} my={'2px'} orientation={'vertical'}/>

						<RtaFeatureIconButton feature={kitchenPropertiesFeature} />
						<RtaFeatureIconButton feature={countertopPropertiesFeature} />
						<Divider h={'30px'} my={'2px'} orientation={'vertical'}/>

						<RtaFeatureIconButton feature={rulerFeature} />
						<RtaFeatureIconButton feature={autoZoomFeature} />

						<Divider h={'30px'} my={'2px'} orientation={'vertical'}/>

						<RtaFeatureIconButton feature={aboutFeature} />
						{/* <RtaIconButton icon={<LiaPlusCircleSolid/>} label={lang.customer.tooltip.clickToAddCustomer} onClick={ onOpen } /> */}
					</HStack>
				</GridItem>
				<GridItem><Divider orientation={'vertical'} /></GridItem>
				<GridItem border="0px">
					<HStack>
						<RtaIconButton
							enabled={ customerDesigns && customerDesigns.designs.length > 0 && contentPanelMode !== ContentPanelMode.findCustomer }
							icon={<LiaFileInvoiceDollarSolid/>}
							label={`${estimatePanelIsOpen ? 'Hide' : 'Open'} Estimate`}
							onClick={() => dispatch(setEstimatePanelIsOpen(!estimatePanelIsOpen))}
						/>
						<RtaIconButton
							enabled={ customerDesigns && customerDesigns.designs.length > 0 && contentPanelMode !== ContentPanelMode.findCustomer && contextPanelCount > 0}
							icon={ contextPanelIsOpen ? <LuPanelRightClose /> : <LuPanelRightOpen />}
							label={`${contextPanelIsOpen ? 'Hide' : 'Open'} Context Panel`}
							onClick={() => dispatch(setContextPanelIsOpen(!contextPanelIsOpen))}
						/>
					</HStack>
				</GridItem>
			</Grid>
		</Box>
	);
};
