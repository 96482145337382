import { Box, Divider, HStack } from '@chakra-ui/react';

import { Logo } from './logo';

export const LogoGroup = () => {
	return (
		<Box h={'100%'} w={'100%'} border={'0'}>
			<HStack gap={'5px'} >
				<Box w={'100px'} h={'100%'} p={'10px 0 0 5px'}>
					<Logo />
				</Box>
				<Divider orientation={'vertical'}/>
			</HStack>
		</Box>
	);
};
