import { slice } from './slice';

// Action creators are generated for each case reducer function.
// export const {
// } =	slice.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
	projectState
} = slice.selectors;

export { loadProject } from './slice';
export type { State as ProjectState } from './state.type';
