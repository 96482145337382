import { lang } from '@lang';
import { MdDeleteOutline } from 'react-icons/md';
import { useDesign } from '@hooks';

import { Feature } from '..';
import { selectPriceChangePromotionalSku, setPricePromotionalSku, useAppDispatch, useAppSelector } from '@state';


export const useChangePricePromotionalSkuFeature = (): Feature<string> => {
	const [controller] = useDesign();
	const dispatch = useAppDispatch();

	const kitchen = controller?.kitchen ?? null;
	const pricePromotionalSku = useAppSelector(selectPriceChangePromotionalSku);
	return {
		name: 'remove-promotional-sku',
		label: lang.promotionalSku.label.delete,
		shortcut: '',
		tooltip: lang.promotionalSku.tooltip.deleteSku,
		icon: (props = {}) => <MdDeleteOutline {...props} />,
		disabled: false,
		value: '',
		hidden: false,
		handler: () => {
			const promotionalSku = kitchen?.promotionalSkus.findBySku(pricePromotionalSku[0]);
			if (promotionalSku) {
				promotionalSku.priceAdjustment = parseFloat(pricePromotionalSku[1]);
			}

			dispatch(setPricePromotionalSku([]));
		}
	};
};
