import { LiaFileDownloadSolid } from 'react-icons/lia';
import { useToast } from '@chakra-ui/react';

import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

import { ComponentController, generateDxfFileInternal } from '@rta/rta-blueprint-component';

type GenerateDxfFeatureParams = FeatureHoookParams;
export const useGenerateDxfFeature = (_params: GenerateDxfFeatureParams = {}): Feature<string | null> => {
	const [controller] = useDesign();
	const toast = useToast();

	const [disabled, tooltip] = (() => {
		if (controller === null) return [true, lang.social.tooltip.noGenerateDxf];
		return [false, lang.social.tooltip.generateDxf];
	})();

	return {
		name: 'generateDxf',
		label: lang.social.label.generateDxf,
		icon: (props = {}) => <LiaFileDownloadSolid {...props} />,
		tooltip,
		disabled,
		value: null,
		hidden: false,
		handler: () => {
			toast({
				title: lang.social.message.generateDxf,
				status: 'info',
				duration: 2000,
				isClosable: true
			});
			if (controller) {
				generateDxfFileInternal(controller as ComponentController);
			}
		}
	};
};
