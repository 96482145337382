import { PayloadAction, ReducerCreators } from '@reduxjs/toolkit';

import { featureStateInitializer } from './feature-state-initializer';
import { FeatureConfirmCustomerCloseState, FeatureFindState, FeatureGetNewCustomerDataState, FeatureSaveAsDraftCloseState, FeatureState } from '.';

type TopState = {
	customer: FeatureState,
};

type SetConfirmCustomerClosePayload = Omit<FeatureConfirmCustomerCloseState, 'confirmed'> & { confirmed?: FeatureConfirmCustomerCloseState['confirmed'] };
type SetSaveAsDraftClosePayload = Omit<FeatureSaveAsDraftCloseState, 'confirmed'> & { confirmed?: FeatureSaveAsDraftCloseState['confirmed'] };
type FeatureGetNewCustomerDataStatePayload = Omit<FeatureGetNewCustomerDataState, 'data'> & { data?: FeatureGetNewCustomerDataState['data'] };

export const featureStateSlice = {
	name: 'customer',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState: featureStateInitializer(),
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: <S extends TopState>(create: ReducerCreators<S>) => ({
		setCustomerFeatureFindState: create.reducer((state, action: PayloadAction<FeatureFindState>) => {
			state.customer.find = action.payload;
		}),
		setSaveAsDraftClose: create.reducer((state, action: PayloadAction<SetSaveAsDraftClosePayload>) => {
			state.customer.alerts.saveAsDraftClose.isOpen = action.payload.isOpen;
			state.customer.alerts.saveAsDraftClose.confirmed = action.payload.confirmed ?? null;
		}),
		setConfirmCustomerClose: create.reducer((state, action: PayloadAction<SetConfirmCustomerClosePayload>) => {
			state.customer.alerts.confirmCustomerClose.isOpen = action.payload.isOpen;
			state.customer.alerts.confirmCustomerClose.confirmed = action.payload.confirmed ?? null;
		}),
		setGetNewCustomerData: create.reducer((state, action: PayloadAction<FeatureGetNewCustomerDataStatePayload>) => {
			state.customer.modals.getNewCustomerData.isOpen = action.payload.isOpen;
			state.customer.modals.getNewCustomerData.data = action.payload.data ?? null;
		}),
		setDeleteCustomerDraftsModal: create.reducer((state, action: PayloadAction<boolean>) => {
			state.customer.modals.deleteCustomerDrafts.isOpen = action.payload;
		}),
		setDeleteAllDraftsModal: create.reducer((state, action: PayloadAction<boolean>) => {
			state.customer.modals.deleteAllDrafts.isOpen = action.payload;
		}),
		setPushSubmissionDataModal: create.reducer((state, action: PayloadAction<boolean>) => {
			state.customer.modals.pushSubmissionData.isOpen = action.payload;
		})
	}),
	// You can define your selectors here. These selectors receive the slice
	// state as their first argument.
	selectors: {
		selectFeatureCustomerState: (state: TopState) => state.customer,
		selectCustomerFeatureFindState: (state: TopState) => state.customer.find,
		selectConfirmCustomerClose: (state: TopState) => state.customer.alerts.confirmCustomerClose,
		selectSaveAsDraftClose: (state: TopState) => state.customer.alerts.saveAsDraftClose,
		selectGetNewCustomerData: (state: TopState) => state.customer.modals.getNewCustomerData,
		selectDeleteCustomerDraftsModal: (state: TopState) => state.customer.modals.deleteCustomerDrafts,
		selectDeleteAllDraftsModal: (state: TopState) => state.customer.modals.deleteAllDrafts,
		selectSetPushSubmissionDataModal: (state: TopState) => state.customer.modals.pushSubmissionData
	}
};
