import { Box, useTheme } from '@chakra-ui/react';

import { dockConfig } from '../../../config';
import { FooterBar } from '../../bars/footer-bar';

export const BottomDock = () => {
	const theme = useTheme();
	const { bottom: bottomConfig } = dockConfig;

	return (
		<Box pos={'fixed'} bottom={'0'} w={'100%'} h={`${bottomConfig.height}px`} bg={bottomConfig.bgColor} overflow="hidden" zIndex={theme.z.docked}>
			<FooterBar />
		</Box>
	);
};
