import { Box, Skeleton, Text, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { selectCustomerEmail, selectCustomerName, useAppSelector } from '@state';

export const TitleBar = () => {
	const customerName = useAppSelector(selectCustomerName);
	const customerEmail = useAppSelector(selectCustomerEmail);
	const [isComponentLoaded, setIsComponentLoaded] = useState(customerName !== '');
	const tooltip = 'to select a customer...';

	useEffect(() => {
		if (customerName !== '') {
			setIsComponentLoaded(true);
		}
	}, [customerName]);

	return (
		<Box w={'100%'} h={'40px'} border={'0'} textAlign="left" pt="5px">
			<Skeleton isLoaded={isComponentLoaded} display="flex" flexDirection="column">
				<Tooltip
					hasArrow
					label={tooltip}
					aria-label={tooltip}
					openDelay={250}
					arrowSize={15}>
					<Text fontSize="xl" as="b" lineHeight="1.4em">{customerName}</Text>
				</Tooltip>
				<Text fontSize="sm" as="i">{customerEmail}</Text>
			</Skeleton>
		</Box>
	);
};
