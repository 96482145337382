import { State } from './state.type';

export const stateInitializer = (): State => {
	return [];
	// return [
	// 	{
	// 		totalPaymentRecieved: 0,
	// 		dealId: '',
	// 		dealStage: '',
	// 		depositDocument: '',
	// 		blueprintApprovalDocument: '',
	// 		blueprintLiaison: {
	// 			email: '',
	// 			firstName: '',
	// 			lastName: ''
	// 		},
	// 		designer: {
	// 			email: '',
	// 			firstName: '',
	// 			lastName: ''
	// 		},
	// 		projectManager: {
	// 			email: '',
	// 			firstName: '',
	// 			lastName: ''
	// 		}
	// 	}
	// ];
};
