import { slice } from './slice';

// Action creators are generated for each case reducer function.
export const { setNavigation } =
  slice.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
	selectIsBootstrapped,
	selectNavigation,
	selectWhatsLoading
} = slice.selectors;

export { bootstrapApp } from './slice';
