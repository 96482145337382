import { MdOutlineFormatListBulleted } from 'react-icons/md';
import { Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';

import { Designs } from './designs.type';
import { CheckMark, RtaIconButton } from '@components';

type DesignListSelectionProps = {
	designs: Designs,
	selected?: string | null,
	disabled?: boolean,
	onSelect: (id: string, version: number | null, baseVersion: number | null) => void
};

export const DesignListSelection = (props: DesignListSelectionProps) => {
	const { designs, disabled, selected = null, onSelect } = props;

	return <Menu>
		<MenuButton as={RtaIconButton} icon={<MdOutlineFormatListBulleted/>} enabled={!disabled} label="Select Design"/>
		<Portal>
			<MenuList>
				{designs.map((design, i) => <MenuItem key={i} value={design.id}
					icon={<CheckMark checked={selected === design.id}/> }
					onClick={() => onSelect(design.id, design.version, design.baseVersion)}>
					{(design.description ?? '') === '' ? 'no description' : design.description} { design.isDirty ? '*' : '' }
				</MenuItem>)}
			</MenuList>
		</Portal>
	</Menu>;
};
