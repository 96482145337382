import { RiFolderUserLine } from 'react-icons/ri';

import { FeatureFindState } from './state';
import { lang } from '@lang';
import { useNavigate } from 'react-router-dom';
import { appMode, selectCustomerFeatureFindState, selectNavigation, useAppSelector } from '@state';
import { Feature, FeatureHoookParams } from '..';

type UseFindCustomerFeatureParams = FeatureHoookParams;
export const useFindCustomerFeature = (_params: UseFindCustomerFeatureParams = {}): Feature<FeatureFindState> => {
	const navigate = useNavigate();

	const appNav = useAppSelector(selectNavigation);
	const findCustomer = useAppSelector(selectCustomerFeatureFindState);

	return {
		name: 'findCustomer',
		label: lang.customer.label.findCustomer,
		shortcut: '',
		tooltip: lang.customer.tooltip.clickToFindCustomer,
		icon: (props = {}) => <RiFolderUserLine {...props} />,
		disabled: false,
		value: findCustomer,
		hidden: appNav.appMode === appMode.view,
		handler: () => {
			navigate('/r');
		}
	};
};


