import { MdOutlineLibraryAdd } from 'react-icons/md';

import { lang } from '../../lang';
import { useNavigate } from 'react-router';
import { Feature, FeatureHoookParams } from '..';
import { selectNavigation, useAppSelector } from '@state';

type SubmissionFeatureParams = FeatureHoookParams;
export const useAddSubmissionFeature = (_params: SubmissionFeatureParams = {}): Feature<string> => {
	const navigate = useNavigate();
	const appNav = useAppSelector(selectNavigation);

	const [disabled, tooltip] = (() => {
		if (!appNav.email) return [true, lang.islandSubmission.tooltip.noCustomer];
		return [false, lang.islandSubmission.tooltip.addDesign];
	})();

	return {
		name: 'add-submission',
		label: lang.promotionalSku.label.add,
		shortcut: '',
		tooltip,
		icon: (props) => <MdOutlineLibraryAdd {...props}/>,
		disabled,
		value: '',
		hidden: false,
		handler: () => {
			navigate(`/r/${appNav.email}`);
		}
	};
};
