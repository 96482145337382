import { Box, FormLabel, Input } from '@chakra-ui/react';

export type StackedStringInputParams = {
	category?: string;
	label: string;
	placeholder?: string;
	value: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange?: ((e: any) => void);
};

// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
export const StackedStringInput = ({ category = '', label, placeholder = '', value, onChange = () => {} }: StackedStringInputParams): JSX.Element => {
	const id = (category + label).toLowerCase();
	const isEnabled = onChange !== null;

	return (
		<Box>
			<FormLabel htmlFor={id} fontSize="small" marginBottom={0}>{label}:</FormLabel>
			{ isEnabled &&
				<Input {...{ id, value, placeholder, onChange }} size="xs"/>
			}

			{ !isEnabled &&
				<Input {...{ id, defaultValue: value, placeholder, onChange }} readOnly={true} size="xs"/>
			}
		</Box>
	);
};
