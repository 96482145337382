/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { BlueprintDesigner, ComponentController as BpComponentController, DisplayMode } from '@rta/rta-blueprint-component';
import { BlueprintInstanceType, EventPayloadAction, useSubscription } from '@rta/rta-blueprint-library';
import { Box, Image } from '@chakra-ui/react';
import { Kitchen3d, Kitchen3dController as TdComponentController } from '@rta/rta-blueprint-3d-component';

import { FindCustomer } from './find-customer';
import { NewSubmissionPanel } from './new-submission/new-submission-panel';
import { useAutoSaveFeature } from '@features';
import { useDesign } from '../../../hooks';
import { appMode, ContentPanelMode, ContextPanelMode, pushContextPanel, selectContentPanelMode, selectIsBootstrapped, selectNavigation, setContentPanelMode, useAppDispatch, useAppSelector } from '@state';

import bpBackground from '@assets/blueprint-drawing-bg.png';

export const ContentPanel = () => {
	const {	isAuthenticated } = useAuth0();
	const dispatch = useAppDispatch();
	const isBootstrapped = useAppSelector(selectIsBootstrapped);
	const contentModeState = useAppSelector(selectContentPanelMode);
	const appNav = useAppSelector(selectNavigation);

	// const { appState: { customerDesigns }} = useContext(AppContext);
	const [controller, design] = useDesign<BpComponentController | TdComponentController>();
	// const kitchen = blueprint?.kitchens.getLatestVersion() ?? null;
	// const bpController: BpComponentController | null = (customerDesigns.designs.find((d) => d.blueprint.id === blueprint?.id)?.bpController as BpComponentController) ?? null;

	useAutoSaveFeature();

	let contentMode = isAuthenticated ? contentModeState : ContentPanelMode.Anonymous;
	if (!isBootstrapped) contentMode = ContentPanelMode.Loading;

	const [uiEvent] = useSubscription(controller, false, [['ui', 'double-click']]);
	// addUiSub('ui', 'click');

	const [event] = useSubscription(controller, false, [
		['*', 'show'],
		['*', 'requested'],
		['navigation-display-mode', 'updated']
	]);

	useEffect(() => {
		if (uiEvent && uiEvent.payload.ref) {
			const refMap = controller?.kitchen.getReferenceMap(uiEvent.payload.ref as string);
			if (uiEvent.noun === 'ui' && refMap) {
				const toggleMode = contentModeState === ContentPanelMode.Designer ? ContentPanelMode.ThreeD : ContentPanelMode.Designer;

				switch (uiEvent.action) {
					case 'click':
						switch (refMap.type) {
							case BlueprintInstanceType.Countertop:
								dispatch(pushContextPanel({ mode: ContextPanelMode.CountertopProperties, id: uiEvent.payload.ref as string, temporary: true	}));
								break;
							case BlueprintInstanceType.Island:
								dispatch(pushContextPanel({ mode: ContextPanelMode.IslandProperties, id: uiEvent.payload.ref as string, temporary: true	}));
								break;
							case BlueprintInstanceType.Connection:
								dispatch(pushContextPanel({ mode: ContextPanelMode.ConnectionProperties, id: uiEvent.payload.ref as string, temporary: true	}));
								break;
							case BlueprintInstanceType.PanelItem:
								// eslint-disable-next-line max-depth
								if (refMap.panelItem.item.category.key === 'cutout') {
									dispatch(pushContextPanel({ mode: ContextPanelMode.CutoutProperties, id: uiEvent.payload.ref as string, temporary: true	}));
								} else {
									dispatch(pushContextPanel({ mode: ContextPanelMode.ApplianceProperties, id: uiEvent.payload.ref as string, temporary: true	}));
								}
								break;
							default:
						}
						break;
					case 'double-click':
						if (design?.bpController && refMap && refMap.island) {
							design.bpController.navigation.islandId = refMap.island.id;
							design.bpController.navigation.panelId = refMap.panel ? refMap.panel.id : refMap.island.frontPanel.id;
							design.bpController.navigation.displayMode = DisplayMode.Panel;
							// dispatch(setContentPanelMode(ContentPanelMode.Designer));
						}

						dispatch(setContentPanelMode(toggleMode));
						break;
					default:
				}
			}
		}
	}, [uiEvent]);

	useEffect(() => {
		if (event) {
			if (event.action === 'show') {
				const contextPanel = event.noun.replace('_', '-');
				if (Object.values(ContextPanelMode).includes(contextPanel as ContextPanelMode)) {
					dispatch(pushContextPanel({ mode: contextPanel as ContextPanelMode, id: event.payload.ref as string, temporary: true	}));
				}
			}

			if (event.action === 'requested') {
				// deepcode ignore DuplicateCaseBody: <please specify a reason of ignoring this>
				switch (event.noun) {
					case 'add_appliance_action':
					case 'swap_appliance_action':
						console.log('add_appliance_action', (event.payload as EventPayloadAction)?.data?.position);
						dispatch(pushContextPanel({
							mode: ContextPanelMode.AddAppliance,
							id: event.payload.ref as string,
							temporary: true,
							data: {
								position: (event.payload as EventPayloadAction)?.data?.position ?? {}
							}
						}));
						break;
					case 'add_cutout_action':
						dispatch(pushContextPanel({
							mode: ContextPanelMode.AddCutout,
							id: event.payload.ref as string,
							temporary: true,
							data: {
								position: (event.payload as EventPayloadAction)?.data?.position ?? {}
							}
						}));
						break;
					case 'add_custom_appliance_action':
						dispatch(pushContextPanel({
							mode: ContextPanelMode.AddCustomAppliance,
							id: event.payload.ref as string,
							temporary: true,
							data: {
								position: (event.payload as EventPayloadAction)?.data?.position ?? {}
							}
						}));
						break;
					default:
				}
			}
		}
	}, [event]);

	let content = null;
	// view mode always uses 'three-d'
	switch (appNav.appMode === appMode.view ? 'three-d' : contentMode) {
		case 'anonymous':
		case 'loading':
			content = <Image src={bpBackground} alt="Background Image" minW="-webkit-fill-available" minH="fit-content" position="absolute" top="-100px" opacity={0.6}/>;
			// content = null;
			break;
		case 'designer':
			content = (controller instanceof BpComponentController)
				? <BlueprintDesigner
					controller={controller as BpComponentController}
					backgroundColor={controller.navigation.displayMode === DisplayMode.Manufacturing ? 'white' : 'unset'}
				/>
				: null;
			break;
		case 'three-d':
			content = <Kitchen3d
				controller={controller as TdComponentController}
				backgroundColor={'var(--chakra-colors-bg-canvas)'}
			/>;
			break;
		case 'new-submission':
			content = <NewSubmissionPanel />;
			break;
		case 'find-customer':
			content = <FindCustomer/>;
			break;
		default:
			content = null;
	}

	return (<>
		<Box w={'100%'} h={'100%'} overflow="hidden" bgColor="gray.100">
			{content}
		</Box>
	</>);
};
