import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { AppGlobalProvider } from '.';
import { providerConfig } from '../auth';

export const AppAuthProvider = (): JSX.Element => {
	const navigate = useNavigate();

	// console.log('render:AppAuthProvider', navigate);
	return <Auth0Provider {...providerConfig(navigate)}>
		<AppGlobalProvider/>
	</Auth0Provider>;
};
