import { MdOutlineCancel } from 'react-icons/md';
import {
	Box, Grid, HStack, Text, VStack
} from '@chakra-ui/react';

import { RtaIconButton } from '@components';
import { popContextPanel, useAppDispatch } from '@state';

export type ContextPanelContentProps = {
	title: string;
	children: React.ReactNode;
	canExit?: boolean;
};

export const ContextPanelContent = ({ children, title, canExit = true }: ContextPanelContentProps) => {
	const dispatch = useAppDispatch();

	const headerHeight = '35px';
	return (
		<Box width="100%" height="100%" overflowY="hidden">
			<Grid width="100%" height="100%" templateRows={`${headerHeight} 1fr`}>
				<HStack h={headerHeight} w="full" background="blue.400" px={6} py={2} justifyContent={'center'} mb={2}>
					<Text textAlign={'center'} color={'white'} fontSize={18} position="absolute">{title}</Text>
					{ canExit &&
						<RtaIconButton icon={<MdOutlineCancel />} onClick={() => dispatch(popContextPanel())} alignContent="end" position="absolute" right="0px" />
					}
				</HStack>
				<VStack px="10px" spacing="12px" overflowY="auto">
					{children}
				</VStack>
			</Grid>
		</Box>
	);
};
