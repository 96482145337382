import { formatDate } from './helper';
import { styleSheet } from '../style-sheet';
import { Text, View } from '@react-pdf/renderer';

import { ProposalDocumentAdditionalNotes, ProposalDocumentCastingTable } from './section';

export type ProposalDocumentFooterProps = {
	page: number,
	notes: Array<string>
}

export const ProposalDocumentPanelFooter = ({ page, notes }: ProposalDocumentFooterProps) => {
	return (
		<View style={styleSheet.containerPanelFooter}>
			<View style={styleSheet.containerSection}>
				<ProposalDocumentCastingTable />
				<ProposalDocumentAdditionalNotes notes={notes} />
			</View>
			<View style={styleSheet.header3Column}>
				<Text style={styleSheet.colorInfo}>{ formatDate(new Date()) }</Text>
				<Text style={styleSheet.colorInfo}>info@rtaoutdoor.com | 1-800-827-1830</Text>
				<Text style={styleSheet.colorInfo}>{page}</Text>
			</View>
		</View>
	);
};
