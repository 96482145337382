import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

interface IControllerSupportsFeature { options: {
	showAppliances: boolean;
	showApplianceLabels: boolean
}}

type ApplianceFeatureParams = FeatureHoookParams;
export const useShowApplianceLabelFeature = (_params: ApplianceFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, IControllerSupportsFeature>();

	useSubscription(controller, false, [['layer-option-appliance-label', '*']]);

	const controllerSupportsFeature = (controller?.options?.showApplianceLabels ?? null) !== null;
	const showApplianceLabels = controller?.options?.showApplianceLabels ?? true;
	const showAppliances = controller?.options?.showAppliances ?? true;
	const showLayer = showApplianceLabels && showAppliances;

	const tooltip = (() => {
		const showHide = showLayer ? lang.layer.tooltip.hide : lang.layer.tooltip.show;
		return `${showHide} ${lang.layer.label.applianceLabels} ${lang.layer.layer}`;
	})();

	return {
		name: 'showApplianceLabel',
		label: lang.layer.label.applianceLabels,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled: !showAppliances || !controllerSupportsFeature,
		value: showLayer,
		hidden: false,
		handler: () => {
			if (controller) {
				controller.options.showApplianceLabels = !showLayer;
			}
		}
	};
};
