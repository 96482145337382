import { FeatureState } from './feature-state.type';

export const featureStateInitializer = (): FeatureState => {
	return {
		alerts: {
			confirmCustomerClose: { isOpen: false, confirmed: null },
			saveAsDraftClose: { isOpen: false, confirmed: null }
		},
		modals: {
			getNewCustomerData: { isOpen: false, data: null },
			deleteCustomerDrafts: { isOpen: false },
			deleteAllDrafts: { isOpen: false },
			pushSubmissionData: { isOpen: false }
		},
		find: {
			customerName: null,
			selectedEmail: null,
			thumbnails: []
		}
	};
};
