import { Box, VStack } from '@chakra-ui/react';

// import { MenuBar } from '../../bars/menu-bar';
import { TitleBar } from '../../bars/title-bar';

export const TitleGroup = () => {
	return (
		<Box w={'100%'} h={'100%'} border={'0px'} >
			<VStack gap={0}>
				<TitleBar />
				<Box/>
				{/* <MenuBar /> */}
			</VStack>
		</Box>
	);
};
