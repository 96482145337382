import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

import { fetchTax } from '../external-endpoints/fetch-tax';
import { useDesign } from './use-design';
import { customerState, useAppSelector } from '@state';

export const useTax = (): [number, string | null] => {
	const [controller] = useDesign();
	const { getAccessTokenSilently } = useAuth0();
	const customer = useAppSelector(customerState);

	const kitchenData = controller?.kitchen ?? null;

	const [tax, setTax]	= useState<number>(kitchenData?.tax ?? 0);
	const [error, setError] = useState<string | null>(null);

	if (kitchenData && kitchenData?.tax !== tax) kitchenData.tax = tax;

	useEffect(() => {
		if (kitchenData !== null) {
			fetchTax({
				kitchen: kitchenData,
				customer,
				getAccessTokenSilently,
				setTax,
				setError
			});
		}
	}, [kitchenData?.itemizedMsrp.subtotal, customer]);

	return [tax, error];
};
