import { createAsyncThunk } from '@reduxjs/toolkit';
import { Params } from 'react-router-dom';

import { createAppSlice } from '../create-app-slice';
import { lang } from '@lang';
import { loadCustomer } from '../customer';
import { loadProject } from '../project';
import { setNavigationReducer } from './set-navigation.reducer';
import { stateInitializer } from './state-initializer';

export const bootstrapApp = createAsyncThunk(
	'app/bootstrapApp',
	async (payload: string, { dispatch }) => {
		if (payload) {
			await Promise.all([
				dispatch(loadCustomer(payload)),
				dispatch(loadProject(payload))
			]);
		}
	}
);

// If you are not using async thunks you can use the standalone `createSlice`.
export const slice = createAppSlice({
	name: 'app',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState: stateInitializer(),
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: (create) => ({
		setNavigation: setNavigationReducer(create)
	}),
	extraReducers: (builder) => {
		builder
			.addCase(bootstrapApp.pending, (state) => {
				console.log('state:bootstrapApp:pending');
				state.isBootstrapped = false;
				state.whatsLoading = lang.app.loading.bootstrap;
			})
			.addCase(bootstrapApp.fulfilled, (state) => {
				console.log('state:bootstrapApp:fulfilled');
				state.isBootstrapped = true;
				state.whatsLoading = '';
			})
			.addCase(bootstrapApp.rejected, (state) => {
				console.log('state:bootstrapApp:rejected');
				state.whatsLoading = lang.app.loading.bootstrapError;
			});
	},
	// You can define your selectors here. These selectors receive the slice
	// state as their first argument.
	selectors: {
		selectIsBootstrapped: (state) => { return state.isBootstrapped; },
		selectNavigation: (state) => { return state.nav; },
		selectWhatsLoading: (state) => { return state.whatsLoading; }
	}
});


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (dispatch, getState) => {
// 	const currentValue = selectCount(getState());

// 	if (currentValue % 2 === 1 || currentValue % 2 === -1) {
// 		dispatch(incrementByAmount(amount));
// 	}
// };
