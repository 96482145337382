import { useRef } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
import { selectSaveAsDraftClose, setSaveAsDraftClose, useAppDispatch, useAppSelector } from '@state';

export const SaveAsDraftAlert = () => {
	const dispatch = useAppDispatch();
	const { isOpen } = useAppSelector(selectSaveAsDraftClose);

	const cancelRef = useRef<any>();
	const onClose = (confirmed: boolean | null) => dispatch(setSaveAsDraftClose({ isOpen: false, confirmed }));

	return (<>
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={() => onClose(null)}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						Save as...
					</AlertDialogHeader>

					<AlertDialogBody>
						Save as a draft or create a new version?
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button colorScheme="blue" ref={cancelRef} onClick={() => onClose(true)}>
							Draft
						</Button>
						<Button colorScheme="green" onClick={() => onClose(false)} ml={3}>
							New Version
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	</>);
};
