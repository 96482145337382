/* eslint-disable max-lines-per-function */
import { Box, FormLabel, Select, Stack } from '@chakra-ui/react';
import { SurroundMaterial, useSubscription } from '@rta/rta-blueprint-library';

import { ContextPanelContent } from './context-panel-content';
import { displayDropdowns } from './helpers';
import { lang } from '@lang';
import { useDesign } from '@hooks';

export const KitchenPropertiesPanel = (): JSX.Element => {
	const [controller] = useDesign();

	useSubscription(controller?.kitchen ?? null, false, [
		['kitchen_fuel', '*'],
		['surround_material', '*']
	]);

	if (!controller) return <></>;
	const { fuelchoice, material: { surround }} = controller.kitchen;

	return <ContextPanelContent title={lang.contextPanels.title.kitchenProperties}>
		<Stack mt="5px" spacing="2" width="100%" height="65%">
			<Box w="100%">
				<FormLabel htmlFor="fuel" fontSize="small">Surround</FormLabel>
				<Select id="fuel" fontSize="small"
					value={surround?.key}
					onChange={ (e) => {
						if (surround) {
							controller.kitchen.material.setSurround(e.target.value);
						}
					}}>
					{displayDropdowns(SurroundMaterial.assetData)}
				</Select>
			</Box>
			<Box>
				<FormLabel htmlFor="fuel" fontSize="small">Fuel:</FormLabel>
				<Select id="fuel" fontSize="small"
					value={fuelchoice}
					onChange={ (e) => {
						controller.kitchen.fuelchoice = e.target.value;
					}}>
					<option value="LP">Liquid Propane (LP)</option>
					<option value="NG">Natural Gas (NG)</option>
					<option value="">&lt;None&gt;</option>
				</Select>
			</Box>
		</Stack>
	</ContextPanelContent>;
};
