import { Box, Divider, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';

import { DesignCollectionControls } from '../../controls/design-selection-controls';
import { LayerControls } from '../../controls/layer-controls';
import { NavigationControls } from '../../controls/navigation-controls';
import { StatusControls } from '../../controls/status-controls';
import { ViewModeControls } from '../../controls/view-mode-controls';

export const FooterBar = () => {
	const screenWidthIsLow = useBreakpointValue({ base: true, md: false });

	const statusControlColumnWidth = screenWidthIsLow ? '' : '35px 5px';
	const statusControls = screenWidthIsLow
		? null
		: <>
			<GridItem>
				<StatusControls />
			</GridItem>
			<GridItem><Divider orientation={'vertical'} /></GridItem>
		</>;

	const layerControlColumnWidth = screenWidthIsLow ? '' : '35px 5px';
	const layerControls = screenWidthIsLow
		? null
		: <>
			<GridItem>
				<LayerControls />
			</GridItem>
			<GridItem><Divider orientation={'vertical'} /></GridItem>
		</>;

	const viewModeControlColumnWidth = screenWidthIsLow ? '' : '95px 5px';
	const viewModeControls = screenWidthIsLow
		? null
		: <>
			<GridItem>
				<ViewModeControls />
			</GridItem>
			<GridItem></GridItem>
		</>;

	const templateColumns = `1fr 5px ${statusControlColumnWidth} ${layerControlColumnWidth} 170px 5px ${viewModeControlColumnWidth}`;
	return (
		<Box w={'calc(100% - 4px)'} h={'calc(100% - 4px)'} m={'1px'} borderTop={'1px'}>
			<Grid templateColumns={templateColumns} w={'100%'} h={'36px'} gap={'5px'} zIndex="10">
				<GridItem>
					<DesignCollectionControls/>
				</GridItem>
				<GridItem><Divider orientation={'vertical'} /></GridItem>
				{statusControls}
				{layerControls}
				<GridItem>
					<NavigationControls />
				</GridItem>
				<GridItem><Divider orientation={'vertical'} /></GridItem>
				{viewModeControls}
			</Grid>
		</Box>
	);
};
