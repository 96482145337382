import { TbZoomReplace } from 'react-icons/tb';

import { Controller } from '@context';
import { ControllerAutoZoomSupport } from './types';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';
import { selectAutoZoom, setAutoZoom, useAppDispatch, useAppSelector } from '@state';

type ZoomInFeatureParams = FeatureHoookParams;
export const useAutoZoomFeature = (_params: ZoomInFeatureParams = {}): Feature<boolean> => {
	const dispatch = useAppDispatch();
	const [controller] = useDesign<Controller, ControllerAutoZoomSupport>();
	const autoZoomState = useAppSelector(selectAutoZoom);

	const controllerSupportsAutozoomFeature = (controller?.options?.autoZoom ?? null) !== null;

	const [enabled, tooltip] = (() => {
		if (!controllerSupportsAutozoomFeature) return [false, lang.zoom.tooltip.disabledNotSupportCurrentView];
		return [true, lang.zoom.tooltip.autozoom];
	})();

	return {
		name: 'autoZoom',
		label: lang.zoom.label.autozoom,
		icon: (props = {}) => <TbZoomReplace color={controllerSupportsAutozoomFeature && autoZoomState ? 'green' : 'unset'} {...props} />,
		tooltip,
		disabled: !enabled,
		value: controllerSupportsAutozoomFeature ? autoZoomState : false,
		hidden: false,
		handler: () => {
			dispatch(setAutoZoom(!autoZoomState));
		}
	};
};
