
import { RiSave3Fill } from 'react-icons/ri';

import { featureHook } from '@features/feature-hook';
import { lang } from '@lang';
import { saveCustomerBlueprint } from '@external';
import { useAuth0 } from '@auth0/auth0-react';
import { useDesign } from '@hooks';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import { AppContext, DesignActionTypes } from '@context';
import { appMode, customerState, loadCustomer, selectNavigation, selectSaveAsDraftClose, setSaveAsDraftClose, useAppDispatch, useAppSelector } from '@state';
import { Feature, FeatureHoookParams } from '..';
import { useContext, useEffect, useState } from 'react';

type UseSaveFeatureParams = FeatureHoookParams;
export const useSaveFeature = (params: UseSaveFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign();
	const dispatch = useAppDispatch();
	const { getAccessTokenSilently } = useAuth0();
	const navigate = useNavigate();

	const toast = useToast();

	const [success, setSuccess] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);

	const customer = useAppSelector(customerState);
	const saveAsDraft = useAppSelector(selectSaveAsDraftClose);
	const autoSaveId = controller?.kitchen?.context?.blueprint?.id ?? '';

	const { appDispatch, appState: { customerDesigns: designs }} = useContext(AppContext);
	const appNav = useAppSelector(selectNavigation);

	useEffect(() => {
		if (controller && success !== null) {
			toast({
				title: lang.customer.message.saveSuccess,
				status: 'success',
				duration: 2000,
				isClosable: true
			});
			// clean up dirty flag and local storage
			localStorage.removeItem(`autosave-${autoSaveId}`);
			appDispatch({ type: DesignActionTypes.setIsDirty, payload: { id: appNav.designId ?? '', isDirty: false }});
			navigate(`/r/${customer.email}/${appNav.designId}/${controller.kitchenVersion}`);
			controller.kitchenVersion = null;
			dispatch(loadCustomer(customer.email));
		}
	}, [success]);

	useEffect(() => {
		if (error !== null) {
			toast({
				title: lang.customer.message.saveFailed,
				status: 'error',
				duration: 2000,
				isClosable: true
			});
		}
	}, [error]);

	const currentDesign = designs.designs.find((bp) => bp.blueprint.id === appNav.designId) ?? null;
	const value = currentDesign ? currentDesign.isDirty : false;

	const [disabled, tooltip] = (() => {
		if (!controller) return [true, lang.customer.tooltip.noController];
		if (!value) return [true, lang.customer.tooltip.notDirty];
		return [false, lang.customer.tooltip.saveDesign];
	})();


	return featureHook<boolean>({
		name: 'saveFeature',
		label: lang.customer.label.saveSubmission,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <RiSave3Fill {...props} />,
		disabled,
		value,
		hidden: appNav.appMode === appMode.view,
		confirm: [
			saveAsDraft,
			() => {
				dispatch(setSaveAsDraftClose({ isOpen: true }));
			}
		],
		reset: () => {
			dispatch(setSaveAsDraftClose({ isOpen: false, confirmed: null }));
		},
		handler: () => {
			if (controller) {
				if (!saveAsDraft.confirmed) {
					const version = controller.kitchen.context.blueprint?.kitchens.promoteDraft();
					if (version) controller.kitchenVersion = version;
				}

				saveCustomerBlueprint({
					controller,
					mode: 'live',
					getAccessTokenSilently,
					setSuccess,
					setError
				});
			}
		}
	});
};
