/* eslint-disable max-lines-per-function */
import { useNavigate } from 'react-router-dom';
import { Box, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';

import { AppContext } from '@context';
import { DesignListSelection } from './design-list-selection';
import { Designs } from './designs.type';
import { DesignTabSelection } from './design-tab-selection';
import { DesignViewSelection } from './design-view-selection';
import { RtaFeatureIconButton } from '@components';
import { useAddSubmissionFeature } from '@features';
import { selectNavigation, useAppSelector } from '@state';

export const DesignCollectionControls = () => {
	const { appState: { customerDesigns }} = useContext(AppContext);
	const navigate = useNavigate();
	const appNav = useAppSelector(selectNavigation);

	const addNewSubmission = useAddSubmissionFeature();

	let submissions: Designs = customerDesigns.designs.map((bpContextRecord) => {
		const bp = bpContextRecord.blueprint;
		const kitchen = bp.kitchens.getActiveVersion();
		return {
			id: bp.id,
			version: kitchen?.version ?? null,
			baseVersion: kitchen?.baseVersion ?? null,
			description: kitchen?.description ?? '',
			thumbnail: bp.thumbnail?.length > 255
				? `data:image/png;base64,${bp.thumbnail}`
				: `${process.env.REACT_APP_BLUEPRINT_IMAGES_BASE_URL}\\${bp.thumbnail}`,
			isDirty: bpContextRecord.isDirty
		};
	});

	useEffect(() => {
		submissions = customerDesigns.designs.map((bpContextRecord) => {
			const bp = bpContextRecord.blueprint;
			const kitchen = bp.kitchens.getActiveVersion();
			return {
				id: bp.id,
				version: kitchen?.version ?? null,
				baseVersion: kitchen?.baseVersion ?? null,
				description: kitchen?.description ?? '',
				thumbnail: bp.thumbnail?.length > 255
					? `data:image/png;base64,${bp.thumbnail}`
					: `${process.env.REACT_APP_BLUEPRINT_IMAGES_BASE_URL}\\${bp.thumbnail}`,
				isDirty: bpContextRecord.isDirty
			};
		});
	}, [customerDesigns]);

	// disable design controls if no designs are available
	const disableControls = !customerDesigns || customerDesigns.designs.length === 0;

	// Hide Tabs when screen realestate is limited
	const showSelectionTabs = useBreakpointValue({ base: false, xl: true });
	const selectionTabColumn = showSelectionTabs ? '1fr' : '';
	const templateColumns = `30px 30px 30px ${selectionTabColumn}`;

	// Selection Tabs JSX
	const selectionTabs = showSelectionTabs
		? <GridItem minWidth="0" maxHeight="36px" overflow="hidden">
			<DesignTabSelection
				designs={submissions}
				selected={appNav.designId}
				disabled={disableControls}
				onSelect={(id, version, baseVersion) => {
					navigate(`/r/${appNav.email}/${id}${version ? `/${version}` : `/${baseVersion ?? ''}?draft`}`);
				}}
				onDesignNameChange={
					(designId, newName) => {
						// console.log('onDesignNameChange', designId, newName, appNav.designId);
						const bp = customerDesigns.designs.find((design) => design.blueprint.id === designId)?.blueprint;
						if (bp) {
							const activeVersion = bp.kitchens.getActiveVersion();
							if (activeVersion) activeVersion.description = newName;
						}
					}
				} />
		</GridItem>
		: null;

	return (
		<Box w={'100%'} h={'100%'} m={'1px'}>
			<Grid templateColumns={templateColumns} w={'100%'} h={'100%'} gap={'5px'} ml="5px">
				<GridItem mt="2px" position={'relative'}>
					<DesignListSelection
						designs={submissions}
						selected={appNav.designId}
						disabled={disableControls}
						onSelect={(id, version, baseVersion) => {
							navigate(`/r/${appNav.email}/${id}${version ? `/${version}` : `/${baseVersion ?? ''}?draft`}`);
						}}/>
				</GridItem>
				<GridItem mt="2px" position={'relative'}>
					<DesignViewSelection
						designs={submissions}
						selected={appNav.designId}
						disabled={disableControls}
						onSelect={(id, version, baseVersion) => {
							navigate(`/r/${appNav.email}/${id}${version ? `/${version}` : `/${baseVersion ?? ''}?draft`}`);
						}}/>
				</GridItem>
				<GridItem mt="2px" position={'relative'}>
					<RtaFeatureIconButton feature={addNewSubmission} />
				</GridItem>
				{selectionTabs}
			</Grid>
		</Box>
	);
};
