import { Box, Card, CardBody, Image, Text, Tooltip, VStack } from '@chakra-ui/react';

import { lang } from '@lang';

export type SelectableImageProps = {
	src: string;
	onSelect: (value: string) => void;
	value: string;
	isSelected: boolean;
	label: string;
};

export const SubmissionCard = (props:SelectableImageProps) => {
	const { src, onSelect, value, isSelected, label } = props;

	return (
		<Tooltip label={lang.app.tooltips.clickToSelect} aria-label={label}>
			<Card maxW="sm" onClick={() => onSelect(value)}
				border="2px"
				borderColor={isSelected ? 'green.300' : 'transparent'}
			>
				<CardBody p="5px">
					<Box width="200px" height="120px" borderRadius="md" bgColor="gray.300">
						<Image src={src}
							margin="auto"
							width="175px"
							height="110px"
							objectFit="cover"
							objectPosition="top"
						/>
					</Box>
					<VStack pt="5px" textAlign={'center'}>
						<Text width="200px" fontSize="sm">{label}</Text>
					</VStack>
				</CardBody>
			</Card>
		</Tooltip>
	);
};
