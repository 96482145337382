import { ComponentController as BpController } from '@rta/rta-blueprint-component';
import { KitchenData } from '@rta/rta-blueprint-library';

export type SaveDirtyKitchenLocally = {
	kitchenId: string;
	blueprintId: string;
	customer: string;
	isNew: boolean;
	sourceVersion: number | null;
	data: KitchenData;
};

export const saveDirtyKitchenLocally = (customerEmail: string | null, controller: BpController, save = true): SaveDirtyKitchenLocally => {
	const blueprintId = controller.blueprint?.id ?? controller.kitchen.context.blueprint?.id ?? null;

	const value2Save: SaveDirtyKitchenLocally = {
		kitchenId: controller.kitchen.id,
		blueprintId,
		customer: customerEmail ?? '',
		isNew: controller.kitchen.baseVersion === null,
		sourceVersion: controller.kitchen.baseVersion,
		data: controller.kitchen.serialize()
	};

	// sometime we only want the payload
	if (save) localStorage.setItem(`autosave-${blueprintId}`, JSON.stringify(value2Save));

	return value2Save;
};
