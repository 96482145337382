import { styleSheet } from '../../style-sheet';

import { Image, View } from '@react-pdf/renderer';

export const ProposalDocumentPageBackPanel = () => {
	return (
		<View>
			<View style={styleSheet.containerCenter}>
				<Image src="https://testwebc07.blob.core.windows.net/assets/products/C1PB.png" style={styleSheet.fullImage}/>
			</View>
		</View>
	);
};
