import { Box, FormLabel, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

type level1NumberInputStyleFactoryParams = {
	min?: number;
	max?: number;
	step?: number;
}

const level1LabelStyle = {
	// padding: '0 10px',
	margin: 'auto 0',
	fontSize: 'small'
};

const level1NumberInputStyleFactory = ({ min = 0, max = 999999999, step = 0.125 }: level1NumberInputStyleFactoryParams) => {
	return {
		margin: 'auto 0 0 auto',
		w: '35%',
		allowMouseWheel: true,
		size: 'xs',
		min,
		max,
		step
	};
};

export type InlineNumberInputParams = {
	category?: string;
	label: string;
	enabled?: boolean;
	value: number | null;
	min?: number;
	max?: number;
	step?: number;
	precision?: number;
	placeholder?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange?: (e: any) => void;
};

export const InlineNumberInput = (props: InlineNumberInputParams): JSX.Element => {
	const { category = '', label, enabled = true, min = 0, max = 999999999, step = 0.125, precision = 3 } = props;
	const id = (category + label).toLowerCase();
	const isEnabled = enabled && props.onChange !== null && props.value !== null;

	const [inputValue, setInputValue] = useState<string>(props.value?.toString() ?? props.placeholder ?? '0');
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

	useEffect(() => {
		setInputValue(props.value?.toString() ?? props.placeholder ?? '0');
	}, [props]);

	// wait 500ms after last change before calling onChange
	useEffect(() => {
		if (timeoutId) clearTimeout(timeoutId);
		setTimeoutId(setTimeout(() => {
			if (props.onChange) props.onChange(Number(inputValue) || 0);
		}, 500));
	}, [inputValue]);

	// console.log('render:inline-number-format', props.value, inputValue);
	return (
		<Box display="Flex">
			<FormLabel htmlFor={id} {...level1LabelStyle}>{label}:</FormLabel>
			<NumberInput
				{...{ id, value: inputValue, precision }}
				isDisabled={!isEnabled}
				onChange={setInputValue}
				// onChange={(e) => { if (props.onChange) props.onChange(e || 0); }}
				{...level1NumberInputStyleFactory({ min, max, step })}
			>
				<NumberInputField textAlign={'right'} paddingRight={isEnabled ? '30px' : '0'}/>
				{ isEnabled && <>
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</> }
			</NumberInput>
		</Box>
	);
};
