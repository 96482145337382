/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
import { useDispatch } from 'react-redux';
import { Button, Stack } from '@chakra-ui/react';
import { ComponentDataIn, Components, ReferenceMap, Size, Vertex2Data } from '@rta/rta-blueprint-library';
import { useEffect, useState } from 'react';

import { ContextPanelContent } from './context-panel-content';
import { lang } from '@lang';
import { popContextPanel } from '@state';
import { useDesign } from '@hooks';
import { InlineNumberInput, StackedStringInput } from './controls';

interface AddCutoutPanelProps {
	id: string | null;
	position?: Vertex2Data;
}

export const AddCutoutPanel: React.FC<AddCutoutPanelProps> = (props: AddCutoutPanelProps): JSX.Element => {
	const { id, position = { x: 0, y: 0 }} = props;

	const dispatch = useDispatch();
	const [controller] = useDesign();

	const kitchen = controller?.kitchen ?? null;

	const [refMap, setRefMap] = useState<ReferenceMap | null>(null);
	const [size, setSize] = useState<Size>({ width: 12, height: 12, depth: 3 });
	const [label, setLabel] = useState<string>('CUTOUT');

	useEffect(() => {
		if (kitchen === null || id === null) return;

		// get the reference map for the provided id
		const map = kitchen?.getReferenceMap(id);
		if (map && map.id === id) setRefMap(map);
	}, [kitchen, id]);

	if (!kitchen || !refMap || !refMap.panel) return <></>;

	// console.log('render:AddCutoutPanel', id, position, refMap.panel, refMap.panelItem);

	const title = lang.contextPanels.title.addCutout;

	return <ContextPanelContent title={title}>
		<Stack spacing="12px" width="100%" pt="10px">
			<StackedStringInput
				label="Label"
				placeholder="Label or SKU..."
				value={label}
				onChange={(e) => setLabel(e.target.value)}
			/>
			<InlineNumberInput
				label="Width" min={0.5}
				value={size.width}
				onChange={(valueString) => {
					setSize({ ...size, width: Number(valueString) });
				}}
			/>
			<InlineNumberInput
				label="Height" min={0.5}
				value={size.height}
				onChange={(valueString) => {
					setSize({ ...size, height: Number(valueString) });
				}}
			/>
			<Button
				onClick={() => {
					const category = kitchen.context.categories.find((c) => c.key === 'cutout');
					const componentData: ComponentDataIn = { description: label, size, cutout: size, sku: 'CUTOUT', label, categoryId: category?.id, placement: Components.PLACEMENT_FRONT_ONLY };
					if (refMap && !refMap.panelItemGroup && refMap.panel) {
						const panelItemGroup = refMap.panel.panelItemGroups.new();
						panelItemGroup.center.x = position.x;
						console.log('cutout', position.x, panelItemGroup.center.x);
						// panelItemGroup.center.add(new Vertex3({ dataIn: { x: position.x }}));
						panelItemGroup.items.new({ item: { override: true, component: componentData }});
						// console.log('cutout', panelItemGroup.serialize());
					} else if (refMap && refMap.panelItemGroup && refMap.panel) {
						refMap.panelItemGroup.items.new({ item: { override: true, component: componentData }});
						refMap.panelItemGroup.centerItemsVertically();
						console.log('cutout', position.x, refMap.panelItemGroup.center.x);
						// console.log('cutout', refMap.panelItemGroup.serialize());
					}
					// Close Drawer
					dispatch(popContextPanel());
				}}
			>Insert</Button>
		</Stack>
	</ContextPanelContent>;
};
