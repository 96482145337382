import { BiExitFullscreen, BiFullscreen } from 'react-icons/bi';

import { lang } from '@lang';
import { ViewMode } from './view-mode.enum';
import { Feature, FeatureHoookParams } from '..';
import { selectIsFullScreen, selectViewMode, setFullScreenMode, setViewMode, useAppDispatch, useAppSelector } from '@state';

type ViewModeFeatureParams = FeatureHoookParams;
export const useFullscreenFeature = (_params: ViewModeFeatureParams = {}): Feature<ViewMode> => {
	const dispatch = useAppDispatch();
	const viewMode = useAppSelector(selectViewMode);
	const isFullScreen = useAppSelector(selectIsFullScreen);

	const tooltip = isFullScreen
		? lang.view.tooltip.exitFullscreen
		: lang.view.tooltip.enterFullscreen;

	const label = isFullScreen
		? lang.view.label.exitFullscreen
		: lang.view.label.enterFullscreen;

	return {
		name: 'fullscreen',
		label,
		shortcut: '',
		tooltip,
		icon: (props = {}) => { return isFullScreen ? <BiExitFullscreen {...props}/> : <BiFullscreen {...props}/>; },
		disabled: true,
		value: viewMode,
		hidden: false,
		handler: () => {
			// console.log('viewMode', viewMode);
			dispatch(setViewMode(isFullScreen ? ViewMode.notSet : ViewMode.fullScreen));
			dispatch(setFullScreenMode(!isFullScreen));
		}
	};
};
