import { Point, Vertex2Data } from '@rta/rta-blueprint-library';

import { AboutPanel } from './about';
import { AddAppliancePanel } from './add-appliance-panel';
import { AddCustomAppliancePanel } from './add-custom-appliance-panel';
import { AddCutoutPanel } from './add-cutout-panel';
import { AppliancePropertiesPanel } from './appliance-properties';
import { BluePrintValidationPanel } from './blueprint-validation';
import { ConnectionPropertiesPanel } from './connection-properties';
import { ContextPanelMode } from '../../../state-management/enums';
import { CountertopPropertiesPanel } from './countertop-properties-panel';
import { CustomAppliancePropertiesPanel } from './custom-appliance-properties';
import { CutoutPropertiesPanel } from './cutout-properties-panel';
import { DesignerOptionsPanel } from './designer-options';
import { FindCustomerPropertiesPanel } from './find-customer-properties-panel';
import { IslandPropertiesPanel } from './island-properties';
import { KitchenPropertiesPanel } from './kitchen-properties-panel';

import { selectContextPanel, useAppSelector } from '@state';

export const ContextPanel = () => {
	const contextMode = useAppSelector(selectContextPanel);

	const panel = (context: { mode: ContextPanelMode, id: string | null, data: Record<string, unknown>}): JSX.Element => {
		return ({
			[ContextPanelMode.About]: <AboutPanel />,
			[ContextPanelMode.AddAppliance]: <AddAppliancePanel id={context.id} position={(context.data as undefined | { position: Vertex2Data })?.position}/>,
			[ContextPanelMode.AddCustomAppliance]: <AddCustomAppliancePanel id={context.id} position={(context.data as undefined | { position: Vertex2Data })?.position}/>,
			[ContextPanelMode.AddCutout]: <AddCutoutPanel id={context.id} position={(context.data as undefined | { position: Vertex2Data })?.position}/>,
			[ContextPanelMode.ApplianceProperties]: <AppliancePropertiesPanel id={context.id}/>,
			[ContextPanelMode.BlueprintValidation]: <BluePrintValidationPanel />,
			[ContextPanelMode.CountertopProperties]: <CountertopPropertiesPanel id={context.id} />,
			[ContextPanelMode.CustomApplianceProperties]: <CustomAppliancePropertiesPanel id={context.id} />,
			[ContextPanelMode.CutoutProperties]: <CutoutPropertiesPanel id={context.id}/>,
			[ContextPanelMode.DesignerOptions]: <DesignerOptionsPanel />,
			[ContextPanelMode.FindCustomerProperties]: <FindCustomerPropertiesPanel />,
			[ContextPanelMode.IslandProperties]: <IslandPropertiesPanel id={context.id} />,
			[ContextPanelMode.KitchenProperties]: <KitchenPropertiesPanel />,
			[ContextPanelMode.ConnectionProperties]: <ConnectionPropertiesPanel id={context.id} />
		})[context.mode] ?? <></>;
	};

	return contextMode === null ? <></> : panel(contextMode);
};
