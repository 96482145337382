import { RxRulerSquare } from 'react-icons/rx';
import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type ApplianceFeatureParams = FeatureHoookParams;
export const useShowRulersFeature = (_params: ApplianceFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, { options: { showRulers: boolean }}>();

	useSubscription(controller, false, [['component-option-show-ruler', '*']]);

	const controllerSupportsFeature = (controller?.options?.showRulers ?? null) !== null;
	const showRulers = controller?.options?.showRulers ?? true;

	const tooltip = (() => {
		return showRulers ? lang.view.tooltip.hideRulers : lang.view.tooltip.showRulers;
	})();

	return {
		name: 'showRulers',
		label: lang.view.label.toggleRulers,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <RxRulerSquare color={controllerSupportsFeature && showRulers ? 'green' : 'unset'} {...props} />,
		disabled: !controllerSupportsFeature,
		value: showRulers,
		hidden: false,
		handler: () => {
			if (controller) {
				controller.options.showRulers = !showRulers;
			}
		}
	};
};
