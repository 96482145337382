import { Box, Grid,	GridItem } from '@chakra-ui/react';

import { ActionMenuBar } from '../../bars/action-menu-bar';
import { ActionPanel } from '../..';
import { dockConfig } from '../../../config';
import { selectLeftDock, useAppSelector } from '@state';

export const LeftDock = ({ isOpen, dockWidth }: {isOpen: boolean, dockWidth: number}) => {
	const leftDock = useAppSelector(selectLeftDock);
	const { left: leftConfig } = dockConfig;

	const actionPanelWidth = leftDock.isActionPanelOpen ? `${dockWidth - leftConfig.menu.width}px` : '0';
	const style = {
		width: isOpen ? '100%' : '0',
		height: '100%',
		overflow: 'hidden',
		transition: isOpen ? `left ${leftConfig.duration}s ease-in` : `left ${leftConfig.duration}s ease-out`,
		backgrounColor: leftConfig.bgColor
		// border: '1px'
	};

	return (
		<Box style={style}>
			<Grid templateColumns={`${leftConfig.menu.width}px ${actionPanelWidth}`} h="100%" w="100%">
				<GridItem h="100%" w="100%" borderRight={'1px'} overflow="hidden">
					<ActionMenuBar />
				</GridItem>
				<GridItem h="100%" w="100%" borderRight={'1px'}>
					<ActionPanel />
				</GridItem>
			</Grid>
		</Box>
	);
};
