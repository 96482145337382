import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdOutlineGridView } from 'react-icons/md';
import { Box, HStack, Image, Portal, Skeleton, Text, useDisclosure, useOutsideClick } from '@chakra-ui/react';

import { Designs } from './designs.type';
import { RtaIconButton } from '@components';
import { useRef } from 'react';

type DesignViewSelectionProps = {
	designs: Designs,
	selected?: string | null,
	disabled?: boolean,
	onSelect: (id: string, version: number | null, baseVersion: number | null) => void
};

export const DesignViewSelection = (props: DesignViewSelectionProps) => {
	const { isOpen, onToggle } = useDisclosure();
	const ref = useRef(null);

	const { designs, disabled, selected = null, onSelect } = props;

	useOutsideClick({
		ref,
		handler: () => { if (isOpen) onToggle(); }
	});

	return 	<>
		<RtaIconButton icon={<MdOutlineGridView/>} enabled={!disabled} label="View Designs" onClick={onToggle}/>
		<Portal>
			<Box
				ref={ref}
				zIndex={1000}
				position="absolute"
				display={isOpen ? 'block' : 'none'}
				bottom={'40px'}
				left={'0'}
				w="calc(100% - 0px)"
				p="10px 25px"
				bg="gray.100"
				shadow="md"
				// onClick={onToggle}
			>
				<Box position="absolute" right="10px" top="10px" onClick={onToggle}><IoMdCloseCircleOutline size={'1.5em'}/></Box>

				<HStack gap="25px">
					{designs.map((design, i) => <Box key={i}>
						<Box bg="white" border="2px" height="100px" minWidth="100px" overflow={'hidden'}
							borderColor={selected === design.id ? 'blue.500' : 'gray.200'}
							onClick={() => {
								onSelect(design.id, design.version, design.baseVersion);
								onToggle();
							}}>
							<Image
								width="100%"
								height="100%"
								// margin="auto"
								// boxSize="100px"
								objectFit="cover"
								objectPosition="center"
								src={design.thumbnail}
								fallback={<Skeleton/>}
							/>
						</Box>
						<Text textAlign={'center'}>{(design.description ?? '') === '' ? 'no description' : design.description} { design.isDirty ? '*' : '' }</Text>
					</Box>)}
				</HStack>
			</Box>
		</Portal>
	</>;
};
