import { setupListeners } from '@reduxjs/toolkit/query';
import { combineSlices, configureStore } from '@reduxjs/toolkit';

// eslint-disable-next-line no-duplicate-imports
import type { Action, ThunkAction } from '@reduxjs/toolkit';

import { slice as appSlice } from './app/slice';
import { slice as customerSlice } from './customer/slice';
import { slice as featureSlice } from './feature/slice';
import { slice as projectSlice } from './project/slice';
import { slice as uiSlice } from './ui/slice';
import { slice as userSlice } from './user/slice';


// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const rootReducer = combineSlices(appSlice, customerSlice, featureSlice, uiSlice, userSlice, projectSlice);

// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (getAccessTokenSilently: any, preloadedState?: Partial<RootState>) => {
	const store = configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware({
			thunk: {
				extraArgument: { getAccessTokenSilently }
			}
		}),
		// Adding the api middleware enables caching, invalidation, polling,
		// and other useful features of `rtk-query`.
		// middleware: getDefaultMiddleware => {
		// return getDefaultMiddleware().concat(quotesApiSlice.middleware)
		// },
		preloadedState
	});
	// configure listeners using the provided defaults
	// optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
	setupListeners(store.dispatch);

	return store;
};

// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
export const store = makeStore(() => { });

// Infer the type of `store`
export type AppStore = typeof store;

// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch'];

export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;
