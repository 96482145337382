import {
	Box
} from '@chakra-ui/react';

import { ActionPanelMode } from '../../../state-management/enums';
import { CrmActionPanel } from './crm-action-panel';
import { NavigationActionPanel } from './navigation-action-panel';
import { selectActionPanelMode, useAppSelector } from '@state';

export const ActionPanel = () => {
	const actionPanelMode = useAppSelector(selectActionPanelMode);

	const panel = (context: typeof actionPanelMode): JSX.Element => {
		return ({
			[ActionPanelMode.None]: <></>,
			[ActionPanelMode.Crm]: <CrmActionPanel />,
			[ActionPanelMode.Navigation]: <NavigationActionPanel />
		})[context] ?? <></>;
	};

	// if (!controller || controller?.kitchen === null) return <></>;
	// const panelItem = controller.kitchen.design.islands.getDefaultIsland().frontPanel.panelItemGroups[0].items[0] ?? null;
	return (
		<Box width="100%" height="100%">
			{panel(actionPanelMode)}
		</Box>
	);
};
