/* eslint-disable max-statements */
import { Box, HStack, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdOutlineLayers } from 'react-icons/md';

import { RtaIconButton } from '@components';
import {
	useShowApplianceDimensionsFeature,
	useShowApplianceFeature,
	useShowApplianceLabelFeature,
	useShowBreakpointFeature,
	useShowCornerFeature,
	useShowCountertopFeature,
	useShowCutoutFeature,
	useShowDeveloperInsightFeature,
	useShowIslandDimensionFeature,
	useShowIslandOverlapFeature,
	useShowOverheadDimensionFeature,
	useShowPanelInsideDimensionFeature,
	useShowPanelOutsideDimensionFeature,
	useShowTitleFeature
} from '@features';

const Checked = () => (<Box pr="5px"><MdOutlineCheckBox/></Box>);
const Unchecked = () => (<Box pr="5px"><MdOutlineCheckBoxOutlineBlank/></Box>);
export const LayerControls = () => {
	const layerFeatures = [
		useShowApplianceFeature(),
		useShowApplianceDimensionsFeature(),
		useShowApplianceLabelFeature(),
		useShowBreakpointFeature(),
		useShowCornerFeature(),
		useShowCountertopFeature(),
		useShowCutoutFeature(),
		useShowIslandDimensionFeature(),
		useShowTitleFeature(),
		useShowIslandOverlapFeature(),
		useShowOverheadDimensionFeature(),
		useShowPanelInsideDimensionFeature(),
		useShowPanelOutsideDimensionFeature(),
		useShowDeveloperInsightFeature()
	];

	// set all layers enabled if every layerfeature.disable is true
	const allLayersAreDisabled = layerFeatures.every((feature) => feature.disabled);

	return (
		<Box w={'100%'} h={'100%'} m={'1px'} border={'0px'}>
			<HStack h={'100%'} gap={'0px'}>
				<Menu closeOnSelect={false} isLazy>
					<MenuButton as={RtaIconButton} icon={<MdOutlineLayers/>} enabled={!allLayersAreDisabled} label="Manage Layers"/>
					<Portal>
						<MenuList>
							{ layerFeatures.map((feature) => (
								<MenuItem key={feature.name} onClick={feature.handler} isDisabled={feature.disabled}>
									{ feature.value ? <Checked/> : <Unchecked /> }{ feature.label }
								</MenuItem>))
							}
						</MenuList>
					</Portal>
				</Menu>
			</HStack>
		</Box>
	);
};
