/* eslint-disable max-lines */
import { StyleSheet } from '@react-pdf/renderer';

export const styleSheet = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: '#FFF',
		padding: 10,
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: 10
	},
	header: {
		margin: 10,
		padding: 10
	},
	body: {
		margin: 10,
		padding: 10,
		flexGrow: 1
	},
	headerTitle: {
		fontSize: 20,
		marginBottom: 20,
		textAlign: 'center',
		color: 'black',
		fontWeight: 700,
		fontFamily: 'Inter',
		textTransform: 'uppercase'
	},
	flex: {
		flexDirection: 'row',
		gap: 10
	},
	marginBottom: {
		marginBottom: 5
	},
	heading: {
		fontWeight: 700,
		fontFamily: 'Inter',
		fontSize: 10
	},
	headingLarge: {
		fontWeight: 700,
		fontFamily: 'Inter',
		fontSize: 14,
		textAlign: 'center'
	},
	text: {
		fontSize: 10,
		fontWeight: 400,
		fontFamily: 'Inter'
	},
	tableHeaderRow: {
		flexDirection: 'row',
		borderWidth: 1,
		borderColor: '#000',
		borderStyle: 'solid',
		backgroundColor: '#f2f2f2',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 12,
		textAlign: 'center',
		alignContent: 'center',
		height: '.25in'
	},
	tableRow: {
		flexDirection: 'row',
		fontFamily: 'Inter',
		fontWeight: 400,
		textAlign: 'right',
		paddingTop: '5px'
	},
	tableCellBorderedLeft: {
		borderLeftWidth: 1,
		borderLeftColor: '#000',
		borderStyle: 'solid'
	},
	tableCellBorderedRight: {
		borderRightWidth: 1,
		borderRightColor: '#000',
		borderStyle: 'solid'
	},
	tableCellFirst: {
		fontSize: 10,
		width: '6in',
		textAlign: 'left',
		padding: '0 5px 0 5px'
	},
	tableCellPrice: {
		fontSize: 10,
		width: '1in',
		padding: '0 5px 0 5px'
	},
	tableCellSubtotal: {
		fontSize: 10,
		width: '1in',
		padding: '0 5px 0 5px'
	},
	tableColumnQty: {
		fontSize: 10,
		width: '.5in',
		padding: '0 5px 0 5px'
	},
	block: {
		marginBottom: 20
	},
	container: {
		marginVertical: 20
	},
	containerSmall: {
		marginVertical: 10
	},
	containerXSmall: {
		marginBottom: 5
	},
	containerRight: {
		alignItems: 'flex-end',
		flex: 1,
		marginVertical: 20
	},
	header3Column: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	headerSingleColumn: {
		width: '20%'
	},
	colorInfo: {
		color: '#8394A4',
		fontSize: 10
	},
	pullRight: {
		textAlign: 'right'
	},
	pullLeft: {
		textAlign: 'left'
	},
	fontWeightBold: {
		fontWeight: 700
	},
	pageHeaderImage: {
		width: 140,
		height: 'auto'
	},
	proposalPage: {
		flexDirection: 'column',
		backgroundColor: '#FFF',
		padding: 15,
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: 10,
		justifyContent: 'space-between'
	},
	containerCenter: {
		marginHorizontal: 'auto'
	},
	pageIntroHeadingRed: {
		color: '#FF3131',
		fontWeight: 700,
		textAlign: 'center'
	},
	pageIntroHeroImage: {
		width: 400,
		height: 'auto',
		marginVertical: 20,
		marginHorizontal: 'auto'
	},
	orangeBox: {
		backgroundColor: '#FF2400',
		borderRadius: 5,
		padding: 10,
		width: '60%',
		fontWeight: 700,
		color: '#FFF',
		textAlign: 'center'
	},
	fontWeightNormal: {
		fontWeight: 400
	},
	fontColorRed: {
		color: '#FF3131'
	},
	text2Col: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		display: 'flex',
		width: '75%',
		alignItems: 'flex-start'
	},
	text2ColItem: {
		width: '45%',
		textAlign: 'left'
	},
	spaceLeft: {
		marginLeft: 60
	},
	lineHeight: {
		lineHeight: 1.4
	},
	tableRevision: {
		width: '70%'
	},
	tableRevisionHeaderRow: {
		flexDirection: 'row',
		backgroundColor: '#A5A5A5',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 12,
		textAlign: 'center',
		alignContent: 'center'
	},
	tableRevisionRow: {
		flexDirection: 'row',
		fontFamily: 'Inter',
		fontWeight: 400,
		textAlign: 'center',
		backgroundColor: '#eee'
	},
	tableRevisionCell: {
		fontSize: 10,
		width: '1.5in',
		padding: 5,
		textAlign: 'center'
	},
	tableRevisionCellDescription: {
		fontSize: 10,
		width: '4in',
		padding: 5,
		textAlign: 'center'
	},
	tableRevisionBorderRight: {
		borderRightWidth: 1,
		borderRightColor: '#fff',
		borderStyle: 'solid'
	},
	tableRevisionBorderLeft: {
		borderLeftWidth: 1,
		borderLeftColor: '#fff',
		borderStyle: 'solid'
	},
	tableRevisionBorderBottom: {
		borderBottomWidth: 1,
		borderBottomColor: '#fff',
		borderStyle: 'solid'
	},
	tableRevision2BorderBottom: {
		borderBottomWidth: 2,
		borderBottomColor: '#fff',
		borderStyle: 'solid'
	},
	tableRevisionRowAlt: {
		flexDirection: 'row',
		fontFamily: 'Inter',
		fontWeight: 400,
		textAlign: 'center',
		backgroundColor: '#d9d9d9'
	},
	tableCasting: {
		width: '30%'
	},
	tableCastingHeaderRow: {
		flexDirection: 'row',
		backgroundColor: '#A5A5A5',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 10,
		textAlign: 'center',
		alignContent: 'center',
		color: '#fff'
	},
	tableCastingRow: {
		flexDirection: 'row',
		fontFamily: 'Inter',
		fontWeight: 400,
		textAlign: 'center',
		backgroundColor: '#eee'
	},
	tableCastingCell: {
		fontSize: 10,
		width: '1in',
		padding: 5,
		textAlign: 'center'
	},
	tableCastingBorderRight: {
		borderRightWidth: 1,
		borderRightColor: '#fff',
		borderStyle: 'solid'
	},
	tableCastingBorderLeft: {
		borderLeftWidth: 1,
		borderLeftColor: '#fff',
		borderStyle: 'solid'
	},
	tableCastingBorderBottom: {
		borderBottomWidth: 1,
		borderBottomColor: '#fff',
		borderStyle: 'solid'
	},
	tableCasting2BorderBottom: {
		borderBottomWidth: 2,
		borderBottomColor: '#fff',
		borderStyle: 'solid'
	},
	tableCastingRowAlt: {
		flexDirection: 'row',
		fontFamily: 'Inter',
		fontWeight: 400,
		textAlign: 'center',
		backgroundColor: '#d9d9d9'
	},
	fullImage: {
		width: 400,
		height: 'auto'
	},
	noteContainer: {
		backgroundColor: '#eee',
		fontSize: 10,
		borderWidth: 1,
		borderColor: '#000',
		borderStyle: 'solid',
		lineHeight: 1.5,
		width: '70%',
		padding: 10
	},
	textUpperCase: {
		textTransform: 'uppercase'
	},
	pullCenter: {
		textAlign: 'center'
	},
	containerSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'stretch',
		gap: 10,
		marginTop: 25
	},
	pageName: {
		textTransform: 'uppercase',
		fontWeight: 700,
		marginTop: 10
	},
	logo: {
		width: 80,
		height: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 20,
		marginBottom: 20
	},
	containerPanel: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	containerPanelFooter: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10
	},
	estimateBPImage: {
		width: 500,
		height: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto'
	}
});
