import { MdAdd } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Center, Flex, Grid, HStack, Input, Text } from '@chakra-ui/react';
import { useContext, useState } from 'react';

import { islandDefinitions } from './islands.data';
import { lang } from '@lang';
import { SubmissionCard } from './submission-card';
import { AppContext, DesignActionTypes } from '@context';
import { ContentPanelMode, selectNavigation, setContentPanelMode, useAppDispatch, useAppSelector } from '@state';

export const NewSubmissionPanel = () => {
	const { user } = useAuth0();
	const { appDispatch } = useContext(AppContext);
	const navigate = useNavigate();
	const appNav = useAppSelector(selectNavigation);
	const [selectedImage, setSelectedImage] = useState('');
	const [submissionName, setSubmissionName] = useState('');
	const dispatch = useAppDispatch();

	const handleImageSelect = (value: string) => {
		setSelectedImage(value);
	};

	const handleSubmissionNameValue = (event: any) => {
		setSubmissionName(event.target.value);
	};

	const handleSaveSubmission = () => {
		const island = islandDefinitions().find((u) => u.label === selectedImage);
		if (island && user?.email && appNav.email) {
			const blueprint = island.definition({ customer: appNav.email, user: user.email });
			const latestVersion = blueprint.kitchens.getLatestVersion();
			if (latestVersion) {
				latestVersion.description = submissionName;
				appDispatch({ type: DesignActionTypes.addBlueprint, payload: { blueprint, version: null, isDirty: false }});
				appDispatch({ type: DesignActionTypes.setIsDirty, payload: { id: blueprint.id, isDirty: true }});
				navigate(`/r/${appNav.email}/${blueprint.id}`);
				dispatch(setContentPanelMode(ContentPanelMode.Designer));
			}
		}
	};

	return (
		<Grid templateRows={'1fr 45px'} padding={5} width="100%" height="100%">
			<Box maxWidth="100%" marginX="auto" overflowY={'auto'} minH="500px">
				<Box>
					<Flex justifyContent="center" gap={5} flexWrap="wrap">
						{islandDefinitions().map((island, index) => (
							<SubmissionCard
								key={index}
								label={island.label}
								src={`${process.env.REACT_APP_ASSETS_BASE_URL}${island.image}`}
								onSelect={handleImageSelect}
								value={island.label}
								isSelected={selectedImage === island.label}
							/>
						))}
					</Flex>
				</Box>
			</Box>
			<Center>
				<HStack>
					<Text fontSize={'md'} >{lang.islandSubmission.label.submissionName}:</Text>
					<Input width="35em" type="text" size="md" autoFocus value={submissionName} onChange={handleSubmissionNameValue} />
					<Button
						leftIcon={<MdAdd />}
						size="md" isDisabled={!submissionName.trim() || !selectedImage} onClick={handleSaveSubmission}
					>{lang.islandSubmission.label.addSubmissionButton}</Button>
				</HStack>
			</Center>
		</Grid>
	);
};
