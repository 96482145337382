import { Box, BoxProps, useTheme } from '@chakra-ui/react';
import { cloneElement, ReactElement, useState } from 'react';

export type RtaIconButtonBoxProps = BoxProps & {
	color?: string;
	height?: string;
	icon: ReactElement;
	enabled?: boolean;
	onClick?: () => void;
};

export const RtaIconButtonBox = (props: RtaIconButtonBoxProps) => {
	const { color, enabled = true, height = '30px', onClick, icon, ...rest } = props;
	const [hover, setHover] = useState(false);
	const theme = useTheme();

	const iconShadow = cloneElement(icon, { opacity: 0.25 });

	return (
		<Box
			{...rest}
			as={ enabled ? 'button' : 'div' }
			padding={'2px'}
			fontSize={`calc(${height} - 4px)`}
			tabIndex={props.tabIndex}
			mx="0px"
			// _hover={enabled ? { transform: 'scale(1.1)', boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.1)' } : {}}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			color={enabled ? color : 'lightgrey'}
			_active={{
				// bg: '#dddfe2',
				transform: 'scale(0.98)',
				borderColor: '#bec3c9'
			}}
			// eslint-disable-next-line no-undefined
			onClick={enabled ? onClick : undefined}
		>
			<Box position="relative">
				<Box zIndex={theme.z.docked + 1}>{icon}</Box>
				{hover && enabled &&
				<Box position="absolute" left="2px" top="2px"
					zIndex={theme.z.docked}
				>{iconShadow}</Box>
				}
			</Box>
		</Box>
	);
};
