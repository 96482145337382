/* eslint-disable max-statements */
import axios from 'axios';
import { Kitchen } from '@rta/rta-blueprint-library';

import { CustomerState } from '@state';
import { TaxPayload } from '../components/panels/estimate-panel/tax-payload.type';

export type FetchTaxParams = {
	// data
	kitchen: Kitchen,
	customer: CustomerState
	// token
	getAccessTokenSilently: () => Promise<string>,
	// callbacks
	setTax?: (tax: number) => void,
	setError?: (error: string) => void
};

export const fetchTax = async (params: FetchTaxParams): Promise<[number | null, string | null]> => {
	// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
	const { kitchen, customer, getAccessTokenSilently, setTax, setError } = params;
	if (kitchen && customer.zipcode) {
		const { itemizedMsrp } = kitchen;
		const taxPayload: TaxPayload = {
			zipcode: customer.zipcode,
			customerCode: customer.email,
			items: itemizedMsrp.items.map((item) => ({
				qty: item.qty,
				amount: item.total,
				sku: item.sku
			}))
		};

		// add countertops
		itemizedMsrp.countertops.forEach((countertop) => {
			if (countertop.total === 0) return;
			taxPayload.items.push({
				qty: 1,
				amount: countertop.total,
				sku: 'countertop'
			});
		});

		// add islands
		itemizedMsrp.islands.forEach((island) => {
			if (island.total === 0) return;
			taxPayload.items.push({
				qty: island.qty,
				amount: island.total,
				sku: island.sku
			});
		});

		// add promotional skus
		if (itemizedMsrp.promotional !== 0) {
			taxPayload.items.push({
				qty: 1,
				amount: itemizedMsrp.promotional,
				sku: 'promotion'
			});
		}

		// no need to call the api if there are no items
		if (taxPayload.items.length === 0) {
			if (setTax) setTax(0);
			return [0, null];
		}

		try {
			const token = await getAccessTokenSilently();
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/calculateSalesTax`, taxPayload, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			// console.log('response ----', response);
			if (response.status === 200) {
				if (setTax) setTax(response.data?.totalTax ?? 0);
				return [response.data?.totalTax ?? 0, null];
			}
		} catch (e) {
			console.warn('error fetching tax', e);
		}

		if (setError) setError('Error fetching tax');
		return [null, 'Error fetching tax'];
	}

	return [null, null];
};
