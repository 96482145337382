import { createContext, Dispatch } from 'react';

import { appCustomerDesignsContextSlice } from './customer-designs/context-slice';

type AppContextType = {
	appState: {
		customerDesigns: appCustomerDesignsContextSlice.State;
	};
	appDispatch: Dispatch<appCustomerDesignsContextSlice.Actions>;
};

const appContext = {
	appState: {
		customerDesigns: appCustomerDesignsContextSlice.stateInitializer()
	},
	appDispatch: () => null
};

export const AppContext = createContext<AppContextType>(appContext);
