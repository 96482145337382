import { Box, Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react';

import { crmLoadCustomer } from '@external';
import { lang } from '@lang';
import { useAuth0 } from '@auth0/auth0-react';
import { selectGetNewCustomerData, setGetNewCustomerData, useAppDispatch, useAppSelector } from '@state';
import { useEffect, useState } from 'react';

export const GetNewCustomerDataModal = (): JSX.Element => {
	const { getAccessTokenSilently } = useAuth0();
	const toast = useToast();

	const dispatch = useAppDispatch();
	const state = useAppSelector(selectGetNewCustomerData);

	const [email, setEmail] = useState<string>('');
	const [customer, setCustomer] = useState<any | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (error) {
			toast({
				title: lang.customer.message.customerNotFound,
				status: 'info',
				duration: 5000,
				isClosable: true
			});
		}
	}, [error]);

	useEffect(() => {
		if (customer) dispatch(setGetNewCustomerData({ isOpen: false, data: customer }));
	}, [customer]);

	const onClose = () => {
		dispatch(setGetNewCustomerData({ isOpen: false, data: customer }));
		setEmail('');
		setError(null);
		setCustomer(null);
	};

	const handleSearch = () => {
		crmLoadCustomer({ email, getAccessTokenSilently, setState: setCustomer, setError });
	};

	return (
		<Modal isOpen={state.isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>{lang.customer.label.newCustomer}</ModalHeader>
				<ModalBody>
					<Box>
						<Text fontSize={'md'} >{lang.customer.label.customerEmail}</Text>
						<Input type="text" size="md" value={email} onChange={(e) => { setEmail(e.target.value); }}/>
					</Box>
				</ModalBody>
				<ModalFooter>
					<Button variant="ghost" mr={3} onClick={onClose}>Cancel</Button>
					<Button colorScheme="green" onClick={handleSearch}>Search</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
