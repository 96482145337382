import { Kitchen } from '@rta/rta-blueprint-library';
import { LiaLinkSolid } from 'react-icons/lia';
import { useClipboard, useToast } from '@chakra-ui/react';

import { encrypt } from '@helpers';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';
import { selectNavigation, useAppSelector } from '@state';

type SocialFeatureParams = FeatureHoookParams;
export const useShareLinkFeature = (_params: SocialFeatureParams = {}): Feature<string> => {
	const [controller] = useDesign();
	const navState = useAppSelector(selectNavigation);

	const kitchen = controller?.kitchen as Kitchen;
	const version = navState.designVersion === null ? (kitchen ? kitchen.version : null) : navState.designVersion;

	const [disabled, tooltip] = (() => {
		if (!navState?.email) return [true, lang.social.tooltip.noEmail];
		if (!navState?.designId) return [true, lang.social.tooltip.noDesign];
		return [false, lang.social.tooltip.shareLink];
	})();

	const submissionId = kitchen?.context?.blueprint?.submissionId;
	const pathParams = submissionId ? `?si=${submissionId}` : '';
	const path = `${navState?.email}/${navState?.designId}/${version}${pathParams}`;
	const link = `${window.origin}/v/${encrypt(path)}`;
	const toast = useToast();

	const { onCopy } = useClipboard(link);

	return {
		name: 'shareLink',
		label: lang.social.label.shareLink,
		icon: (props = {}) => <LiaLinkSolid {...props} />,
		tooltip,
		disabled,
		value: disabled ? '' : link,
		hidden: false,
		handler: () => {
			onCopy();

			toast({
				title: lang.social.message.copiedToClipboard,
				status: 'success',
				duration: 2000,
				isClosable: true
			});
		}
	};
};
