import { Blueprint, connectIslands, EmptyIslandDefaults, newEmptyIsland } from '@rta/rta-blueprint-library';

import { NewSubmissionParams } from '../types';

export const uShapedCornerMiddleShape = (params: NewSubmissionParams): Blueprint => {
	const { customer, user } = params;

	const bp = new Blueprint({ userEmail: user, dataIn: { email: customer }});
	const kitchen = bp.kitchens.getActiveVersion();
	kitchen.description = 'U-Shaped with Middle Corner';

	const grill = newEmptyIsland(kitchen, EmptyIslandDefaults.grill, 0, true);
	const lCorner = newEmptyIsland(kitchen, EmptyIslandDefaults.corner, 315);
	const rCorner = newEmptyIsland(kitchen, EmptyIslandDefaults.corner, 45);
	const lBar = newEmptyIsland(kitchen, EmptyIslandDefaults.bar, 270);
	const rBar = newEmptyIsland(kitchen, EmptyIslandDefaults.bar, 90);

	connectIslands({
		sourcePanel: lCorner.frontPanel.getRightPanel(),
		targetPanel: grill.frontPanel.getLeftPanel()
	});

	connectIslands({
		sourcePanel: rCorner.frontPanel.getLeftPanel(),
		targetPanel: grill.frontPanel.getRightPanel()
	});

	connectIslands({
		sourcePanel: lBar.frontPanel.getRightPanel(),
		targetPanel: lCorner.frontPanel.getLeftPanel()
	});

	connectIslands({
		sourcePanel: rBar.frontPanel.getLeftPanel(),
		targetPanel: rCorner.frontPanel.getRightPanel()
	});

	return bp;
};
