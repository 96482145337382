import { useAuth0 } from '@auth0/auth0-react';
import { Box, Grid, Heading, Input, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { CustomerList } from './customer-list';
import { lang } from '@lang';
import { LoadingSpinner } from './loading-spinner';
import { Customer, fetchCustomers, PagedData } from '@external';
import { setCustomerFeatureFindState, useAppDispatch } from '@state';

export const FindCustomer = () => {
	const email = '';
	const [searchString, setSearchString] = useState<string>(email || '');
	const [pagedData, setPagedData] = useState<PagedData<Customer> | null>(null);
	const [fetchingError, setFetchingError] = useState<string | null>(null);
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
	const { getAccessTokenSilently } = useAuth0();
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (searchString && searchString.length > 0) {
			fetchCustomers({ filter: searchString, limit: 25, getAccessTokenSilently, setData: setPagedData, setError: setFetchingError });
			setLoading(true);
		}
	}, [searchString]);

	useEffect(() => {
		if (pagedData) {
			setLoading(false);
		}
	}, [pagedData]);

	useEffect(() => {
		if (fetchingError) {
			toast({ title: 'Error', description: fetchingError, status: 'error', duration: 5000, isClosable: true });
			setFetchingError(null);
			setLoading(false);
		}
	}, [fetchingError]);

	// on enter or value change clear timer and search
	const performSearch = (e:any) => {
		const searchValue = e.target.value?.trim() ?? '';
		if (timeoutId) clearTimeout(timeoutId);

		const doSearch = () => {
			dispatch(setCustomerFeatureFindState({ selectedEmail: null, customerName: null, thumbnails: [] }));
			setPagedData(null);
			setSearchString(searchValue);
		};

		if (e.key === 'Enter') {
			doSearch();
		} else if (searchValue.length >= 3 && searchValue !== searchString) {
			setTimeoutId(setTimeout(() => {
				doSearch();
			}, 500));
		}
	};

	const spinner = loading ? <Box display="flex" alignItems="center" justifyContent="center" height="100%"><LoadingSpinner /></Box> : null;

	const listSection = loading || !pagedData || pagedData.data?.length === 0
		? <Box rounded="10px" border="1px" borderColor="gray.400" bgColor="gray.200">{spinner}<Text></Text></Box>
		: <CustomerList customers={pagedData?.data ?? []} />;

	const pagingSection = pagedData && pagedData.data?.length > 0
		? <Box pl="10px">
			<Text>
				Your search returned {pagedData.data?.length} customer records.&nbsp;
				{pagedData.pageCount > 1 ? `There are ${pagedData.totalCount} total matching records.` : ''}
			</Text>
		</Box>
		: <Box pl="10px" pt="5px">
			<Text>There are no results to select from. Search by typing in a partial name or email and pressing enter.</Text>
		</Box>;


	// console.log('render:findCustomer');
	return (
		<Box width="100%" height="100%" padding="15px">
			<Grid templateRows="60px 55px 1fr 25px" width="100%" height="100%">
				<Heading as="h3" size="md">{lang.customer.text.findCustomer}</Heading>
				<Box flex={1} marginRight={4}>
					<Input pl="2" bgColor="white" width="100%" variant="flushed" autoFocus placeholder={lang.customer.label.customerPlaceholder} onChange={performSearch}/>
				</Box>
				{listSection}
				{pagingSection}
			</Grid>
		</Box>
	);
};


