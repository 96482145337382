/* eslint-disable max-lines-per-function */
import { Box, Stack, Text, useColorModeValue } from '@chakra-ui/react';

import { ContextPanelContent } from './context-panel-content';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { InlineNumberInput, InlineSlider, StackedStringInput } from './controls';
import { PanelItem, useSubscription } from '@rta/rta-blueprint-library';
import { useEffect, useState } from 'react';

export type AppliancePropertiesPanelProps = {
	id: string | null;
};

export const CutoutPropertiesPanel = ({ id }: AppliancePropertiesPanelProps): JSX.Element => {
	const [controller] = useDesign();

	const [label, setLabel] = useState<string>('');
	const [panelItem, setPanelItem] = useState<PanelItem | null>(null);

	const [event] = useSubscription(controller?.kitchen ?? null, true, [['item_sku', 'updated']]);

	useEffect(() => {
		if (panelItem && event) {
			// if (event.noun === 'item_size') setSize(panelItem.item.size);
			if (event.noun === 'item_sku') setLabel(panelItem.item.sku);
		}
	}, [event]);

	useEffect(() => {
		if (controller) {
			const refMap = controller.kitchen?.getReferenceMap(id ?? '');
			setPanelItem(refMap.panelItem);
			setLabel(refMap.panelItem.item.sku);
		}
	}, [controller, id]);

	if (!controller || panelItem === null) return <></>;

	return <ContextPanelContent title={lang.contextPanels.title.cutoutProperties}>
		<Stack pt="10px" spacing="0" width="100%">
			<StackedStringInput
				label="Label"
				placeholder="Label or SKU..."
				value={panelItem.item.component?.label ?? ''}
				onChange={(e) => {
					if (panelItem.item.component) panelItem.item.component.label = e.target.value;
				}}
			/>

			<Text pt="5px" pl="5px" color={useColorModeValue('gray.600', 'gray.300')} fontSize="medium"
				fontWeight="medium"
			>Dimensional Details</Text>
			<Box marginBottom={3} padding={2} textAlign="left"
				borderLeft="1px solid grey" borderTop="1px" roundedTopLeft="7px"
			>

				<InlineNumberInput
					label="Width" min={0.5}
					value={panelItem.item.size.width}
					onChange={(valueString) => {
						panelItem.item.size = {	width: Number(valueString) };
						panelItem.item.cutout = { width: Number(valueString) };
					}}
				/>
				<InlineNumberInput
					label="Height" min={0.5}
					value={panelItem.item.size.height}
					onChange={(valueString) => {
						panelItem.item.size = {	height: Number(valueString) };
						panelItem.item.cutout = { height: Number(valueString) };
					}}
				/>
			</Box>
			<>
				<Text pl="5px" color={useColorModeValue('gray.600', 'gray.300')} fontSize="medium"
					fontWeight="medium"
				>Dimension Positioning</Text>
				<Box marginBottom={3} padding={2} textAlign="left"
					borderLeft="1px solid grey" borderTop="1px" roundedTopLeft="7px"
				>
					<Stack spacing="3" width="100%" height="65%">
						<InlineSlider
							category="dimension" label="H. Line"
							min={0} max={1} step={0.01}
							value={ panelItem.dimensionOffsets.hLine }
							onChange={(e) => {
								panelItem.dimensionOffsets = { hLine: e };
							}}
						/>
						<InlineSlider
							category="dimension" label="H. Label"
							min={0} max={1} step={0.01}
							value={ panelItem.dimensionOffsets.hLabel }
							onChange={(e) => {
								panelItem.dimensionOffsets = { hLabel: e };
							}}
						/>
						<InlineSlider
							category="dimension" label="V. Line"
							min={0} max={1} step={0.01}
							value={ panelItem.dimensionOffsets.vLine }
							onChange={(e) => {
								panelItem.dimensionOffsets = { vLine: e };
							}}
						/>
						<InlineSlider
							category="dimension" label="V. Label"
							min={0} max={1} step={0.01}
							value={ panelItem.dimensionOffsets.vLabel }
							onChange={(e) => {
								panelItem.dimensionOffsets = { vLabel: e };
							}}
						/>
					</Stack>
				</Box>
			</>
		</Stack>
	</ContextPanelContent>;
};
