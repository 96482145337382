import { Box, HStack } from '@chakra-ui/react';

import { RtaFeatureIconButton } from '@components';
import { useCustomerPresentationFeature, useFullscreenFeature } from '@features';

export const ViewModeControls = () => {
	const customerPresentationMode = useCustomerPresentationFeature();
	const fullscreenMode = useFullscreenFeature();

	return (
		<Box w={'100%'} h={'100%'} m={'1px'} border={'0px'}>
			<HStack h={'100%'} gap={'5px'}>
				<RtaFeatureIconButton feature={customerPresentationMode} />
				<RtaFeatureIconButton feature={fullscreenMode} />
			</HStack>
		</Box>
	);
};
