import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react';

import { lang } from '@lang';
import { saveCustomerBlueprint } from '@external';
import { useAuth0 } from '@auth0/auth0-react';
import { useDesign } from '@hooks';
import { selectSetPushSubmissionDataModal, setPushSubmissionDataModal, useAppDispatch, useAppSelector } from '@state';
import { useEffect, useState } from 'react';


export const PushSubmissionModal = (): JSX.Element => {
	const [controller] = useDesign();
	const { getAccessTokenSilently } = useAuth0();
	const toast = useToast();

	const dispatch = useAppDispatch();
	const state = useAppSelector(selectSetPushSubmissionDataModal);

	const [success, setSuccess] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (controller && success !== null) {
			toast({
				title: lang.customer.message.pushSubmissionSuccess,
				status: 'success',
				duration: 2000,
				isClosable: true
			});
		}
	}, [success]);

	useEffect(() => {
		if (error !== null) {
			toast({
				title: lang.customer.message.pushSubmissionFailed,
				status: 'error',
				duration: 2000,
				isClosable: true
			});
		}
	}, [error]);

	const onClose = () => {
		dispatch(setPushSubmissionDataModal(false));
	};

	const handlePushSubmission = () => {
		if (controller) {
			saveCustomerBlueprint({
				controller,
				mode: 'test',
				getAccessTokenSilently,
				setSuccess,
				setError
			});

			dispatch(setPushSubmissionDataModal(false));
		}
	};

	return (
		<Modal isOpen={state.isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<>
					<ModalHeader>Warning</ModalHeader>
					<ModalBody>
						<Text>{lang.customer.message.pushSubmission}</Text>
					</ModalBody>

					<ModalFooter>
						<Button variant="ghost" mr={3} onClick={onClose}>Cancel</Button>
						<Button colorScheme="green" onClick={handlePushSubmission}>Continue</Button>
					</ModalFooter>
				</>
			</ModalContent>
		</Modal>
	);
};
