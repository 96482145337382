export const contextPanels = {
	title: {
		about: 'About',
		addAppliance: 'Add Appliance',
		addCustomAppliance: 'Add Custom Appliance',
		addCutout: 'Add Cutout',
		applianceProperties: 'Appliance Properties',
		blueprintValidation: 'Validation Items',
		connectionProperties: 'Connection Properties',
		countertopProperties: 'Countertop Properties',
		customApplianceProperties: 'Custom Appliance Properties',
		cutoutProperties: 'Cutout Properties',
		designerOptions: 'Designer Options',
		findCustomerProperties: 'Selection Details',
		islandProperties: 'Island Properties',
		kitchenProperties: 'Kitchen Properties',
		swapAppliance: 'Swap Appliance'
	},
	tooltip: {
	},
	message: {
		swapAppliance: 'Note: Swap appliances should match same item placement.'
	}
};
