export const customerLang = {
	label: {
		findCustomer: 'Find Customer',
		loadCustomer: 'Load Customer',
		newCustomer: 'New Customer',
		noRecentCustomers: 'No recent entries found.',
		recentCustomers: 'Recent Customers',
		saveSubmission: 'Save Current Submission',
		customerPlaceholder: 'Search a customer by email or name.',
		customerEmail: 'Customer Email',
		autoSaveSubmission: 'Auto Save Submission',
		deleteCustomerDrafts: 'Delete Customer Drafts',
		deleteAllDrafts: 'Delete All Drafts',
		pushSubmission: 'Push To Test'
	},
	text: {
		findCustomer: 'Find a customer with existing blueprint..'
	},
	tooltip: {
		clickToFindCustomer: 'Click to find customer',
		clickToAddCustomer: 'Click to Add a New Customer',
		noController: 'No blueprint available',
		notDirty: 'No changes to save',
		saveDesign: 'Click to Save the Current Design',
		pushSubmission: 'Push the current submission to the test environment',
		pushSubmissionDisabled: 'Select a design to enable this feature',
		deleteCustomerDrafts: 'Delete Customer Drafts',
		noCustomerDrafts: 'No drafts available for this customer',
		deleteAllDrafts: 'Delete All Drafts',
		noDrafts: 'No drafts available'
	},
	message: {
		openCustomerWarning: 'This action will close the current customer',
		errorFetchingUser: 'Error fetching user',
		customerNotFound: 'Customer not found in Hubspot. Check your entry and try again.',
		saveFailed: 'Blueprint save failed. Please try again.',
		saveSuccess: 'Blueprint saved successfully',
		pushSubmission: 'This action will push the current submission to the test environment. Do you want to proceed?',
		pushSubmissionSuccess: 'Submission pushed successfully',
		pushSubmissionFailed: 'Submission push failed',
		deleteCustomerDraftsWarning: 'This action will delete all drafts for this customer',
		customerDraftsDeleted: 'Customer drafts deleted.',
		deleteAllDraftsWarning: 'This action will delete all drafts for all customers',
		allDraftsDeleted: 'All drafts deleted.'
	}
};
