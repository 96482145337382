/* eslint-disable max-statements */
import axios from 'axios';

import { Controller } from '@context';
import { CustomerState } from '@state';

export type SaveCustomerBlueprintParams = {
	controller: Controller,
	// customer: CustomerState,
	getAccessTokenSilently: () => Promise<string>,
	mode: string,
	// designName: string,
	setSuccess?: (success: string) => void,
	setError?: (error: string) => void
};

export const saveCustomerBlueprint = async (params: SaveCustomerBlueprintParams): Promise<[string | null, string | null]> => {
	// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
	const { controller, getAccessTokenSilently, setSuccess, setError, mode } = params;

	const kitchen = controller?.kitchen ?? null;
	const blueprint = kitchen?.context?.blueprint ?? null;
	const customerEmail = blueprint?.email;
	const submissionId = blueprint?.submissionId ?? blueprint?.id;
	// const blueprintData = blueprint.serialize();

	// const blueprintData1 = {
	// 	email: customerEmail,
	// 	date: designName,
	// 	layout: kitchenData.layout,
	// 	islands: kitchenData.design.islands.serialize(),
	// 	state: [],
	// 	surround: kitchenData.material.surround.displayName,
	// 	countertop: kitchenData.material.countertop.displayName,
	// 	estimateNumber: '<none>',
	// 	invoiceNumber: kitchenData.quoteId ?? '<none>',
	// 	workordernumber: '<none>',
	// 	fuelchoice: kitchenData.fuelchoice
	// };

	try {
		const token = await getAccessTokenSilently();
		const apiUrl = mode === 'test' ? process.env.REACT_APP_API_URL_TEST : process.env.REACT_APP_API_URL;
		const response = await axios.put(`${apiUrl}/customers/${customerEmail}/blueprints/${submissionId}`, kitchen.serialize(), {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		console.log('responseon', response.status, response.data);
		if (response.status === 200) {
			if (setSuccess) setSuccess('Blueprint saved successfully');
			return [response.data ?? null, null];
		}

		throw new Error('Error saving blueprint');
	} catch (error) {
		console.log('error', error);
		if (setError) setError('Error saving blueprint');
		return [null, 'Error saving blueprint'];
	}

	return [null, null];
};
