/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
import { FaDownload } from 'react-icons/fa';
import { useToast } from '@chakra-ui/react';

import { EstimateDocument } from '../../components/documents';
import { lang } from '@lang';
import { pdf } from '@react-pdf/renderer';
import { useDesign } from '@hooks';
import { ContentPanelMode, customerState, projectState, setContentPanelMode, useAppDispatch, useAppSelector } from '@state';
import { Feature, FeatureHoookParams } from '..';

import { Kitchen3dController as TdComponentController } from '@rta/rta-blueprint-3d-component';

type EstimateFeatureParams = FeatureHoookParams & {
	tax: number;
};

export const useEstimateDownloadFeature = (params: EstimateFeatureParams): Feature<null> => {
	const { tax } = params;
	const toast = useToast();

	const [controller, designContext] = useDesign<TdComponentController>();

	const customer = useAppSelector(customerState);
	const project = useAppSelector(projectState);
	const dispatch = useAppDispatch();

	const date = new Date();
	const year = date.getFullYear().toString()
		.slice(-2);
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString()
		.padStart(2, '0');
	const hour = date.getHours().toString()
		.padStart(2, '0');
	const minutes = date.getMinutes().toString()
		.padStart(2, '0');
	const dateString = `${year}${month}${day}${hour}${minutes}`;
	const fileName = `${customer.firstName.toLowerCase()}-${customer.lastName.toLowerCase()}-estimate-${dateString}.pdf`;

	const [disabled, tooltip] = (() => {
		if (customer.email === '') return [true, lang.document.tooltip.missingCustomer];
		return [false, lang.document.tooltip.generateEstimate];
	})();

	// wait for Image
	const waitForImage = async () => {
		let image = designContext?.tdController?.getImage();
		while (image === null || image === '') {
			image = designContext?.tdController?.getImage();
			await new Promise((resolve) => setTimeout(resolve, 1000));
		}
		return image ?? '';
	};

	const handleDocumentDownloadClick = async () => {
		await dispatch(setContentPanelMode(ContentPanelMode.ThreeD));

		const imageData = await waitForImage();
		// const imageData = designContext?.tdController?.getImage() ?? '';
		const EstimateDoc = <EstimateDocument data={ { customer, project, tax, kitchen: controller?.kitchen ?? null, image: imageData } } />;

		const blob = await pdf(EstimateDoc).toBlob();
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = fileName;
		link.click();
	};

	return {
		name: 'estimateDocument',
		label: lang.document.label.downloadEstimate,
		icon: (props = {}) => <FaDownload {...props} />,
		tooltip,
		disabled,
		value: null,
		hidden: false,
		handler: () => {
			handleDocumentDownloadClick();
			toast({
				title: lang.document.message.downloadEstimate,
				status: 'info',
				duration: 2000,
				isClosable: true
			});
		}
	};
};
