/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
import { useDispatch } from 'react-redux';
import { Box, Button, Center, FormLabel, Image, Select, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { Blueprint, BlueprintInstanceType, BoundToEdge, Component, COMPONENT_PLACEMENT_FRONT_ONLY, Components, EventCrudMessage, PanelItem, PanelItemData, PanelItemGroup, Point, ReferenceMap, Vertex2, Vertex2Data, Vertex3 } from '@rta/rta-blueprint-library';
import { useEffect, useRef, useState } from 'react';

import { calculatePriceDelta } from './helpers';
import { ContextPanelContent } from './context-panel-content';
import { formatPrice } from '@helpers';
import { lang } from '@lang';
import { popContextPanel, setContextPanelIsOpen } from '@state';
import { useDesign } from '@hooks';
import { InlineNumberInput, InlineSlider, StackedStringInput } from './controls';

interface AddCustomAppliancePanelProps {
	id: string | null;
	position?: Vertex2Data;
}

export const AddCustomAppliancePanel: React.FC<AddCustomAppliancePanelProps> = (props: AddCustomAppliancePanelProps): JSX.Element => {
	const { id, position = { x: 0, y: 0 }} = props;

	const colorSectionHeader = useColorModeValue('gray.600', 'gray.300');

	const dispatch = useDispatch();
	const [controller] = useDesign();

	const kitchen = controller?.kitchen ?? null;
	// const vendors = kitchen?.context.vendors;
	const categories = kitchen?.context.categories;
	// const components = kitchen?.context.components ?? [];

	const [refMap, setRefMap] = useState<ReferenceMap | null>(null);
	// const [vendorId, setVendorId] = useState('');
	// const [categoryId, setCategoryId] = useState('');

	const [gasChoice, setGasChoice] = useState<string>('');
	const [panelItem, setPanelItem] = useState<PanelItem | null>(null);
	const [piEvent, setPiEvent] = useState<EventCrudMessage | null>(null);
	const controllerSubs = useRef<string[]>([]);

	useEffect(() => {
		if (kitchen === null || id === null) return;

		// get the reference map for the provided id
		const map = kitchen?.getReferenceMap(id);
		if (map && map.id === id) setRefMap(map);

		if (map.type === BlueprintInstanceType.PanelItem) {
			setPanelItem(map.panelItem);
		} else {
			setPanelItem(new PanelItem({
				parent: map.kitchenDesign,
				dataIn: {
					item: {
						override: true,
						component: {
							description: 'Custom Appliance',
							sku: 'CUSTOM',
							categoryId: categories?.find((cat) => cat.key === 'appliance')?.id ?? '',
							placement: COMPONENT_PLACEMENT_FRONT_ONLY,
							cutout: { width: 12, height: 12, depth: 1 },
							size: { width: 13, height: 13, depth: 1 }
						}
					}
				}
			}));
		}
	}, [kitchen, id]);

	useEffect(() => {
		if (panelItem) {
			panelItem.item.component.readonly = false;
			const sub = panelItem.subscribe('*', '*', setPiEvent);
			if (sub) controllerSubs.current.push(sub);
		}

		// cleanup subscriptions
		return () => controllerSubs.current.forEach((sub: string) => controller?.unsubscribe(sub));
	}, [panelItem]);

	// update the panel item when the event is updated
	// useEffect(() => {
	// 	if (piEvent) console.log('AddCustomAppliancePanel:useEffect:piEvent', piEvent);
	// }, [piEvent]);

	if (!kitchen || !refMap || !refMap.panel || !panelItem) return <></>;

	// console.log('render:AddCustomAppliancePanelItem', id, position, refMap.panel, panelItem);
	const title = lang.contextPanels.title.addCustomAppliance;

	console.log('panelItem', panelItem);

	return <ContextPanelContent title={title}>
		<Stack width="100%" pt="10px" spacing={'5px'}>
			<StackedStringInput
				label="Sku"
				placeholder="Sku code..."
				value={panelItem.item.component?.label}
				onChange={((e) => {
					if (panelItem.item.component) panelItem.item.component.label = e.target.value;
				})}
			/>
			<StackedStringInput
				label="Description"
				placeholder="Appliance description..."
				value={panelItem.item.component?.description ?? ''}
				onChange={((e) => {
					if (panelItem.item.component) panelItem.item.component.description = e.target.value;
				})}
			/>

			<Box>
				<FormLabel htmlFor="vendor" fontSize="small" marginBottom="0">Vendor:</FormLabel>
				<Select id="vendor" height="2em"
					fontSize="small"
					value={panelItem.item.component?.vendorId ?? ''}
					onChange={(e) => {
						if (panelItem.item.component) {
							panelItem.item.component.vendorId = e.target.value;
						}
					}}
				>
					<option value="">Other</option>
					{ kitchen.context.vendors
						.filter((v) => v.active)
						.map((v, i) => <option key={i} value={v.id}>{v.displayValue}</option>)}
				</Select>
			</Box>

			<Box>
				<FormLabel htmlFor="appliance-type" fontSize="small" marginBottom="0">Type:</FormLabel>
				<Select id="appliance-type" height="2em"
					fontSize="small"
					value={panelItem.item.component?.categoryId ?? ''}
					onChange={(e) => {
						if (panelItem.item.component) panelItem.item.component.categoryId = e.target.value;
					}}
				>
					{ kitchen.context.categories
						.filter((c) => c.active)
						.map((cat, i) => <option key={i} value={cat.id}>{cat.description}</option>)}
				</Select>
			</Box>

			<Box>
				<FormLabel htmlFor="appliance-placement" fontSize="small" marginBottom="0">Placement:</FormLabel>
				<Select id="appliance-placement" height="2em"
					fontSize="small"
					value={panelItem.item.component?.placement ?? ''}
					onChange={(e) => {
						if (panelItem.item.component) panelItem.item.component.placement = e.target.value;
					}}
				>
					{ Components.placement.map((p, i) => {
						return <option key={i} value={p.key}>{p.displayName}</option>;
					})}
					{/* { availablePlacements.map((placement) => <option value={placement.value}>{placement.displayName}</option>)} */}
				</Select>
			</Box>

			<Box>
				<FormLabel htmlFor="appliance-gas-choice" fontSize="small" marginBottom="0">Fuel Options:</FormLabel>
				<Select id="appliance-gas-choice" height="2em"
					fontSize="small"
					value={gasChoice}
					onChange={(event) => setGasChoice(event.target.value)}
				>
					<option value="">Use Kitchen Default</option>
					<option value="CH">Charcoal</option>
					<option value="EL">Electric</option>
					<option value="PE">Pellet</option>
					<option value="LP">Propane</option>
					<option value="NG">Natural Gas</option>
				</Select>
			</Box>

			<Box marginBottom={3} padding={2} textAlign="left" borderLeft="1px solid grey">
				<Text paddingBottom={3} fontSize="medium">Dimensional Info.</Text>
				<Stack spacing="3" width="100%" height="65%">
					<InlineNumberInput
						category="cutout"
						label="Cutout Width"
						min={0.5}
						value={panelItem.item.cutout.width}
						onChange={(e) => { panelItem.item.cutout = { width: e }; }}
					/>
					<InlineNumberInput
						category="cutout"
						label="Cutout Height"
						min={0.5}
						value={panelItem.item.cutout.height}
						onChange={(e) => { panelItem.item.cutout = { height: e }; }}
					/>
					<InlineNumberInput
						category="cutout"
						label="Cutout Depth"
						min={0}
						value={panelItem.item.cutout.depth}
						onChange={(e) => { panelItem.item.cutout = { depth: e }; }}
					/>
					<InlineNumberInput
						category="appliance" label="Overall Width" min={0.5}
						value={panelItem.item.size.width}
						onChange={(e) => { panelItem.item.size = { width: e }; }}
					/>
					<InlineNumberInput
						category="appliance" label="Overall Height" min={0.5}
						value={panelItem.item.size.height}
						onChange={(e) => { panelItem.item.size = { height: e }; }}
					/>
					<InlineNumberInput
						category="appliance" label="Overall Depth" min={0}
						value={panelItem.item.component.size.depth}
						onChange={(e) => { panelItem.item.size = { depth: e }; }}
					/>
					<InlineNumberInput
						category="appliance" label="Distance Below Counter" min={0}
						value={ panelItem.item.component.distanceBelowCountertop ?? 0 }
						onChange={(e) => { panelItem.item.component.distanceBelowCountertop = e; }}
					/>
					<InlineNumberInput
						category="appliance" label="Distance Front Of Face" min={0}
						value={ panelItem.item.component.distanceFrontOfFace }
						onChange={(e) => { panelItem.item.component.distanceFrontOfFace = e; }}
					/>
				</Stack>
			</Box>

			{ refMap.panelItem &&
				<Box>
					<Text pt="5px" pl="5px" color={colorSectionHeader} fontSize="medium"
						fontWeight="medium"
					>Dimensional Details</Text>
					<Box mb={3} padding={2} textAlign="left"
						borderLeft="1px solid grey" borderTop="1px" roundedTopLeft="7px"
					>
						<Stack spacing="3" width="100%" height="65%">
							<InlineSlider
								category="dimension" label="H. Line"
								min={0} max={1} step={0.01}
								value={ panelItem.dimensionOffsets.hLine }
								onChange={(e) => {
									panelItem.dimensionOffsets.hLine = e;
								}}
							/>
							<InlineSlider
								category="dimension" label="H. Label"
								min={0} max={1} step={0.01}
								value={ panelItem.dimensionOffsets.hLabel }
								onChange={(e) => {
									panelItem.dimensionOffsets.hLabel = e;
								}}
							/>
							<InlineSlider
								category="dimension" label="V. Line"
								min={0} max={1} step={0.01}
								value={ panelItem.dimensionOffsets.vLine }
								onChange={(e) => {
									panelItem.dimensionOffsets.vLine = e;
								}}
							/>
							<InlineSlider
								category="dimension" label="V. Label"
								min={0} max={1} step={0.01}
								value={ panelItem.dimensionOffsets.vLabel }
								onChange={(e) => {
									panelItem.dimensionOffsets.vLabel = e;
								}}
							/>
						</Stack>
					</Box>
				</Box>
			}

			<Box pt="10px" width="100%">
				<Button width="100%"
					onClick={() => {
						dispatch(popContextPanel());

						// eslint-disable-next-line no-bitwise
						panelItem.item.component.distanceAboveCountertop = (panelItem.boundToEdge & BoundToEdge.Top) > 0
							? Math.max(0, panelItem.item.size.height - panelItem.item.distanceBelowCountertop)
							: null;

						panelItem.center.y = position.y;
						panelItem.item.component.sku = 'CUSTOM';

						if (!refMap.panelItem) {
							if (refMap && !refMap.panelItemGroup && refMap.panel) {
								const panelItemGroup = refMap.panel.panelItemGroups.new();
								panelItemGroup.center.x = position.x;
								// panelItemGroup.center.add(new Vertex3({ dataIn: { x: position.x }}));
								panelItemGroup.items.new(panelItem.serialize());
							} else if (refMap && refMap.panelItemGroup && refMap.panel) {
								refMap.panelItemGroup.items.new(panelItem.serialize());
							}
						}
					}}
				>{refMap.panelItem ? 'Close' : 'Add'}</Button>
			</Box>
		</Stack>
	</ContextPanelContent>;
};
