export const blueprintStatus = {
	label: {
		good: 'Good',
		warn: 'Warning',
		error: 'Error',
		critical: 'Critical',
		blueprintValidation: 'Blueprint Validation',
		createdBy: 'Created By',
		createdDate: 'Created Date',
		acceptedBy: 'Accepted By',
		acceptedDate: 'Accepted Date',
		notificationType: 'Notification Type'
	},
	tooltip: {
		noNotifications: 'No notifications',
		noDesign: 'feature is unavailable without a design'
	},
	message: {
	}
};
