import { useEffect } from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { useMatch, useParams } from 'react-router-dom';

import { rtaTheme } from '../theme';
import { useLoadCustomerDesigns } from '@context';
import { AppLoadingPanel, BottomDock, ConfirmCustomerCloseAlert, DeleteAllDraftsModal, DeleteCustomerDraftsModal, GetNewCustomerDataModal, MainArea, PushSubmissionModal, SaveAsDraftAlert, TopDock } from '@components';
import { selectNavigation, setNavigation, useAppDispatch, useAppSelector } from '@state';

import './app.css';

export const App = () => {
	const appNav = useAppSelector(selectNavigation);
	const dispatch = useAppDispatch();

	const matchA = useMatch({ path: '/:type/*' });
	const params = useParams();

	useEffect(() => {
		dispatch(setNavigation({ mode: matchA?.params.type ?? 'r', params }));
	}, [params]);

	useLoadCustomerDesigns({ appNav });

	// console.log('render:App');
	return (<>
		<ChakraProvider theme={rtaTheme}>
			<ColorModeScript />

			{/* application loading / bootstrapping */}
			<AppLoadingPanel/>

			{/* application areas */}
			<TopDock/>
			<MainArea/>
			<BottomDock/>

			{/* application alerts */}
			<div>
				<ConfirmCustomerCloseAlert/>
				<SaveAsDraftAlert/>
			</div>

			{/* application modals */}
			<div>
				<GetNewCustomerDataModal/>
				<PushSubmissionModal/>
				<DeleteCustomerDraftsModal/>
				<DeleteAllDraftsModal/>
			</div>

		</ChakraProvider>
	</>);
};

