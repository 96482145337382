import { App } from '.';
import { AppContext, initializeContext } from '@context';

export const AppRootContextProvider = () => {
	const context = initializeContext();
	// console.log('render:AppRootContextProvider');
	return (
		<AppContext.Provider value={context}>
			<App />
		</AppContext.Provider>
	);
};
