export const debug = {
	label: {
		about: 'About'
	},
	tooltip: {
		about: 'Click to view the versions'
	},
	message: {
	}
};
