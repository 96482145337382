import { LiaCameraSolid } from 'react-icons/lia';
import { useToast } from '@chakra-ui/react';

import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type SnapshotFeatureParams = FeatureHoookParams;
export const useSnapshotFeature = (_params: SnapshotFeatureParams = {}): Feature<string | null> => {
	const [controller] = useDesign();
	const toast = useToast();

	const [disabled, tooltip] = (() => {
		if (controller === null) return [true, lang.social.tooltip.noDesign];
		return [false, lang.social.tooltip.snapshot];
	})();

	return {
		name: 'shareLink',
		label: lang.social.label.shareLink,
		icon: (props = {}) => <LiaCameraSolid {...props} />,
		tooltip,
		disabled,
		value: controller?.getImage() ?? null,
		hidden: false,
		handler: () => {
			toast({
				title: lang.social.message.downloadSnapshot,
				status: 'info',
				duration: 2000,
				isClosable: true
			});
		}
	};
};
