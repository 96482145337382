/* eslint-disable max-lines-per-function */
import { Island } from '@rta/rta-blueprint-library';
import { ComponentController as BpController, DisplayMode } from '@rta/rta-blueprint-component';
import { Button, Grid, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, showPanelFeature, useDisplayOverheadModeFeature, useDisplayPanelModeFeature } from '@features';

export const NavigationActionPanel = (): JSX.Element => {
	const [controller] = useDesign<BpController>();

	let island: Island | null = null;
	if (controller?.instanceOf === 'bp-controller' && controller.navigation?.islandId) {
		island = controller.kitchen.design.islands.findById(controller.navigation.islandId);
	}

	const displayOverheadModeFeature = useDisplayOverheadModeFeature();
	const displayPanelModeFeature = useDisplayPanelModeFeature();
	const displayMode = controller?.navigation?.displayMode === DisplayMode.Overhead ? displayPanelModeFeature : displayOverheadModeFeature;

	const navFeature: Record<string, Feature<unknown>> = {};
	if (island) navFeature.countertop = showPanelFeature({ controller: controller as Controller, label: 'countertop' });

	island?.panels.children.forEach((panel) => {
		navFeature[panel.label] = showPanelFeature({ controller: controller as Controller, panelId: panel.id, label: panel.label });
	});

	return (
		<Grid width="100%" height="100%" templateRows="auto 1fr">
			<HStack h="35px" w="full" background="blue.400" px={6} py={2} justifyContent={'center'} mb={2}>
				<Text textAlign={'center'} color={'white'} fontSize={18}>{lang.actionPanels.title.navigation}</Text>
			</HStack>
			<VStack w="full" spacing={4} py={2}>
				<Tooltip label={displayMode.tooltip} aria-label={displayMode.tooltip}>
					<Button width="150px" textTransform="capitalize" isDisabled={displayMode.disabled} onClick={displayMode.handler}>
						{displayMode.label}
					</Button>
				</Tooltip>
				{
					Object.keys(navFeature).map((key, i) => (
						<Tooltip key={i} label={navFeature[key].tooltip} aria-label={navFeature[key].tooltip}>
							<Button width="150px" textTransform="capitalize" isDisabled={navFeature[key].disabled} onClick={navFeature[key].handler}>
								{navFeature[key].label}
							</Button>
						</Tooltip>
					))
				}
			</VStack>
		</Grid>
	);
};
