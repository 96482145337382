import { Actions as A } from './actions';
import { ActionTypes as aT } from './action-types';
import { stateInitializer as init } from './state-initializer';
import { reducer as reduce } from './reducer';
import { State as S } from './state.type';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace appCustomerDesignsContextSlice {
	export type Actions = A;
	export const ActionTypes = aT;
	export type State = S;
	export const stateInitializer = init;
	export const reducer = reduce;
}
