import { IoMdLocate } from 'react-icons/io';
import { Box, Divider, HStack, Text } from '@chakra-ui/react';

import { useDesign } from '@hooks';
import { RtaFeatureIconButton, RtaIconButton } from '@components';
import { useZoomInFeature, useZoomOutFeature } from '@features';

export const NavigationControls = () => {
	const [controller] = useDesign();

	const zoomOut = useZoomOutFeature();
	const zoomIn = useZoomInFeature();
	// console.log('NavigationControls', controller?.zoom);

	return (
		<Box w={'100%'} h={'100%'} m={'1px'} border={'0px'}>
			<HStack h={'100%'} gap={'5px'}>
				<RtaIconButton enabled={false} icon={<IoMdLocate/>} label="Center" height="26px"/>
				<Divider orientation={'vertical'} ml="5px"/>
				<RtaFeatureIconButton feature={zoomOut} />
				<Text fontSize="lg" as="b" width={'70px'}
					textAlign="center" verticalAlign={'middle'}
				>{controller?.zoom ?? '---'}%</Text>
				<RtaFeatureIconButton feature={zoomIn} />
			</HStack>
		</Box>
	);
};
