import { NavigateFunction } from 'react-router';

import { onRedirectCallbackFactory } from './on-redirect-callback';

export const providerConfig = (navigate: NavigateFunction) => {
	return {
		domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
		clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
		onRedirectCallback: onRedirectCallbackFactory(navigate),
		authorizationParams: {
			// eslint-disable-next-line camelcase
			redirect_uri: window.location.origin === '/' ? '/r' : window.location.origin,
			audience: process.env.REACT_APP_AUTH0_AUDIENCE
		}
	};
};
