/* eslint-disable consistent-return */
import { Feature } from '..';
import { lang } from '@lang';
import { setDeleteAllDraftsModal, useAppDispatch } from '@state';

export const useDeleteAllDraftsFeature = (): Feature<boolean> => {
	const dispatch = useAppDispatch();

	const [enabled, tooltip] = (() => {
		const drafts = Object.keys(localStorage).filter((key) => key.startsWith('autosave-'));
		if (drafts.length) {
			return [true, lang.customer.tooltip.deleteAllDrafts];
		}
		return [false, lang.customer.tooltip.noDrafts];
	})();

	return {
		name: 'deleteAllDrafts',
		label: lang.customer.label.deleteAllDrafts,
		shortcut: '',
		tooltip,
		icon: () => <></>,
		disabled: !enabled,
		value: enabled,
		hidden: false,
		handler: () => {
			dispatch(setDeleteAllDraftsModal(true));
		}
	};
};
