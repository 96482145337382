import { Blueprint, connectIslands, EmptyIslandDefaults, newEmptyIsland } from '@rta/rta-blueprint-library';

import { NewSubmissionParams } from '../types';

export const uShapedCornerLeftShape = (params: NewSubmissionParams): Blueprint => {
	const { customer, user } = params;

	const bp = new Blueprint({ userEmail: user, dataIn: { email: customer }});
	const kitchen = bp.kitchens.getActiveVersion();
	kitchen.description = 'U-Shaped with Corner Left';

	const grill = newEmptyIsland(kitchen, EmptyIslandDefaults.grill, 0, true);
	const corner = newEmptyIsland(kitchen, EmptyIslandDefaults.corner, 315);
	const lBar = newEmptyIsland(kitchen, EmptyIslandDefaults.bar, 270);
	const rBar = newEmptyIsland(kitchen, EmptyIslandDefaults.bar, 90);

	connectIslands({
		sourcePanel: corner.frontPanel.getRightPanel(),
		targetPanel: grill.frontPanel.getLeftPanel()
	});

	connectIslands({
		sourcePanel: lBar.frontPanel.getRightPanel(),
		targetPanel: corner.frontPanel.getLeftPanel()
	});

	connectIslands({
		sourcePanel: rBar.frontPanel,
		targetPanel: grill.frontPanel.getRightPanel()
	});

	return bp;
};
