export const view = {
	label: {
		countertopProperties: 'Countertop Properties',
		customerPresentation: 'Customer Presentation Mode',
		enterFullscreen: 'Enter Fullscreen Mode',
		exitFullscreen: 'Exit Fullscreen Mode',
		kitchenProperties: 'Kitchen Properties',
		toggleRulers: 'Toggle Rulers'
	},
	tooltip: {
		countertopProperties: 'Click to open Countertop Properties',
		kitchenProperties: 'Click to open Kitchen Properties',
		onlyOnComponent: 'Only available in Blueprint and 3D views',
		enterFullscreen: 'Click to enter fullscreen mode',
		exitFullscreen: 'Click to exit fullscreen mode',
		naFullscreen: 'Not available in fullscreen mode',
		showRulers: 'Show Rulers',
		hideRulers: 'Hide Rulers'
	},
	message: {
	}
};
