import { useSubscription } from '@rta/rta-blueprint-library';
import { ComponentController as BpController, DisplayMode } from '@rta/rta-blueprint-component';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';

import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type displayPanelModeFeature = FeatureHoookParams;
export const useDisplayPanelModeFeature = (_params: displayPanelModeFeature = {}): Feature<DisplayMode | null> => {
	const [controller] = useDesign();
	let displayMode: DisplayMode | null = null;

	useSubscription(controller, false, [['navigation-display-mode', '*']]);

	const [enabled, tooltip] = (() => {
		const test = controller instanceof BpController;
		if (test) {
			({ displayMode } = controller.navigation);
			return [true, lang.navigation.tooltip.panelMode];
		}

		return [false, lang.navigation.tooltip.displayModeDesignerOnly];
	})();

	return {
		name: 'panel-mode',
		label: lang.navigation.label.panelMode,
		shortcut: '',
		tooltip,
		icon: (props = {}) => {
			return displayMode === DisplayMode.Panel ? <MdOutlineCheckBox {...props}/> : <MdOutlineCheckBoxOutlineBlank {...props} />;
		},
		disabled: !enabled,
		value: displayMode,
		hidden: false,
		handler: () => {
			if (controller && controller instanceof BpController) {
				controller.navigation.displayMode = DisplayMode.Panel;
			}
		}
	};
};
