import { slice } from './slice';

// Action creators are generated for each case reducer function.
// export const {  } =
//   slice.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
	userState
} = slice.selectors;
