import { useEffect, useState } from 'react';

import { Box, FormLabel, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Tooltip } from '@chakra-ui/react';

export type InlineSliderParams = {
	category?: string;
	label: string;
	value: number;
	min: number;
	max: number;
	step: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange?: ((e: any) => void) | null;
};

export const InlineSlider = (props: InlineSliderParams): JSX.Element => {
	const { category = '', label, min, max, step, value, onChange = null } = props;
	const id = (category + label).toLowerCase();
	const isEnabled = onChange !== null;

	const [inputValue, setInputValue] = useState<number>(value);

	useEffect(() => {
		setInputValue(props.value);
	}, [props.value]);

	const handleChange = (e: number) => {
		setInputValue(e);
		if (isEnabled) onChange(e);
	};

	// console.log('render:inline-slider', props.value, inputValue);
	return	(
		<Box display="flex">
			<FormLabel htmlFor={id} fontSize="small" marginBottom={0} width="75px">{label}:</FormLabel>

			{ isEnabled &&
				<Slider {...{ id, min, max, step, value: inputValue, onChange: handleChange }}>
					<SliderTrack>
						<SliderFilledTrack/>
					</SliderTrack>
					<Tooltip label={`${Math.round(inputValue * 100)}%`}>
						<SliderThumb/>
					</Tooltip>
				</Slider>
			}

			{ !isEnabled &&
				<Slider {...{ id, min, max, step, value: inputValue }}>
					<SliderTrack>
						<SliderFilledTrack />
					</SliderTrack>
					<Tooltip label={`${Math.round(inputValue * 100)}%`}>
						<SliderThumb />
					</Tooltip>
				</Slider>
			}
		</Box>
	);
};
