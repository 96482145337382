import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type DeveloperInsightsFeatureParams = FeatureHoookParams;
export const useShowDeveloperInsightFeature = (_params: DeveloperInsightsFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, { options: { showDeveloperInsights: boolean }}>();

	useSubscription(controller, false, [['layer-option-developer-insight', '*']]);

	const controllerSupportsFeature = (controller?.options?.showDeveloperInsights ?? null) !== null;
	const showLayer = controller?.options?.showDeveloperInsights ?? true;

	const tooltip = (() => {
		const showHide = showLayer ? lang.layer.tooltip.hide : lang.layer.tooltip.show;
		return `${showHide} ${lang.layer.label.developerInsights} ${lang.layer.layer}`;
	})();

	return {
		name: 'showDeveloperInsight',
		label: lang.layer.label.developerInsights,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled: !controllerSupportsFeature,
		value: showLayer,
		hidden: false,
		handler: () => {
			if (controller) {
				controller.options.showDeveloperInsights = !showLayer;
			}
		}
	};
};
