export const formatDate = (date: Date) => {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];

	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear();

	let daySuffix = '';
	switch (day) {
		case 1:
		case 21:
		case 31:
			daySuffix = 'st';
			break;
		case 2:
		case 22:
			daySuffix = 'nd';
			break;
		case 3:
		case 23:
			daySuffix = 'rd';
			break;
		default:
			daySuffix = 'th';
	}

	return `${day}${daySuffix} ${months[monthIndex]}, ${year}`;
};
