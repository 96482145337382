import { styleSheet } from '../../style-sheet';
import { Text, View } from '@react-pdf/renderer';

export const ProposalDocumentCastingTable = () => {
	return (
		<View style={styleSheet.tableCasting}>
			<View style={styleSheet.tableCastingHeaderRow}>
				<Text style={[styleSheet.tableCastingCell, styleSheet.tableCastingBorderRight]}>Dept.</Text>
				<Text style={[styleSheet.tableCastingCell, styleSheet.tableCastingBorderRight]}>Initials</Text>
				<Text style={[styleSheet.tableCastingCell]}>Date</Text>
			</View>
			<View style={styleSheet.tableCastingRow}>
				<Text style={[styleSheet.tableCastingCell, styleSheet.tableCastingBorderRight]}>Casting</Text>
				<Text style={[styleSheet.tableCastingCell, styleSheet.tableCastingBorderRight]}></Text>
				<Text style={[styleSheet.tableCastingCell]}></Text>
			</View>
			<View style={styleSheet.tableCastingRowAlt}>
				<Text style={[styleSheet.tableCastingCell, styleSheet.tableCastingBorderRight]}>Processing</Text>
				<Text style={[styleSheet.tableCastingCell, styleSheet.tableCastingBorderRight]}></Text>
				<Text style={[styleSheet.tableCastingCell]}></Text>
			</View>
			<View style={styleSheet.tableCastingRow}>
				<Text style={[styleSheet.tableCastingCell, styleSheet.tableCastingBorderRight]}>Crating</Text>
				<Text style={[styleSheet.tableCastingCell, styleSheet.tableCastingBorderRight]}></Text>
				<Text style={[styleSheet.tableCastingCell]}></Text>
			</View>
			<View style={styleSheet.tableCastingRowAlt}>
				<Text style={[styleSheet.tableCastingCell, styleSheet.tableCastingBorderRight]}>Final</Text>
				<Text style={[styleSheet.tableCastingCell, styleSheet.tableCastingBorderRight]}></Text>
				<Text style={[styleSheet.tableCastingCell]}></Text>
			</View>
		</View>
	);
};
