import { Box, Flex, Text } from '@chakra-ui/react';

import { ContextPanelContent } from './context-panel-content';
import { lang } from '@lang';
import { useDesign } from '@hooks';

export const BluePrintValidationPanel = (): JSX.Element => {
	const [controller] = useDesign();
	const kitchen = controller?.kitchen ?? null;
	const notifications = kitchen?.design.notifications.getNotications() ?? null;

	return <ContextPanelContent title={lang.contextPanels.title.blueprintValidation}>
		<Box padding="2" textAlign="left">
			{notifications?.map((notification, index) => (
				<Box key={index} padding="2" borderRadius="5" marginBottom="5" backgroundColor="#eee" boxShadow="0 0 5px 1px rgba(0,0,0,0.2)">
					<Text fontSize="14" marginBottom="5">{notification.description}</Text>
					<Flex fontSize="12" justifyContent="space-between">
						<Text>{lang.blueprintStatus.label.createdDate}:</Text>
						<Text>{notification.createDate}</Text>
					</Flex>
					<Flex fontSize="12" justifyContent="space-between">
						<Text>{lang.blueprintStatus.label.createdBy}:</Text>
						<Text>{notification.createdBy}</Text>
					</Flex>
					<Flex fontSize="12" justifyContent="space-between">
						<Text>{lang.blueprintStatus.label.notificationType}:</Text>
						<Text>{notification.type}</Text>
					</Flex>
					{ notification.acceptedDate !== '' && (
						<Flex fontSize="12" justifyContent="space-between">
							<Text>{lang.blueprintStatus.label.acceptedDate}:</Text>
							<Text>{notification.acceptedDate}</Text>
						</Flex>
					)}
					{ notification.acceptedBy !== '' && (
						<Flex fontSize="12" justifyContent="space-between">
							<Text>{lang.blueprintStatus.label.acceptedBy}:</Text>
							<Text>{notification.acceptedBy}</Text>
						</Flex>
					)}
				</Box>
			))}
		</Box>
	</ContextPanelContent>;
};
