import { Box } from '@chakra-ui/react';

import { ContextPanel } from '../../panels/context-panel';
import { dockConfig } from '../../../config';
import { EstimatePanel } from '../../panels/estimate-panel';
import { selectContextPanelCount, selectRightDock, useAppSelector } from '@state';

export const RightDock = ({ isOpen }: {isOpen: boolean}) => {
	const rightDock = useAppSelector(selectRightDock);
	const contextCount = useAppSelector(selectContextPanelCount);
	const { right: rightConfig } = dockConfig;

	const style = {
		width: isOpen ? '100%' : `${rightConfig.minWidth}px`,
		height: '100%',
		overflow: 'auto',
		backgroundColor: rightConfig.bgColor
		// border: '1px'
	};

	const panel = (dock: typeof rightDock): JSX.Element => {
		if (dock.isOpen) {
			if (dock.isContextPanelOpen && contextCount > 0) {
				return <ContextPanel />;
			} else if (rightDock.isEstimatePanelOpen) {
				return <EstimatePanel/>;
			}
		}
		return <></>;
	};


	return (
		<Box style={style} borderLeft={'1px'}>
			{panel(rightDock)}
		</Box>
	);
};
