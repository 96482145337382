// import type { PayloadAction } from '@reduxjs/toolkit';

import { createAppSlice } from '../create-app-slice';
import { stateInitializer } from './state-initializer';

// If you are not using async thunks you can use the standalone `createSlice`.
export const slice = createAppSlice({
	name: 'user',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState: stateInitializer(),
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: (create) => ({
	}),
	// You can define your selectors here. These selectors receive the slice
	// state as their first argument.
	selectors: {
		userState: (state) => state
	}
});
