import { MdOutlineHelp } from 'react-icons/md';

import { lang } from '@lang';
import { ContextPanelMode, pushContextPanel, useAppDispatch } from '@state';
import { Feature, FeatureHoookParams } from '..';

type AboutFeatureParams = FeatureHoookParams;
export const showAboutFeature = (_params: AboutFeatureParams = {}): Feature<null> => {
	const dispatch = useAppDispatch();

	return {
		name: 'about',
		label: lang.debug.label.about,
		shortcut: '',
		tooltip: lang.debug.tooltip.about,
		icon: (props = {}) => <MdOutlineHelp {...props} />,
		disabled: false,
		value: null,
		hidden: false,
		handler: () => {
			dispatch(pushContextPanel({ mode: ContextPanelMode.About }));
		}
	};
};
