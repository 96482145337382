import type { PayloadAction } from '@reduxjs/toolkit';

import { createAppSlice } from '../create-app-slice';
import { stateInitializer } from './state-initializer';
import { ActionPanelMode, ContentPanelMode, ContextPanelMode } from '../enums';

// If you are not using async thunks you can use the standalone `createSlice`.
export const slice = createAppSlice({
	name: 'ui',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState: stateInitializer(),
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: (create) => ({
		setFullScreenMode: create.reducer((state, action: PayloadAction<boolean>) => {
			state.isFullScreen = action.payload;
		}),
		setActionPanelMode: create.reducer((state, action: PayloadAction<ActionPanelMode>) => {
			state.actionPanelMode = action.payload;
			// state.docks.left.isActionPanelOpen = true;
		}),
		setContentPanelMode: create.reducer((state, action: PayloadAction<ContentPanelMode>) => {
			state.contentPanelMode = action.payload;
			state.contextPanel = [];
			state.docks.right.isContextPanelOpen = false;
			state.docks.right.isEstimatePanelOpen = false;
			state.docks.right.isOpen = false;
		}),
		pushContextPanel: create.reducer((state, action: PayloadAction<{ mode: ContextPanelMode, id?: string, temporary?: boolean, data?: Record<string, unknown> }>) => {
			const { id = null, mode, temporary = false, data = {}} = action.payload;
			state.contextPanel = state.contextPanel.filter((cp) => cp.temporary !== true);
			state.contextPanel.push({ id, mode, temporary, data });

			// always open context panel when a new entry is pushed
			state.docks.right.isContextPanelOpen = true;
			state.docks.right.isOpen = true;
		}),
		popContextPanel: create.reducer((state) => {
			state.contextPanel.pop();
			// close context if no more entries
			if (state.contextPanel.length === 0) {
				const rDock = state.docks.right;
				rDock.isContextPanelOpen = false;
				rDock.isOpen = rDock.isContextPanelOpen || rDock.isEstimatePanelOpen;
			}
		}),
		setActionPanelIsOpen: create.reducer((state, action: PayloadAction<boolean>) => {
			state.docks.left.isActionPanelOpen = action.payload ?? false;
		}),
		setContextPanelIsOpen: create.reducer((state, action: PayloadAction<boolean>) => {
			const rDock = state.docks.right;
			rDock.isContextPanelOpen = action.payload ?? false;
			if (rDock.isEstimatePanelOpen) rDock.isEstimatePanelOpen = false;
			rDock.isOpen = rDock.isContextPanelOpen || rDock.isEstimatePanelOpen;
		}),
		setEstimatePanelIsOpen: create.reducer((state, action: PayloadAction<boolean>) => {
			const rDock = state.docks.right;
			rDock.isEstimatePanelOpen = action.payload ?? false;
			if (rDock.isContextPanelOpen) rDock.isContextPanelOpen = false;
			rDock.isOpen = rDock.isContextPanelOpen || rDock.isEstimatePanelOpen;
		})
	}),
	selectors: {
		selectActionPanelIsOpen: (state) => state.docks.left.isActionPanelOpen,
		selectActionPanelMode: (state) => state.actionPanelMode,
		selectContentPanelMode: (state) => state.contentPanelMode,
		selectContextPanel: (state) => { return state.contextPanel.length > 0 ? state.contextPanel[state.contextPanel.length - 1] : null; },
		selectContextPanelCount: (state) => state.contextPanel.length,
		selectIsFullScreen: (state) => state.isFullScreen,
		selectLeftDock: (state) => state.docks.left,
		selectRightDock: (state) => state.docks.right,
		selectLeftDockIsOpen: (state) => state.docks.left.isOpen,
		selectRightDockIsOpen: (state) => state.docks.right.isOpen,
		selectContextPanelIsOpen: (state) => state.docks.right.isContextPanelOpen,
		selectEstimatePanelIsOpen: (state) => state.docks.right.isEstimatePanelOpen
	}
});
