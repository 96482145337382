import { lang } from '@lang';
import { MdDeleteOutline } from 'react-icons/md';
import { useDesign } from '@hooks';

import { Feature } from '..';
import { selectRemovedPromotionalSku, setRemovedPromotionalSku, useAppDispatch, useAppSelector } from '@state';

export const useRemovePromotionalSkuFeature = (): Feature<string> => {
	const [controller] = useDesign();
	const dispatch = useAppDispatch();

	const kitchen = controller?.kitchen ?? null;
	const removePromotionalSku = useAppSelector(selectRemovedPromotionalSku);

	return {
		name: 'remove-promotional-sku',
		label: lang.promotionalSku.label.delete,
		shortcut: '',
		tooltip: lang.promotionalSku.tooltip.deleteSku,
		icon: (props = {}) => <MdDeleteOutline {...props} />,
		disabled: false,
		value: '',
		hidden: false,
		handler: () => {
			kitchen?.promotionalSkus.children.forEach((sku, index) => {
				if (sku.sku === removePromotionalSku) {
					kitchen?.promotionalSkus.children.splice(index, 1);
				}
			});

			dispatch(setRemovedPromotionalSku(''));
		}
	};
};
