import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type PanelDimensionFeatureParams = FeatureHoookParams;
export const useShowPanelOutsideDimensionFeature = (_params: PanelDimensionFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, { options: { showPanelOutsideDimensions: boolean }}>();

	useSubscription(controller, false, [['layer-option-panel-outside-dimension', '*']]);

	const controllerSupportsFeature = (controller?.options?.showPanelOutsideDimensions ?? null) !== null;
	const showLayer = controller?.options?.showPanelOutsideDimensions ?? true;

	const tooltip = (() => {
		const showHide = showLayer ? lang.layer.tooltip.hide : lang.layer.tooltip.show;
		return `${showHide} ${lang.layer.label.panelOutsideDimensions} ${lang.layer.layer}`;
	})();

	return {
		name: 'showPanelOutsideDimensions',
		label: lang.layer.label.panelOutsideDimensions,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled: !controllerSupportsFeature,
		value: showLayer,
		hidden: false,
		handler: () => {
			if (controller) {
				controller.options.showPanelOutsideDimensions = !showLayer;
			}
		}
	};
};
