/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Box, Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';

import { ContextPanelContent } from './context-panel-content';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Appliance3d, Appliance3dController } from '@rta/rta-blueprint-3d-component';
import { Components, PanelItem, useSubscription } from '@rta/rta-blueprint-library';
import { ComponentController as BpController } from '@rta/rta-blueprint-component';
import { InlineNumberInput, InlineSlider } from './controls';
import { useEffect, useRef, useState } from 'react';

export type AppliancePropertiesPanelProps = {
	id: string | null;
};

export const AppliancePropertiesPanel = ({ id }: AppliancePropertiesPanelProps): JSX.Element => {
	// const dispatch = useDispatch();
	const [controller] = useDesign();
	const descriptionColor = useColorModeValue('gray.500', 'gray.300');
	const sectionHeaderColor = useColorModeValue('gray.600', 'gray.300');
	const appliance3dController = useRef<Appliance3dController>(new Appliance3dController()).current;
	const [panelItem, setPanelItem] = useState<PanelItem | null>(null);

	const kitchen = controller?.kitchen ?? null;
	const components: Components | null = kitchen?.context.components ?? null;
	const isCountertop = (controller as BpController)?.navigation.panelId === null ?? false;

	const skuCurrentItem = panelItem ? components?.findId(panelItem.item.id) ?? null : null;

	useSubscription(controller, false, [['navigation-panel', 'updated']]);

	useEffect(() => {
		if (controller) {
			const locator = id ? kitchen?.getReferenceMap(id) ?? null : null;
			const pi: PanelItem | null = locator?.panelItem ?? null;

			console.log('AppliancePropertiesPanel:useEffect', id, pi?.item.component);
			appliance3dController.component = pi?.item.component ?? null;

			setPanelItem(pi);
		}
	}, [controller, id]);

	if (!controller || skuCurrentItem === null || panelItem === null) return <></>;
	console.log('AppliancePropertiesPanel:useEffect', appliance3dController?.component?.sku, skuCurrentItem?.sku);

	// console.log('render:appliance-properties-panel', id, skuCurrentItem);
	return <ContextPanelContent title={lang.contextPanels.title.applianceProperties}>
		<Stack pt="10px" spacing="0">
			<Text ml="5px" fontSize="md" fontWeight="medium">{skuCurrentItem.sku}</Text>
			<Box marginBottom="10px" p="5px" width="100%" bgColor="gray.100" borderColor="gray.200" border="1px" rounded="10px" overflow="hidden">
				{ skuCurrentItem.svg?.item
					? <svg
						height="150px"
						viewBox={`0 0 ${skuCurrentItem.size.width} ${skuCurrentItem.size.height}`}
					>{ skuCurrentItem.svg.item({ x: 0, y: 0 })}</svg>
					: <Box w={'100%'} h={'150px'} overflow="hidden" bgColor="gray.100">
						{appliance3dController && <Appliance3d controller={appliance3dController} backgroundColor="#edf2f7" />}
					</Box>
				}
				{/* <Center>{
					skuCurrentItem.svg?.item
						? <svg
							height="150px"
							viewBox={`0 0 ${skuCurrentItem.size.width} ${skuCurrentItem.size.height}`}
						>{ skuCurrentItem.svg.item({ x: 0, y: 0 })}</svg>
						: <Image
							objectFit="contain"
							objectPosition="center"
							src={`${process.env.REACT_APP_ASSETS_BASE_URL}/products/${skuCurrentItem.sku}.png`}
							width="250px" height="150px"/>
				}</Center> */}
			</Box>

			<Flex pl="5px" pb="10px" justifyContent="space-between">
				<Text color={descriptionColor} fontSize="sm">{skuCurrentItem.description}</Text>
			</Flex>

			<Text pl="5px" color={sectionHeaderColor} fontSize="medium"
				fontWeight="medium"
			>Dimensional Details</Text>
			<Box marginBottom={3} padding={2} textAlign="left"
				borderLeft="1px solid grey" borderTop="1px" roundedTopLeft="7px"
			>
				<Stack spacing="1" width="100%" height="65%">
					<InlineNumberInput
						category="cutout"
						label="Cutout Width"
						min={0.5}
						value={skuCurrentItem.cutout.width}
						enabled={false}
					/>
					<InlineNumberInput
						category="cutout"
						label="Cutout Height"
						min={0.5}
						value={skuCurrentItem.cutout.height}
						enabled={false}
					/>
					<InlineNumberInput
						category="cutout"
						label="Cutout Depth"
						min={0}
						value={skuCurrentItem.cutout.depth}
						enabled={false}
					/>
					<InlineNumberInput
						category="appliance" label="Overall Width" min={0.5}
						value={skuCurrentItem.size.width}
						enabled={false}
					/>
					<InlineNumberInput
						category="appliance" label="Overall Height" min={0.5}
						value={skuCurrentItem.size.height}
						enabled={false}
					/>
					<InlineNumberInput
						category="appliance" label="Overall Depth" min={0}
						value={skuCurrentItem.size.depth}
						enabled={false}
					/>
					<InlineNumberInput
						category="appliance" label="Distance Below Counter" min={0}
						placeholder="NA"
						value={skuCurrentItem.distanceBelowCountertop}
						enabled={false}
					/>
					<InlineNumberInput
						category="appliance" label="Distance Front Of Face" min={0}
						value={ skuCurrentItem.distanceFrontOfFace }
						enabled={false}
					/>
				</Stack>
			</Box>

			{ controller.instanceOf === 'bp-controller' && (skuCurrentItem.placement === 'front only') &&
			<Box marginBottom={3} padding={2} textAlign="left"
				borderLeft="1px solid grey" borderTop="1px" roundedTopLeft="7px"
			>
				<Text paddingBottom={3} fontSize="medium">Other Settings</Text>
				<Stack spacing="3" width="100%" height="65%">
					<InlineNumberInput
						category="appliance" label="Side Padding" min={0}
						value={ panelItem.sidePadding ?? 0 }
						enabled={true}
						onChange={(e) => {
							panelItem.sidePadding = e;
							// intDispatch({
							// 	type: BlueprintActionTypes.UpdatePanelItemSidePadding,
							// 	payload: {
							// 		pubState,
							// 		key: locator.ref.key,
							// 		sidePadding: panelItem.sidePadding
							// 	}
							// });
						}}
					/>
				</Stack>
			</Box>
			}

			{ controller.instanceOf === 'bp-controller' && (skuCurrentItem.dimensions.inside?.horizontal.render || skuCurrentItem.dimensions.inside?.vertical.render) && <>
				<Text pl="5px" color={useColorModeValue('gray.600', 'gray.300')} fontSize="medium"
					fontWeight="medium"
				>Dimension Positioning</Text>
				<Box marginBottom={3} padding={2} textAlign="left"
					borderLeft="1px solid grey" borderTop="1px" roundedTopLeft="7px"
				>
					<Stack spacing="3" width="100%" height="65%">
						{ skuCurrentItem.dimensions.inside.horizontal.render && <>
							<InlineSlider
								category="dimension" label="H. Line"
								min={0} max={1} step={0.01}
								value={ isCountertop ? panelItem.dimensionOffsets.zLine : panelItem.dimensionOffsets.hLine }
								onChange={(e) => {
									panelItem.dimensionOffsets = isCountertop ? { zLine: e } : { hLine: e };
								}}
							/>
							<InlineSlider
								category="dimension" label="H. Label"
								min={0} max={1} step={0.01}
								value={ isCountertop ? panelItem.dimensionOffsets.zLabel : panelItem.dimensionOffsets.hLabel }
								onChange={(e) => {
									panelItem.dimensionOffsets = isCountertop ? { zLabel: e } : { hLabel: e };
								}}
							/>
						</>}
						{ skuCurrentItem.dimensions.inside.horizontal.render && <>
							<InlineSlider
								category="dimension" label="V. Line"
								min={0} max={1} step={0.01}
								value={ panelItem.dimensionOffsets.vLine }
								onChange={(e) => {
									panelItem.dimensionOffsets = { vLine: e };
								}}
							/>
							<InlineSlider
								category="dimension" label="V. Label"
								min={0} max={1} step={0.01}
								value={ panelItem.dimensionOffsets.vLabel }
								onChange={(e) => {
									panelItem.dimensionOffsets = { vLabel: e };
								}}
							/>
						</>}
					</Stack>
				</Box>
			</>}
		</Stack>
	</ContextPanelContent>;
};
