import {
	Grid,
	HStack,
	Text,
	VStack
} from '@chakra-ui/react';

import { lang } from '@lang';
import { RtaFeatureButton } from '@components';
import { useProposalDownloadFeature } from '@features/document';
import { useTax } from '@hooks';
import { useFinalizeSaleFeature, useSaveQuoteFeature } from '@features/crm';

export const CrmActionPanel = (): JSX.Element => {
	const [tax] = useTax();

	const saveQuoteFeature = useSaveQuoteFeature();
	const usefinalizeSaleFeature = useFinalizeSaleFeature();
	const proposal = useProposalDownloadFeature({ tax });

	return (
		<Grid width="100%" height="100%" templateRows="auto 1fr">
			<HStack h="35px" w="full" background="blue.400" px={6} py={2} justifyContent={'center'} mb={2}>
				<Text textAlign={'center'} color={'white'} fontSize={18}>{lang.actionPanels.title.crm}</Text>
			</HStack>
			<VStack width="165px" spacing={2} mx="5px" border={'0px'} >
				<RtaFeatureButton width="165px" feature={proposal} useIcon/>
				<RtaFeatureButton feature={saveQuoteFeature} useIcon/>
				<RtaFeatureButton feature={usefinalizeSaleFeature} useIcon/>
			</VStack>
		</Grid>
	);
};
