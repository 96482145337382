import { Params } from 'react-router-dom';
import { PayloadAction, ReducerCreators } from '@reduxjs/toolkit';

import { decrypt } from '@helpers';
import { State } from './state.type';
import { AppMode, NavOptions } from '../types';

export const setNavigationReducer = (create: ReducerCreators<State>) => {
	return create.reducer((state: State, action: PayloadAction<{mode: AppMode, params: Readonly<Params<string>>}>) => {
		let email = null;
		let designId = null;
		let designVersion = null;
		const options: NavOptions = {};
		const navAppMode: string = action.payload.mode ?? 'r';

		if (action.payload.params.designHash) {
			// console.log('state:setNavigationReducer 1', action.payload.params.designHash);
			// console.log('state:setNavigationReducer 2', decrypt(action.payload.params.designHash ?? null));
			const pathParts = decrypt(action.payload.params.designHash ?? null).split('?');
			const pathParams = pathParts.length === 2 ? pathParts[1].split('&') : [];
			const pathRoute = pathParts[0].split('/');
			pathParams.forEach((param) => {
				const pathParam = param.split('=');
				switch (pathParam[0]) {
					case 'si':
						if (pathParam.length === 2) options.submissionId = pathParam[1] ?? null;
						break;
					default:
						break;
				}
			});
			if (pathRoute.length === 3) ([email, designId, designVersion] = pathRoute);
		} else {
			email = action.payload.params.email ?? null;
			designId = action.payload.params.designId ?? null;
			designVersion = action.payload.params.version ?? null;
		}

		state.nav = { appMode: navAppMode, email, designId, designVersion, options };
		console.log('state:setNavigationReducer', action.payload.params, state.nav);
	});
};

