import { RiFolderAddLine } from 'react-icons/ri';

import { featureHook } from '../feature-hook';
import { lang } from '@lang';
import { useNavigate } from 'react-router-dom';
import { appMode, selectConfirmCustomerClose, selectGetNewCustomerData, selectNavigation, setConfirmCustomerClose, setGetNewCustomerData, useAppDispatch, useAppSelector } from '@state';
import { Feature, FeatureHoookParams } from '..';

type UseNewCustomerFeatureParams = FeatureHoookParams;
type ReturnType = string;

export const useNewCustomerFeature = (_params: UseNewCustomerFeatureParams = {}): Feature<ReturnType> => {
	const dispatch = useAppDispatch();
	// const email = selector(selectCustomerEmail);
	const confirm = useAppSelector(selectConfirmCustomerClose);
	const modal = useAppSelector(selectGetNewCustomerData);
	const appNav = useAppSelector(selectNavigation);

	const navigate = useNavigate();

	const [disabled, tooltip] = (() => {
		return [false, lang.customer.tooltip.clickToAddCustomer];
	})();

	return featureHook<ReturnType>({
		name: 'newCustomer',
		label: lang.customer.label.newCustomer,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <RiFolderAddLine {...props} />,
		disabled,
		// this will be set by the modal
		value: '',
		hidden: appNav.appMode === appMode.view,
		confirm: [
			confirm,
			() => {
				if (appNav && appNav.email !== '' && appNav.email !== null) {
					// a customer is loaded, confirm if user wants to close it
					dispatch(setConfirmCustomerClose({ isOpen: true }));
				} else {
					// no customer loaded, no need to confirm, open new customer modal
					dispatch(setConfirmCustomerClose({ isOpen: false, confirmed: true }));
				}
			}
		],
		modal: [
			modal,
			() => {
				// open new customer modal to get data
				if (confirm.confirmed) dispatch(setGetNewCustomerData({ isOpen: true }));
			}
		],
		reset: () => {
			dispatch(setConfirmCustomerClose({ isOpen: false, confirmed: null }));
			dispatch(setGetNewCustomerData({ isOpen: false }));
		},
		handler: () => {
			if (modal?.data?.email && appNav.email !== modal.data.email) navigate(`/r/${modal.data.email}`);
		}
	});
};

