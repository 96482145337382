import { styleSheet } from '../../style-sheet';
import { Text, View } from '@react-pdf/renderer';

export type ProposalDocumentAdditionalNotesProps = {
	notes: Array<string>
}

export const ProposalDocumentAdditionalNotes = ({ notes }: ProposalDocumentAdditionalNotesProps) => {
	return (
		<View style={styleSheet.noteContainer}>
			<Text style={[styleSheet.fontColorRed, styleSheet.fontWeightBold, styleSheet.textUpperCase, styleSheet.pullCenter, styleSheet.marginBottom]}>Additional Notes</Text>
			<View>
				{notes.map((note, index) => {
					return (
						<Text key={index} style={styleSheet.fontColorRed}>{note}</Text>
					);
				})}
			</View>
		</View>
	);
};
