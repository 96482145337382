import axios from 'axios';

export type GetCustomerBlueprintParams = {
	// data
	email: string,
	submissionId: string,
	// token
	getAccessTokenSilently: () => Promise<string>,
	// callbacks
	setState?: (data: any) => void,
	setError?: (error: string) => void
};

export const getCustomerBlueprint = async (params: GetCustomerBlueprintParams): Promise<[any, string | null]> => {
	// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
	const { email, submissionId, getAccessTokenSilently, setState, setError } = params;

	try {
		const token = await getAccessTokenSilently();
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/customers/${email}/blueprints/${submissionId}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		if (response.status !== 200) {
			if (setError) setError(response.data);
			return [null, response.data];
		}

		if (setState) setState([response.data]);
		return [[response.data], null];
	} catch (error) {
		if (setError) setError(error as string);
		return [null, error as string];
	}

	return [null, null];
};
