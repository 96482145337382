import { Blueprint, connectIslands, EmptyIslandDefaults, newEmptyIsland } from '@rta/rta-blueprint-library';

import { NewSubmissionParams } from '../types';

export const lShapeBarRightShape = (params: NewSubmissionParams): Blueprint => {
	const { customer, user } = params;

	const bp = new Blueprint({ userEmail: user, dataIn: { email: customer }});
	const kitchen = bp.kitchens.getActiveVersion();
	kitchen.description = 'L Shaped Bar Right Kitchen';

	const grill = newEmptyIsland(kitchen, EmptyIslandDefaults.grill, 0, true);
	const bar = newEmptyIsland(kitchen, EmptyIslandDefaults.bar, 90);

	connectIslands({
		sourcePanel: bar.frontPanel,
		targetPanel: grill.frontPanel.getRightPanel()
	});

	return bp;
};
