import { DisplayMode } from '@rta/rta-blueprint-component';
import { GiOverhead } from 'react-icons/gi';

import { ControllerDisplayModeSupport } from './types';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { useSubscription } from '@rta/rta-blueprint-library';
import { Feature, FeatureHoookParams } from '..';

type displayPanelModeFeature = FeatureHoookParams;
export const useDisplayOverheadModeFeature = (_params: displayPanelModeFeature = {}): Feature<boolean> => {
	const design = useDesign();
	const controller = design[0] as (typeof design[0]) & ControllerDisplayModeSupport;

	const controllerSupportsFeature = (controller?.navigation?.displayMode ?? null) !== null;
	const inOverheadMode = controller?.navigation?.displayMode === DisplayMode.Overhead;
	const inOverheadManufacturingView = controller?.navigation?.displayMode === DisplayMode.OverheadManufacturing;

	useSubscription(controller, false, [['navigation-display-mode', '*']]);

	const [disabled, tooltip] = (() => {
		if (!controllerSupportsFeature) return [true, lang.navigation.tooltip.disabledNotSupportCurrentView];
		return [false, lang.navigation.tooltip.overheadMode];
	})();

	return {
		name: 'overhead-mode',
		label: lang.navigation.label.overheadMode,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <GiOverhead color={inOverheadMode || inOverheadManufacturingView ? 'green' : 'unset'} {...props} />,
		disabled,
		value: inOverheadMode,
		hidden: false,
		handler: () => {
			if (controllerSupportsFeature) {
				if (inOverheadMode) {
					// revert to saved displayMode
					controller.navigation.displayMode = DisplayMode.Panel;
				} else {
					controller.navigation.displayMode = DisplayMode.Overhead;
				}
			}
		}
	};
};
