/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { ComponentController as BpController } from '@rta/rta-blueprint-component';
import { BlueprintInstanceType, Connection, CornerSize, HorizontalAlignment, Island, Panel, Size, useSubscription } from '@rta/rta-blueprint-library';
import { Box, FormLabel, Select, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { ContextPanelContent } from './context-panel-content';
import { InlineNumberInput } from './controls';
import { lang } from '@lang';
import { useDesign } from '@hooks';

export type ConnectionPropertiesPanelProps = {
	id: string | null;
};

export const ConnectionPropertiesPanel = ({ id }: ConnectionPropertiesPanelProps): JSX.Element => {
	const [controller] = useDesign<BpController>();
	const [island, setIsland] = useState<Island | null>(null);
	const [standardSize, setStandardSize] = useState<Size>();
	const [cornerSize, setCornerSize] = useState<CornerSize>();

	useEffect(() => {
		if (controller) {
			let refMap = controller.kitchen?.getReferenceMap(id ?? '');
			if (refMap.type === BlueprintInstanceType.Countertop) refMap = controller.kitchen?.getReferenceMap(refMap.countertop.islandIds[0]);
			setIsland(refMap.island);
			if (refMap.island.isCornerIsland) {
				setCornerSize(refMap.island.size as CornerSize);
			} else {
				setStandardSize(refMap.island.size as Size);
			}
		}
	}, [controller, id]);

	if (!controller || !island || !(standardSize || cornerSize)) return <></>;

	const [selectedConnection] = island.connections.getConnectionsBySourcePanelId(id ?? '');

	if (!selectedConnection) return <></>;

	let sourceConnection: Connection | null = null;
	let targetConnection: Connection | null = null;

	if (selectedConnection.isSource) {
		sourceConnection = selectedConnection as Connection;
		({ targetConnection } = selectedConnection);
	} else {
		sourceConnection = selectedConnection.targetConnection;
		targetConnection = selectedConnection as Connection;
	}

	const { sourcePanel } = sourceConnection as Connection;
	const { sourcePanel: targetPanel } = targetConnection as Connection;

	let sourceLeftLabel = 'Right';
	let sourceRightLabel = 'Left';
	if (sourcePanel?.isFrontPanel) {
		sourceLeftLabel = 'Left';
		sourceRightLabel = 'Right';
	} else if (sourcePanel?.isLeftPanel) {
		sourceLeftLabel = 'Back';
		sourceRightLabel = 'Front';
	} else if (sourcePanel?.isRightPanel) {
		sourceLeftLabel = 'Front';
		sourceRightLabel = 'Back';
	}

	let targetLeftLabel = 'Right';
	let targetRightLabel = 'Left';
	if (targetPanel?.isFrontPanel) {
		targetLeftLabel = 'Left';
		targetRightLabel = 'Right';
	} else if (targetPanel?.isLeftPanel) {
		targetLeftLabel = 'Back';
		targetRightLabel = 'Front';
	} else if (targetPanel?.isRightPanel) {
		targetLeftLabel = 'Front';
		targetRightLabel = 'Back';
	}


	return <ContextPanelContent title={lang.contextPanels.title.connectionProperties}>
		<Box w="100%">
			<Stack spacing="12px" marginTop="20px">
				<InlineNumberInput
					label="Padding" min={1} max={100}
					value={ sourceConnection?.padding ?? 0 }
					onChange={(valueString) => {
						console.log('valute', valueString);
						if (sourceConnection) {
							sourceConnection.padding = parseFloat(valueString);
						}
					}}
				/>

				<InlineNumberInput
					label="Offset" min={-99} max={99}
					value={ sourceConnection?.offset ?? 0 }
					onChange={(valueString) => {
						if (sourceConnection) {
							sourceConnection.offset = parseFloat(valueString);
						}
					}}
				/>

				<Box>
					<FormLabel htmlFor="dimension-font-factor" fontSize="small">Source Horizontal Align:</FormLabel>
					<Select id="dimension-font-factor" fontSize="small"
						value={sourceConnection?.horizontalAlign}
						onChange={(event) => {
							if (sourceConnection) {
								sourceConnection.horizontalAlign = event.target.value as HorizontalAlignment;
							}
						}}>
						<option value={HorizontalAlignment.left}>{sourceLeftLabel} </option>
						<option value={HorizontalAlignment.right}>{sourceRightLabel}</option>
					</Select>
				</Box>

				<Box>
					<FormLabel htmlFor="dimension-font-factor" fontSize="small">Target Alignment:</FormLabel>
					<Select id="dimension-font-factor" fontSize="small"
						value={targetConnection?.horizontalAlign}
						onChange={(event) => {
							if (targetConnection) {
								targetConnection.horizontalAlign = event.target.value as HorizontalAlignment;
							}
						}}>
						<option value={HorizontalAlignment.left}>{targetLeftLabel}</option>
						<option value={HorizontalAlignment.right}>{targetRightLabel}</option>
					</Select>
				</Box>

			</Stack>
		</Box>
	</ContextPanelContent>;
};
