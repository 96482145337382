type DropDownItem = { enabled: boolean; displayName: string; };

export const displayDropdowns = (data: Record<string, DropDownItem>, includeDisabled = false) => {
	// console.log('displayDropdowns', data);
	return Object.keys(data).map((key, index) => {
		if (includeDisabled || data[key].enabled) {
			return <option value={key} key={index}>{data[key].displayName}</option>;
		}
		return null;
	})
		.filter((e) => e);
};
