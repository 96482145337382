import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react';

import { lang } from '@lang';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext, DesignActionTypes } from '@context';
import { selectDeleteCustomerDraftsModal, selectNavigation, setContextPanelIsOpen, setDeleteCustomerDraftsModal, useAppDispatch, useAppSelector } from '@state';

export const DeleteCustomerDraftsModal = (): JSX.Element => {
	const toast = useToast();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const state = useAppSelector(selectDeleteCustomerDraftsModal);
	const appNav = useAppSelector(selectNavigation);

	const { appDispatch, appState: { customerDesigns: designs }} = useContext(AppContext);

	const onClose = () => {
		dispatch(setDeleteCustomerDraftsModal(false));
	};

	const handleDelete = () => {
		designs.designs.forEach((design) => {
			localStorage.removeItem(`autosave-${design.blueprint.id}`);
			appDispatch({ type: DesignActionTypes.setIsDirty, payload: { id: design.blueprint.id, isDirty: false }});
		});

		toast({
			title: lang.customer.message.customerDraftsDeleted,
			status: 'success',
			isClosable: true
		});

		dispatch(setDeleteCustomerDraftsModal(false));
		dispatch(setContextPanelIsOpen(false));

		navigate(`/r/${appNav.email}/${appNav.designId}`);
	};

	return (
		<Modal isOpen={state.isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<>
					<ModalHeader>Warning</ModalHeader>
					<ModalBody>
						<Text>{lang.customer.message.deleteCustomerDraftsWarning}</Text>
					</ModalBody>

					<ModalFooter>
						<Button variant="ghost" mr={3} onClick={onClose}>Cancel</Button>
						<Button colorScheme="green" onClick={handleDelete}>Continue</Button>
					</ModalFooter>
				</>
			</ModalContent>
		</Modal>
	);
};
