// import { customerFeatureStateSlice } from '@features';
import { slice } from './slice';

// Action creators are generated for each case reducer function.
export const {
	setViewMode,
	setAutoZoom,
	setCustomerFeatureFindState,
	setConfirmCustomerClose,
	setSaveAsDraftClose,
	setGetNewCustomerData,
	setPromotionalSkuDropdown,
	setSelectedPromotionalSku,
	setRemovedPromotionalSku,
	setPricePromotionalSku,
	setDeleteCustomerDraftsModal,
	setDeleteAllDraftsModal,
	setPushSubmissionDataModal
} =
  slice.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
	selectFeatureState,
	selectFeatureCustomerState,
	selectFeatureLayerState,
	selectFeatureValidationState,
	selectZoomFeatureState,
	selectCustomerFeatureFindState,
	selectConfirmCustomerClose,
	selectSaveAsDraftClose,
	selectGetNewCustomerData,
	selectViewMode,
	selectAutoZoom,
	selectPromotionalSkuDropdown,
	selectSelectedPromotionalSku,
	selectRemovedPromotionalSku,
	selectPriceChangePromotionalSku,
	selectDeleteCustomerDraftsModal,
	selectDeleteAllDraftsModal,
	selectSetPushSubmissionDataModal
} = slice.selectors;
