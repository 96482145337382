type ReleaseInfo = {
	release: string;
	build: string;
	commit: string;
}

const releaseInfo: ReleaseInfo = {
	release: '__RELEASE__',
	build: '20241112.1',
	commit: 'c18a3a6'
};

export { releaseInfo };

