export const promotionalSku = {
	label: {
		promotionalSku: 'Add Promotional SKU',
		hidePromotionalSku: 'Hide Promotional SKU',
		add: 'Add',
		delete: 'Delete'
	},
	tooltip: {
		addPromotionalSku: 'Click to add promotional SKU',
		hidePromotionalSku: 'Click to hide promotional SKU',
		addSku: 'Add SKU',
		selectSku: 'Select Promotional Sku',
		deleteSku: 'Delete SKU'
	},
	message: {
	}
};
