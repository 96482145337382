import { AppState } from '@auth0/auth0-react';
import { NavigateFunction } from 'react-router';

export const onRedirectCallbackFactory = (navigate: NavigateFunction) => {
	return (appState?: AppState) => {
		console.log('onRedirectCallback:', appState, window.location.pathname);
		navigate(appState && appState.returnTo
			? appState.returnTo === '/' ? '/r' : appState.returnTo
			: window.location.pathname === '' ? '/r' : window.location.pathname);
	};
};

