import { ReactNode } from 'react';
import { useColorModeValue as mode, Text, TextProps } from '@chakra-ui/react';

export type PriceProps = {
	children?: ReactNode;
	isOnSale?: boolean;
	textProps?: TextProps;
};

export const Price = (props: PriceProps) => {
	const { isOnSale, children, textProps } = props;
	const defaultColor = mode('gray.700', 'gray.400');
	const onSaleColor = mode('gray.400', 'gray.700');
	const color = isOnSale ? onSaleColor : defaultColor;

	return (
		<Text
			as="span"
			fontWeight="medium"
			color={color}
			textDecoration={isOnSale ? 'line-through' : 'none'}
			{...textProps}
		>
			{children}
		</Text>
	);
};
