
import { Box, Divider, HStack } from '@chakra-ui/react';

import { RtaFeatureIconButton } from '@components';
import { useGenerateDxfFeature, useShareLinkFeature, useSnapshotFeature } from '@features/social';

export const ShareControls = () => {
	const shareLink = useShareLinkFeature();
	const snapshot = useSnapshotFeature();
	const generateDxf = useGenerateDxfFeature();

	return (
		<Box w={'100%'} h={'100%'} m={'1px'} border={'0px'}>
			<HStack h={'100%'} gap={'5px'}>
				<RtaFeatureIconButton feature={generateDxf} />
				<Divider orientation="vertical" h={'50%'} />
				<RtaFeatureIconButton feature={snapshot} />
				<Divider orientation="vertical" h={'50%'} />
				<RtaFeatureIconButton feature={shareLink} />
			</HStack>
		</Box>
	);
};
