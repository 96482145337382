import { IconProps } from '@chakra-ui/react';

import { HubSpotIcon } from '@components';
import { lang } from '@lang';
import { ActionPanelMode, appMode, projectState, selectActionPanelIsOpen, selectActionPanelMode, selectNavigation, setActionPanelIsOpen, setActionPanelMode, useAppDispatch, useAppSelector } from '@state';
import { Feature, FeatureHoookParams } from '..';

type UseCrmFeatureParams = FeatureHoookParams;
export const useCrmFeature = (_params: UseCrmFeatureParams = {}): Feature<boolean> => {
	const dispatch = useAppDispatch();
	const project = useAppSelector(projectState);
	const actionPanelIsOpen = useAppSelector(selectActionPanelIsOpen);
	const actionPanelMode = useAppSelector(selectActionPanelMode);
	const appNav = useAppSelector(selectNavigation);

	const [disabled, tooltip] = (() => {
		if (project.length > 0) return [false, lang.crm.tooltip.openCrmFeatures];
		return [true, lang.crm.tooltip.disabledCrm];
	})();

	return {
		name: 'crm',
		label: lang.crm.label.crm,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <HubSpotIcon {...props as IconProps} />,
		disabled,
		value: false,
		hidden: appNav.appMode === appMode.view,
		handler: () => {
			dispatch(setActionPanelIsOpen(actionPanelMode === ActionPanelMode.Crm ? !actionPanelIsOpen : true));
			dispatch(setActionPanelMode(ActionPanelMode.Crm));
		}
	};
};
