/* eslint-disable max-lines-per-function */
import { RtaIconButton } from '../../../components/ui';
import { Box, Flex, Input, InputGroup, InputLeftElement, Spacer, Stack, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { CurrencyOptions } from 'src/helpers';
import { PriceTag } from './price-tag';
import { useChangePricePromotionalSkuFeature, useRemovePromotionalSkuFeature } from '@features';
import { setPricePromotionalSku, setRemovedPromotionalSku, useAppDispatch } from '@state';

type EstimateItemProps = {
	sku: string
	description: string
	quantity: number
	price: number
	currency: CurrencyOptions
	isPromotional?: boolean
	type?: string
	totalAdjustment?: number
//   imageUrl: string
}

export const EstimateItem = (props: EstimateItemProps) => {
	const {
		sku,
		description,
		quantity,
		// imageUrl,
		currency = { locale: 'en-US', currency: 'USD' },
		price,
		isPromotional,
		type,
		totalAdjustment
		// onChangeQuantity,
		// onClickDelete,
	} = props;
	const dispatch = useAppDispatch();

	const [deleteSku, setDeleteSku]	= useState<string>('');
	const [priceSku, setPriceSku]	= useState<string>('');
	const removePromotionalSku = useRemovePromotionalSkuFeature();
	const changePricePromotionalSku = useChangePricePromotionalSkuFeature();
	const [showInput, setShowInput] = useState<boolean>(false);
	const [inputPrice, setInputPrice] = useState<number | string>(price);

	useEffect(() => {
		changePricePromotionalSku.handler();
		setPriceSku('');
	}, [priceSku]);

	useEffect(() => {
		removePromotionalSku.handler();
	}, [deleteSku]);

	return (
		<Stack overflow="hidden" roundedLeft={'6px'} direction="row" bg="gray.10" spacing="0" mb="5px" pr="5px" border="1px" borderColor={'gray.400'}>
			<Box pos="relative" bg="gray.200" pl="5px" pr="5px" pt="0">
				{/* <Image
					rounded="md"
					minWidth="10"
					maxWidth="10"
					height={{ base: '10', md: '10' }}
					fit="cover"
					// src={imageUrl}
					// alt={name}
					draggable="false"
					loading="lazy"
				/> */}
				<Box alignContent={'center'} textAlign="center" width="1em" height="100%" margin="auto">
					<Text fontSize={'lg'}>{quantity}</Text>
				</Box>
			</Box>
			<VStack spacing="0.1" width="full" align={'left'}>
				<Text ml="5px" borderBottom="1px" borderColor="gray.300" fontSize="md" fontWeight="medium">{sku}</Text>
				<Flex pl="5px" justifyContent="space-between">
					<Text color={useColorModeValue('gray.500', 'gray.300')} fontSize="sm">{description}</Text>
				</Flex>
				<Flex justifyContent="space-between">
					<Spacer/>
					{ !showInput &&
							<PriceTag priceProps={{ fontSize: 'sm' }} price={price} currency={currency} totalAdjustment={totalAdjustment} type={type} onClick={() => {
								if (isPromotional && type === 'FIXED') setShowInput(true);
							}}/>
					}

					{showInput &&
					<InputGroup pl="5px" pb="5px">
						<InputLeftElement
							pointerEvents="none"
							color="gray.300"
							mt="0"
						>$</InputLeftElement>
						<Input height="fit-content" fontSize="md" type="number" value={inputPrice === 0 ? '' : inputPrice} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setInputPrice(isNaN(Number(event.target.value)) ? '' : Number(event.target.value));
						}}

						onBlur={() => {
							dispatch(setPricePromotionalSku([sku, inputPrice]));
							setPriceSku(sku);
							setShowInput(false);
						}}
						style={{
							// width: '50px',
							// height: '30px',
							// padding: '5px',
							// margin: '10px 0px',
							// border: '2px solid',
							borderRadius: '5px'
						}}/>
					</InputGroup>
					}

					{ isPromotional &&
						<RtaIconButton enabled={ !showInput } tabIndex={-1} height={'1.25em'} color={'red.500'} icon={removePromotionalSku.icon()} onClick={() => {
							dispatch(setRemovedPromotionalSku(sku));
							setDeleteSku(sku);
						} }/>
					}
				</Flex>
			</VStack>
		</Stack>
	);
};
