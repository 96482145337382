import { lang } from '@lang';
import { MdCoPresent } from 'react-icons/md';
import { ViewMode } from './view-mode.enum';
import { Feature, FeatureHoookParams } from '..';
import { selectViewMode, setViewMode, useAppDispatch, useAppSelector } from '@state';

type ViewModeFeatureParams = FeatureHoookParams;
export const useCustomerPresentationFeature = (_params: ViewModeFeatureParams = {}): Feature<ViewMode> => {
	const dispatch = useAppDispatch();
	const viewMode = useAppSelector(selectViewMode);

	const [enabled, tooltip] = (() => {
		return [false, 'feature not available'];
		// if (viewMode === ViewMode.fullScreen) return [false, lang.view.tooltip.naFullscreen];
		// return [true, lang.view.label.customerPresentation];
	})();

	return {
		name: 'customer-presentation',
		label: lang.view.label.customerPresentation,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <MdCoPresent {...props} />,
		disabled: !enabled,
		value: viewMode,
		hidden: false,
		handler: () => {
			// console.log('viewMode', viewMode);
			dispatch(setViewMode(ViewMode.customerPresentation));
		}
	};
};
