import { Feature } from '..';
import { lang } from '@lang';
import { selectNavigation, setPushSubmissionDataModal, useAppDispatch, useAppSelector } from '@state';

export const usePushSubmissionFeature = (): Feature<boolean> => {
	const dispatch = useAppDispatch();
	const appNav = useAppSelector(selectNavigation);

	const [enabled, tooltip] = (() => {
		if (appNav.designId !== null) return [true, lang.customer.tooltip.pushSubmission];
		return [false, lang.customer.tooltip.pushSubmissionDisabled];
	})();

	return {
		name: 'pushSubmissionFeature',
		label: lang.customer.label.pushSubmission,
		shortcut: '',
		tooltip,
		icon: () => <></>,
		disabled: !enabled,
		value: enabled,
		hidden: process.env.REACT_APP_AUTH0_AUDIENCE !== 'blueprint-api-test' ?? false,
		handler: () => {
			dispatch(setPushSubmissionDataModal(true));
		}
	};
};
