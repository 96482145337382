export const islandSubmission = {
	label: {
		addNewSubmission: 'New Submission Panel',
		addSubmissionButton: 'Add Submission',
		submissionName: 'Name Your Submission'
	},
	tooltip: {
		addDesign: 'Add Design',
		noCustomer: 'No Customer Loaded'
	},
	message: {
	}
};
