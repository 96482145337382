export const document = {
	label: {
		downloadEstimate: 'Download Estimate',
		downloadProposal: 'Download Proposal'
	},
	tooltip: {
		generateEstimate: 'Click to generate an estimate for the current blueprint',
		generateProposal: 'Click to generate a proposal for the current blueprint',
		missingCustomer: 'Required a customer to be loaded',
		missingHubspotData: 'Customer data missing from Hubspot'
	},
	message: {
		downloadEstimate: 'Estimate Download Started!',
		downloadProposal: 'Proposal Download Started!'
	}
};
