import { State } from './state.type';
import { ActionPanelMode, ContentPanelMode } from '../enums';

export const stateInitializer = (): State => {
	return {
		isFullScreen: false,
		actionPanelMode: ActionPanelMode.None,
		contentPanelMode: ContentPanelMode.Designer,
		contextPanel: [],
		docks: {
			top: {
				isOpen: true
			},
			bottom: {
				isOpen: true
			},
			left: {
				isOpen: true,
				isActionPanelOpen: false
			},
			right: {
				isOpen: false,
				isEstimatePanelOpen: false,
				isContextPanelOpen: false
			}
		}
	};
};
