import { Box } from '@chakra-ui/react';

import { AppRouter } from '.';

export const AppContainer = () => {
	// console.log('render:AppContainer');
	return (
		<Box width="100%" height="100%" minH="500px">
			<AppRouter />
		</Box>
	);
};
