/* eslint-disable max-statements */
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Text, useColorMode, useTheme } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';

import { AppContext } from '@context';
import { Logo } from '../../groups/logo-group/logo';
import { bootstrapApp, ContentPanelMode, ContextPanelMode, pushContextPanel, selectIsBootstrapped, selectNavigation, selectWhatsLoading, setContentPanelMode, useAppDispatch, useAppSelector } from '@state';

export const AppLoadingPanel = () => {
	const auth = useAuth0();
	const { isAuthenticated, loginWithRedirect, isLoading: isAuthLoading, error } = auth;

	const { appState: { customerDesigns: { isLoading }}} = useContext(AppContext);

	const dispatch = useAppDispatch();
	const isBootstrapped = useAppSelector(selectIsBootstrapped);
	const whatsLoading = useAppSelector(selectWhatsLoading);
	const navigation = useAppSelector(selectNavigation);

	const theme = useTheme();
	const { colorMode } = useColorMode();

	// console.log('render:AppLoadingPanel (state:)', isBootstrapped, auth, navigation);

	// Once auth is ready and loaded, and we have a valid route, bootstrap the app
	useEffect(() => {
		// console.log('render:AppLoadingPanel:useEffect', isAuthLoading, isAuthenticated, navigation.email);
		if (!isAuthLoading && isAuthenticated) {
			if (navigation.email) {
				// bootstrap the app with customer data
				dispatch(bootstrapApp(navigation.email));
				if (!navigation.designId) {
					// default to find customer and action panel open to home if we have no design
					dispatch(setContentPanelMode(ContentPanelMode.NewSubmission));
				}
			} else {
				// default to find customer and action panel open to home if we have no email
				dispatch(setContentPanelMode(ContentPanelMode.findCustomer));
				dispatch(pushContextPanel({ mode: ContextPanelMode.FindCustomerProperties, temporary: true }));
			}
		}
	}, [isAuthLoading, isAuthenticated, navigation.email]);

	useEffect(() => {
		if (navigation.email && !navigation.designId) {
			dispatch(setContentPanelMode(ContentPanelMode.NewSubmission));
		}
	}, [navigation.designId]);

	const whatsLoadingSection = isAuthLoading
		? <Text fontSize="2xl" fontWeight="bold" textAlign="center" color="black" >Checking Login</Text>
		: <Text fontSize="2xl" fontWeight="bold" textAlign="center" color="black" >{whatsLoading}</Text>;

	const loginSection = <>
		<Button minW={'200px'} fontSize="xl" fontWeight="bold" onClick={() => loginWithRedirect({
			appState: {
				returnTo: window.location.pathname
			}
		})}>Login</Button>
		<Text fontSize="2xl" fontWeight="bold" textAlign="center" color="black" >Please Login to Continue</Text>
	</>;

	let progressSection = null;
	if (!isAuthLoading && !isAuthenticated) {
		progressSection = loginSection;
	} else if (!isBootstrapped || isLoading) {
		progressSection = whatsLoadingSection;
	}
	// console.log('AppLoadingPanel (state:->progressSection)', progressSection, isBootstrapped, isAuthLoading, isAuthenticated);

	const panel = !isAuthenticated || !isBootstrapped || isLoading
		? <>
			<Box top={0} left={0} pos={'fixed'} w={'100%'} h={'100%'} zIndex={theme.z.overlay} bg={colorMode === 'dark' ? 'black' : 'white'} opacity={0.80}>
			</Box>
			<Box top={0} left={0} pos={'fixed'} w={'100%'} h={'100%'} zIndex={theme.z.overlay} display="flex" textAlign={'center'} justifyContent="center" alignItems="center">
				<Box minW="300px" w="50%" position={'fixed'} >
					<Logo pb="30px"/>
					{progressSection}
					{ error && <Box pt="20px">
						<Alert status="error">
							<AlertIcon />
							<AlertTitle>Login Error:</AlertTitle>
							<AlertDescription>{error.message}</AlertDescription>
						</Alert>
					</Box> }
				</Box>
			</Box>
		</>
		: null;

	// console.log('render:AppLoadingPanel');
	return panel;
};
