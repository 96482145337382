import { BsFillSignStopFill } from 'react-icons/bs';
import { IconBaseProps } from 'react-icons';
import { NotificationCountData } from '@rta/rta-blueprint-library/lib/blueprint-classes/notifications/types';
import { MdCheckCircle, MdOutlineError, MdOutlineWarning } from 'react-icons/md';

import { lang } from '@lang';
import { useDesign } from '@hooks';
import { ContextPanelMode, pushContextPanel, useAppDispatch } from '@state';
import { Feature, FeatureHoookParams } from '..';

const statusList: Record<string, {
	icon: (props: IconBaseProps) => JSX.Element;
	color: string;
	label: string;
}> = {
	good: {
		icon: (props = {}) => <MdCheckCircle {...props} />,
		color: 'green.300',
		label: lang.blueprintStatus.label.good
	},
	warn: {
		icon: (props = {}) => <MdOutlineWarning {...props} />,
		color: 'yellow.300',
		label: lang.blueprintStatus.label.warn
	},
	error: {
		icon: (props = {}) => <MdOutlineError {...props} />,
		color: 'red.300',
		label: lang.blueprintStatus.label.error
	},
	critical: {
		icon: (props = {}) => <BsFillSignStopFill {...props} />,
		color: 'red.500',
		label: lang.blueprintStatus.label.critical
	}
};

type BlueprintStatusFeatureParams = FeatureHoookParams;
export const showBlueprintStatusFeature = (_params: BlueprintStatusFeatureParams = {}): Feature<string> => {
	const dispatch = useAppDispatch();
	const [controller] = useDesign();

	const kitchen = controller?.kitchen ?? null;
	const notifications = kitchen?.design.notifications ?? null;
	const notificationCount = notifications?.getCounts() ?? null;
	// const notificationCount = { total: 1, info: 1, warn: 0, error: 0, critical: 0 };

	let blueprintStatus = 'good';

	if (notificationCount && notificationCount.total > 0) {
		const determineIconToShow = (notificationType: NotificationCountData) => {
			if (notificationType?.critical > 0) return 'critical';
			if (notificationType?.error > 0) return 'error';
			if (notificationType?.warn > 0) return 'warn';
			return 'good';
		};
		blueprintStatus = determineIconToShow(notificationCount);
	}

	const [enabled, tooltip] = (() => {
		if (controller === null) return [false, lang.blueprintStatus.tooltip.noDesign];
		if (notificationCount?.total === 0) return [false, lang.blueprintStatus.tooltip.noNotifications];
		return [true, statusList[blueprintStatus].label];
	})();

	return {
		name: 'blueprintStatus',
		label: statusList[blueprintStatus].label,
		shortcut: '',
		tooltip,
		icon: (props = {}) => statusList[blueprintStatus].icon(props),
		color: statusList[blueprintStatus].color,
		disabled: !enabled,
		value: blueprintStatus,
		hidden: false,
		handler: () => {
			if (notificationCount && notificationCount.total > 0) {
				dispatch(pushContextPanel({ mode: ContextPanelMode.BlueprintValidation }));
			}
		}
	};
};
