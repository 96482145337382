import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

interface IControllerSupportsFeature { options: {
	showAppliances: boolean;
	showDimensions: boolean
}}

type ApplianceDimensionFeatureParams = FeatureHoookParams;
export const useShowApplianceDimensionsFeature = (_params: ApplianceDimensionFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, IControllerSupportsFeature>();

	useSubscription(controller, false, [['layer-option-dimension', '*']]);

	const controllerSupportsFeature = (controller?.options?.showDimensions ?? null) !== null;
	const showDimensions = controller?.options?.showDimensions ?? true;
	const showAppliances = controller?.options?.showAppliances ?? true;
	const showLayer = showDimensions && showAppliances;

	const tooltip = (() => {
		const showHide = showLayer ? lang.layer.tooltip.hide : lang.layer.tooltip.show;
		return `${showHide} ${lang.layer.label.applianceDimensions} ${lang.layer.layer}`;
	})();

	return {
		name: 'showApplianceDimension',
		label: lang.layer.label.applianceDimensions,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled: !showAppliances || !controllerSupportsFeature,
		value: showLayer,
		hidden: false,
		handler: () => {
			if (controller) {
				controller.options.showDimensions = !showLayer;
			}
		}
	};
};
