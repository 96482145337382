import { useNavigate } from 'react-router-dom';
import { Box, Button, HStack, List, ListItem, Text, VStack } from '@chakra-ui/react';

import { BlueprintImage } from '../content-panel/find-customer/blueprint-image';
import { ContextPanelContent } from './context-panel-content';
import { lang } from '@lang';
import { useFindCustomerFeature } from '@features';
import { ContentPanelMode, CustomerState, popContextPanel, setContentPanelMode, setCustomerFeatureFindState, useAppDispatch, useAppSelector } from '@state';

export const FindCustomerPropertiesPanel = (): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const findCustomer = useFindCustomerFeature();
	const recentCustomers: Array<CustomerState> = JSON.parse(localStorage.getItem('recent-customers') ?? '[]');


	const navigateTo = (path: string) => {
		navigate(path);
		dispatch(popContextPanel());
		dispatch(setContentPanelMode(ContentPanelMode.Designer));
		dispatch(setCustomerFeatureFindState({ selectedEmail: null, customerName: null, thumbnails: [] }));
	};

	const recentCustomersElement = (
		<Box width="90%" border="1px" bgColor="gray.200" borderColor="gray.200" borderRadius="md" mt="10px" pb="5px">
			<HStack w="full" background="blue.200" justifyContent={'center'} mb={2}>
				<Text fontSize="mm" fontWeight="bold">{lang.customer.label.recentCustomers}</Text>
			</HStack>
			<VStack spacing={2}>
				{ recentCustomers.length === 0
					? <Text fontSize="xs" textAlign="center">{lang.customer.label.noRecentCustomers}</Text>
					: recentCustomers.map((customer, index) => (
						<Button key={index} fontSize="xs" height="30px" width="90%" onClick={() => navigateTo(`r/${customer.email}/`)}>
							{`${customer.firstName} ${customer.lastName}`}
						</Button>
					))
				}
			</VStack>
		</Box>
	);

	const showCustomerElement = (<>
		<List>
			<ListItem>Name: {findCustomer.value.customerName}</ListItem>
			<ListItem>Email: {findCustomer.value.selectedEmail}</ListItem>
		</List>
		<Box>
			{findCustomer.value.thumbnails.map((thumbnail, index) => <BlueprintImage key={index} image={thumbnail} />)}
		</Box>
		<Button
			isDisabled={!findCustomer.value.selectedEmail}
			onClick={() => navigateTo(`/r/${findCustomer.value.selectedEmail}`)}
		>{lang.customer.label.loadCustomer}</Button>
	</>);

	return <ContextPanelContent title={lang.contextPanels.title.findCustomerProperties} canExit={false}>
		{ findCustomer.value.selectedEmail ? showCustomerElement : recentCustomersElement }
	</ContextPanelContent>;
};
