/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { MdSave } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { lang } from '@lang';
import { saveQuote } from '@external';
import { useDesign } from '@hooks';
import { customerState, projectState, useAppSelector } from '@state';
import { Feature, FeatureHoookParams } from '..';

type SaveQuoteFeatureParams = FeatureHoookParams;
export const useSaveQuoteFeature = (_params: SaveQuoteFeatureParams = {}): Feature<null> => {
	const { getAccessTokenSilently } = useAuth0();
	const [controller] = useDesign();

	const customer = useAppSelector(customerState);
	const project = useAppSelector(projectState);

	// const taxRate = useTax();
	const toast = useToast();

	const [quoteId, setQuoteId] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (controller && quoteId !== null) {
			controller.kitchen.quoteId = quoteId;
			toast({
				title: lang.crm.message.quoteCreated,
				status: 'success',
				duration: 2000,
				isClosable: true
			});
		}
	}, [quoteId]);

	useEffect(() => {
		if (error !== null) {
			toast({
				title: error,
				status: 'error',
				duration: 2000,
				isClosable: true
			});
		}
	}, [error]);

	const dealId = project[0]?.dealId ?? null;
	const items = controller?.kitchen.itemizedMsrp.items;

	const [disabled, tooltip] = (() => {
		if (customer.email === '') return [true, lang.document.tooltip.missingCustomer];
		if (project.length === 0) return [true, lang.document.tooltip.missingHubspotData];
		if (dealId === '' || dealId === null) return [true, lang.crm.tooltip.noDealId];
		if (!items || items.length === 0) return [true, lang.crm.tooltip.noQuoteData];

		return [false, lang.crm.tooltip.generateQuote];
	})();


	return {
		name: 'saveQuote',
		label: lang.crm.label.saveQuote,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <MdSave {...props} />,
		disabled,
		value: null,
		hidden: false,
		handler: () => {
			if (controller) {
				saveQuote({
					controller,
					customer,
					dealId,
					getAccessTokenSilently,
					setQuoteId,
					setError
				});
			}
		}
	};
};
