import { Feature } from '@features';
import { IconBaseProps } from 'react-icons';
import { RtaIconButton, RtaIconButtonProps } from './rta-icon-button';

export type RtaFeatureIconButtonProps = Partial<RtaIconButtonProps> & {
	feature: Feature<any>;
	iconProps?: IconBaseProps;
};

export const RtaFeatureIconButton = (props: RtaFeatureIconButtonProps) => {
	const { feature, iconProps = {}, ...rest } = props;

	return <RtaIconButton
		{...rest}
		icon={feature.icon(iconProps)}
		onClick={feature.handler}
		enabled={!feature.disabled}
		hidden={feature.hidden}
		label={feature.tooltip}
	/>;
};
