import { PagedData } from '../types/paged-data.type';

export const emptyPagedDataRecord = <T>(): PagedData<T> => {
	return {
		data: [],
		totalCount: 0,
		page: 0,
		pageSize: 0,
		pageCount: 0
	};
};
