import { State } from './state.type';

export const stateInitializer = (): State => {
	return {
		firstName: '',
		lastName: '',
		email: '',
		zipcode: '',
		phone: '',
		address: '',
		country: ''
	};
};
