import { Box, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';

import { CollaborationGroup } from '../../groups/collaboration-group';
import { LogoGroup } from '../../groups/logo-group';
import { TitleGroup } from '../../groups/title-group';

export const HeaderBar = () => {
	const screenWidthIsLow = useBreakpointValue({ base: true, lg: false });

	return (
		<Box w={'calc(100% - 2px)'} h={'calc(100% - 2px)'} m={'1px'} shadow="lg">
			<Grid templateColumns={'110px 300px 1fr auto'} w={'100%'} h={'100%'} gap={'10px'}>
				<GridItem>
					<LogoGroup />
				</GridItem>
				<GridItem>
					<TitleGroup />
				</GridItem>
				<GridItem>
				</GridItem>
				<GridItem>
					<CollaborationGroup />
				</GridItem>
			</Grid>
		</Box>
	);
};
