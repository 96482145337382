import { FaPencilAlt } from 'react-icons/fa';
import { useEffect, useState } from 'react';

import { Box, Input, Tab, TabList, Tabs } from '@chakra-ui/react';

import { Designs } from './designs.type';
import { RtaIconButton } from '@components';

type DesignTabSelectionProps = {
	designs: Designs,
	selected?: string | null,
	disabled?: boolean,
	onSelect: (id: string, version: number | null, baseVersion: number | null) => void,
	onDesignNameChange?: (id: string, name: string) => void
};

export const DesignTabSelection = (props: DesignTabSelectionProps) => {
	const { designs, disabled, selected = null, onSelect } = props;
	const [isEditing, setIsEditing] = useState(false);
	const [editedName, setEditedName] = useState('');

	// eslint-disable-next-line no-undefined
	const tabIndex = selected ? designs.findIndex((design) => design.id === selected) : -1;

	const handleEditClick = () => {
		setIsEditing(true);
		setEditedName('');
	};

	const handleSaveClick = () => {
		setIsEditing(false);
		const designId = designs.find((design) => design.id === selected)?.id;
		if (designId && editedName !== '') {
			props.onDesignNameChange?.(designId, editedName);
		}
	};

	const handleSpecialKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.key) {
			case ' ':
				e.preventDefault();
				setEditedName(`${editedName} `);
				break;
			case 'Escape':
				setIsEditing(false);
				break;
			case 'Enter':
				handleSaveClick();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		setIsEditing(false);
		setEditedName('');
	}, [selected]);

	return <Box width="2000px">
		<Tabs variant="enclosed-colored" size="sm"
			index={tabIndex}
			onChange={(i) => onSelect(designs[i].id, designs[i].version, designs[i].baseVersion)}
		>
			<TabList>
				{designs.map((design) => <Tab isDisabled={disabled} key={`${design.id}:${design.version}`} minWidth="10em" fontSize="sm" display="flex" gap="5px" style={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					textAlign: 'left'
				}}>
					{isEditing && selected === design.id
						? <>
							<Input value={editedName} placeholder={design.description} size="xs" autoFocus onKeyDown={(e) => { handleSpecialKey(e); }} onChange={(e) => setEditedName(e.target.value.trim())} onBlur={() => handleSaveClick()} />
						</>
						: <>
							<>{(design.description ?? '') === '' ? 'no description' : design.description} { design.isDirty ? '*' : '' }</>
							{ selected && selected === design.id && <RtaIconButton icon={<FaPencilAlt />} height="15px" onClick={handleEditClick} />}
						</>
					}
				</Tab>)}
			</TabList>
		</Tabs>
	</Box>;
};
