import axios from 'axios';

export type GetCustomerBlueprintsParams = {
	// data
	email: string,
	// token
	getAccessTokenSilently: () => Promise<string>,
	// callbacks
	setState?: (data: any) => void,
	setError?: (error: string) => void
};

export const getCustomerBlueprints = async (params: GetCustomerBlueprintsParams): Promise<[any, string | null]> => {
	// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
	const { email, getAccessTokenSilently, setState, setError } = params;

	try {
		const token = await getAccessTokenSilently();
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/customers/${email}/blueprints`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		if (response.status !== 200) {
			if (setError) setError(response.data);
			return [null, response.data];
		}

		if (setState) setState(response.data.data);
		return [response.data.data, null];
	} catch (error) {
		if (setError) setError(error as string);
		return [null, error as string];
	}

	return [null, null];
};
