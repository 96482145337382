import { forwardRef, Tooltip } from '@chakra-ui/react';

import { RtaIconButtonBox, RtaIconButtonBoxProps } from './rta-icon-button-box';

export type RtaIconButtonProps = RtaIconButtonBoxProps & {
	label?: string;
};

export const RtaIconButton = forwardRef<RtaIconButtonProps, 'div'>((props, ref) => {
	const { height = '30px', label = '' } = props;

	return label.trim() === ''
		? (
			<RtaIconButtonBox {...props} />
		)
		: (
			<Tooltip hasArrow
				label={label}
				aria-label={label}
				openDelay={250}
				arrowSize={15}
			>
				<div ref={ref} style={{ height }}><RtaIconButtonBox {...props} /></div>
			</Tooltip>
		);
});

RtaIconButton.displayName = 'RtaIconButton';
