import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type BreakpointFeatureParams = FeatureHoookParams;
export const useShowBreakpointFeature = (_params: BreakpointFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, { options: { showBreakpoints: boolean }}>();

	useSubscription(controller, false, [['layer-option-breakpoint', '*']]);

	const controllerSupportsFeature = (controller?.options?.showBreakpoints ?? null) !== null;
	const showLayer = controller?.options?.showBreakpoints ?? true;

	const tooltip = (() => {
		const showHide = showLayer ? lang.layer.tooltip.hide : lang.layer.tooltip.show;
		return `${showHide} ${lang.layer.label.breakpoints} ${lang.layer.layer}`;
	})();

	return {
		name: 'showBreakpoint',
		label: lang.layer.label.breakpoints,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled: !controllerSupportsFeature,
		value: showLayer,
		hidden: false,
		handler: () => {
			if (controller) {
				controller.options.showBreakpoints = !showLayer;
			}
		}
	};
};
