import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type ShowTitleFeatureParams = FeatureHoookParams;
export const useShowTitleFeature = (_params: ShowTitleFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, { options: { showTitle: boolean }}>();

	useSubscription(controller, false, [['layer-option-title', '*']]);

	const controllerSupportsFeature = (controller?.options?.showTitle ?? null) !== null;
	const showLayer = controller?.options?.showTitle ?? true;

	const tooltip = (() => {
		const showHide = showLayer ? lang.layer.tooltip.hide : lang.layer.tooltip.show;
		return `${showHide} ${lang.layer.label.title} ${lang.layer.layer}`;
	})();

	return {
		name: 'showTitleLabel',
		label: lang.layer.label.title,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled: !controllerSupportsFeature,
		value: showLayer,
		hidden: false,
		handler: () => {
			if (controller) controller.options.showTitle = !showLayer;
		}
	};
};
