export enum ContextPanelMode {
	About = 'about',
	AddAppliance = 'add-appliance',
	AddCustomAppliance = 'add-custom-appliance',
	AddCutout = 'add-cutout',
	ApplianceProperties = 'appliance-properties',
	BlueprintValidation= 'blueprint-validation',
	ConnectionProperties = 'connection-properties',
	CountertopProperties = 'countertop-properties',
	CustomApplianceProperties = 'custom-appliance-properties',
	CutoutProperties = 'cutout-properties',
	DesignerOptions = 'designer-options',
	FindCustomerProperties = 'recent-customers',
	IslandProperties = 'island-properties',
	KitchenProperties = 'kitchen-properties'
}
