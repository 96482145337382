/* eslint-disable no-alert */
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Flex, List, ListItem, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Customer, fetchBlueprints } from '@external';
import { setCustomerFeatureFindState, useAppDispatch } from '@state';

type ListProps = {
	customers: Array<Customer>
};

export const CustomerList = ({ customers }: ListProps): JSX.Element => {
	const [blueprints, setBlueprint] = useState<[]>([]);
	const [selected, setSelected] = useState<[string, string]>(['', '']);
	const [loading, setLoading] = useState(false);
	const [fetchingError, setFetchingError] = useState<string | null>(null);
	const dispatch = useAppDispatch();

	const toast = useToast();
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		setLoading(false);
		if (blueprints.length > 0) {
			// console.log('blueprints:', blueprints);
			dispatch(setCustomerFeatureFindState({
				selectedEmail: selected[0],
				customerName: selected[1],
				thumbnails: blueprints.map((blueprint: any) => { return blueprint.thumbnail; })
			}));
		}
	}, [blueprints]);

	useEffect(() => {
		if (fetchingError) {
			toast({ title: 'Error', description: fetchingError, status: 'error', duration: 5000, isClosable: true });
			setFetchingError(null);
		}
	}, [fetchingError]);

	const handleSelectCustomer = (email: string, firstName: string, lastName: string) => {
		// toast({ description: email, status: 'info', duration: 5000, isClosable: true });
		const customerName = `${firstName} ${lastName}`;
		setSelected([email, customerName]);
		dispatch(setCustomerFeatureFindState({ selectedEmail: email, customerName, thumbnails: [] }));
		fetchBlueprints({ email, getAccessTokenSilently, setData: setBlueprint, setError: setFetchingError });
	};


	return (
		<Box rounded="10px" border="1px" borderColor="gray.400" bgColor="gray.200" width="100%" overflow="hidden">
			<Box overflowY="scroll" height="100%">
				<List>
					{customers.map((customer, i) => <ListItem key={i}>
						<Flex
							bg={ selected[0] === customer.email ? 'blue.300' : (i % 2 ? 'gray.200' : 'gray.300') }
							borderBottom="1px"
							onClick={() => handleSelectCustomer(customer.email, customer.firstName, customer.lastName)}
							cursor="pointer"
							direction="row"
							w="100%"
							border="1px 0 0 0" borderColor="gray.400"
							padding="10px"
							marginBottom="2px"
						>
							<Text marginX="10px">{customer.firstName} {customer.lastName}</Text>
							<Text>( {customer.email} )</Text>
						</Flex>
					</ListItem>)}
				</List>
			</Box>
		</Box>
	);
};
