import { Feature } from '@features';
import { IconBaseProps } from 'react-icons';
import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';

export type RtaFeatureButtonProps = ButtonProps & {
	feature: Feature<any>;
	useIcon?: boolean;
	iconProps?: IconBaseProps;
};

export const RtaFeatureButton = (props: RtaFeatureButtonProps): JSX.Element => {
	const { feature, useIcon = true, iconProps = {}, ...rest } = props;

	if (feature.hidden) return <></>;

	const button = <Button
		{...rest}
		leftIcon={useIcon ? feature.icon(iconProps) : props.leftIcon}
		tooltip={feature.tooltip}
		isDisabled={feature.disabled}
		onClick={() => feature.handler()}
	>{feature.label}</Button>;

	return (feature.tooltip ?? null) === null || feature.tooltip?.trim() === ''
		? button
		: <Tooltip label={feature.tooltip} aria-label={feature.tooltip} shouldWrapChildren>{button}</Tooltip>;
};
