
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { MdOutlineLogout, MdOutlineSettings } from 'react-icons/md';

export const UserProfileControls = () => {
	const {
		user,
		isAuthenticated,
		logout
	} = useAuth0();

	const { toggleColorMode } = useColorMode();
	const text = useColorModeValue('dark', 'light');
	const SwitchIcon = useColorModeValue(FaMoon, FaSun);

	return (<>
		<Box w={'100%'} h={'100%'} m={'1px'} border={'0px'}>
			<HStack h="100%">
				{isAuthenticated &&
					<Menu closeOnSelect={false}>
						<MenuButton
							as={IconButton}
							aria-label="Options"
							icon={
								<Avatar boxSize="10" name={user?.name} src={user?.picture} />
							}
							variant="ghost"
							_active={{
								bg: 'none'
							}}
						/>
						<MenuList>
							<MenuItem icon={<MdOutlineSettings />}>
								Settings
							</MenuItem>
							<MenuItem
								onClick={toggleColorMode}
								icon={<SwitchIcon />}
							>
								Change to {text} mode
							</MenuItem>
							<MenuItem
								icon={<MdOutlineLogout />}
								onClick={() => logout({ logoutParams: {
									returnTo: window.location.origin
								}})}
							>
								Logout
							</MenuItem>
						</MenuList>
					</Menu>
				}
				{!isAuthenticated && <Avatar boxSize="10" />}
			</HStack>
		</Box>
	</>);
};
