export type CurrencyOptions = { locale?: string; currency?: string }

export const formatPrice = (value: number, opts: CurrencyOptions = {}) => {
	const { locale = 'en-US', currency = 'USD' } = opts;
	const formatter = new Intl.NumberFormat(locale, {
		currency,
		style: 'currency',
		maximumFractionDigits: 2
	});
	return formatter.format(value);
};
