import { ComponentController as BpController } from '@rta/rta-blueprint-component';
import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';
import { projectState, useAppSelector } from '@state';

type FinalizeSaleFeatureParams = FeatureHoookParams;
export const useFinalizeSaleFeature = (_params: FinalizeSaleFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, { options: { readonly: boolean; }}>();
	const project = useAppSelector(projectState);

	const [disabled, tooltip] = (() => {
		const isBlueprintController = controller instanceof BpController;
		if (isBlueprintController) {
			if (controller.kitchen.quoteId !== null && project[0]?.dealStage === 'depositReceived') {
				return [false, lang.crm.tooltip.finalizeSale];
			}
		}
		return [true, lang.crm.tooltip.disabledFinalizeSale];
	})();

	return {
		name: 'finalizeSale',
		label: lang.crm.label.finalizeSale,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled,
		value: false,
		hidden: false,
		handler: () => {
			if (controller) {
				const isBlueprintController = controller?.instanceOf === 'bp-controller';
				if (isBlueprintController && controller.options) controller.options.readonly = true;
				controller.kitchen.isFinalized = true;
			}
		}
	};
};
