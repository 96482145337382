import { State } from './state.type';

export const stateInitializer = (): State => {
	// console.log('context: stateInitializer()');

	return {
		email: null,
		isLoading: false,
		designs: []
	};
};
