import { Blueprint } from '@rta/rta-blueprint-library';

import { NewSubmissionParams } from './types';
import {
	barGrillIslandShape,
	barIslandShape,
	galleyIslandShape,
	grillBarIslandShape,
	grillIslandShape,
	lShapeBarLeftShape,
	lShapeBarRightShape,
	lShapeWithBarLeftShape,
	lShapeWithBarRightShape,
	uShapedCornerLeftShape,
	uShapedCornerMiddleShape,
	uShapedCornerRightShape,
	uShapedIslandShape
} from './island-definitions';

export type IslandDefinitionType = {
	label: string;
	image: string;
	definition: (params: NewSubmissionParams) => Blueprint;
};


export const islandDefinitions = (): Array<IslandDefinitionType> => {
	return [
		{
			label: 'Grill Island',
			image: '/app/island-images/1-0.png',
			definition: (params: NewSubmissionParams) => { return grillIslandShape(params); }
		},
		{
			label: 'Bar Island',
			image: '/app/island-images/13-0.png',
			definition: (params: NewSubmissionParams) => { return barIslandShape(params); }
		},
		{
			label: 'Grill & Bar ( Bar Right )',
			image: '/app/island-images/6-0.png',
			definition: (params: NewSubmissionParams) => { return grillBarIslandShape(params); }
		},
		{
			label: 'Bar & Grill ( Bar Left )',
			image: '/app/island-images/7-0.png',
			definition: (params: NewSubmissionParams) => { return barGrillIslandShape(params); }
		},
		{
			label: 'L Shaped ( Bar Right )',
			image: '/app/island-images/5-0.png',
			definition: (params: NewSubmissionParams) => { return lShapeBarRightShape(params); }
		},
		{
			label: 'L Shaped ( Bar Left )',
			image: '/app/island-images/2-0.png',
			definition: (params: NewSubmissionParams) => { return lShapeBarLeftShape(params); }
		},
		{
			label: 'L Shaped with Bar Right',
			image: '/app/island-images/9-0.png',
			definition: (params: NewSubmissionParams) => { return lShapeWithBarRightShape(params); }
		},
		{
			label: 'L Shaped with Bar Left',
			image: '/app/island-images/8-0.png',
			definition: (params: NewSubmissionParams) => { return lShapeWithBarLeftShape(params); }
		},
		{
			label: 'U-Shaped',
			image: '/app/island-images/3-0.png',
			definition: (params: NewSubmissionParams) => { return uShapedIslandShape(params); }
		},
		{
			label: 'U-Shaped with Corner Right',
			image: '/app/island-images/11-0.png',
			definition: (params: NewSubmissionParams) => { return uShapedCornerRightShape(params); }
		},
		{
			label: 'U-Shaped with Corner Left',
			image: '/app/island-images/12-0.png',
			definition: (params: NewSubmissionParams) => { return uShapedCornerLeftShape(params); }
		},
		{
			label: 'U-Shaped with Middle Corner',
			image: '/app/island-images/10-0.png',
			definition: (params: NewSubmissionParams) => { return uShapedCornerMiddleShape(params); }
		},
		{
			label: 'Galley',
			image: '/app/island-images/4-0.png',
			definition: (params: NewSubmissionParams) => { return galleyIslandShape(params); }
		}
	];
};

// islands are 28" depth
// grill islands are 34.5" height
// countertop islands as 39.25"
// default all standard islad type widths to 60"
// corner islands: 36" wide, 25" depth
