import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type CornerFeatureParams = FeatureHoookParams;
export const useShowCornerFeature = (_params: CornerFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, { options: { showCorners: boolean }}>();

	useSubscription(controller, false, [['layer-option-corner', '*']]);

	const controllerSupportsFeature = (controller?.options?.showCorners ?? null) !== null;
	const showLayer = controller?.options?.showCorners ?? true;

	const tooltip = (() => {
		const showHide = showLayer ? lang.layer.tooltip.hide : lang.layer.tooltip.show;
		return `${showHide} ${lang.layer.label.corners} ${lang.layer.layer}`;
	})();

	return {
		name: 'showCorner',
		label: lang.layer.label.corners,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled: !controllerSupportsFeature,
		value: showLayer,
		hidden: false,
		handler: () => {
			if (controller) {
				controller.options.showCorners = !showLayer;
			}
		}
	};
};
