/* eslint-disable no-alert */
import { Box, Image } from '@chakra-ui/react';

export type BlueprintImageProps = {
	image: string;
};

export const BlueprintImage = ({ image }: BlueprintImageProps): JSX.Element => {
	const usePlaceholderImage = image === null;
	const useImageUrl = !usePlaceholderImage && image?.length < 1000;
	const imageData = useImageUrl ? `${process.env.REACT_APP_BLUEPRINT_IMAGES_BASE_URL}/${image}` : `data:image/jpeg;base64,${image}`;
	const imageSrc = imageData;
	console.log('imageSrc:', image);
	return (
		<Box marginBottom="10px" width="100%" borderColor="gray.200" border="1px" rounded="15px" overflow="hidden">
			<Image
				objectFit="cover"
				objectPosition="middle"
				src={imageSrc} width="250px" height="150px" style={{ border: '1px rgb(50,50,50,0.1) solid', borderRadius: '4px' }}/>
		</Box>
	);
};
