import { Blueprint, connectIslands, EmptyIslandDefaults, newEmptyIsland } from '@rta/rta-blueprint-library';

import { NewSubmissionParams } from '../types';

export const uShapedCornerRightShape = (params: NewSubmissionParams): Blueprint => {
	const { customer, user } = params;

	const bp = new Blueprint({ userEmail: user, dataIn: { email: customer }});
	const kitchen = bp.kitchens.getActiveVersion();
	kitchen.description = 'U-Shaped with Corner Right';

	const grill = newEmptyIsland(kitchen, EmptyIslandDefaults.grill, 0, true);
	const lBar = newEmptyIsland(kitchen, EmptyIslandDefaults.bar, 270);
	const corner = newEmptyIsland(kitchen, EmptyIslandDefaults.corner, 45);
	const rBar = newEmptyIsland(kitchen, EmptyIslandDefaults.bar, 90);

	// console.log('uShapedCornerRightShape', {
	// 	grill: grill.id,
	// 	lBar: lBar.id,
	// 	corner: corner.id,
	// 	rBar: rBar.id
	// });

	connectIslands({
		sourcePanel: lBar.frontPanel,
		targetPanel: grill.frontPanel.getLeftPanel()
	});

	connectIslands({
		sourcePanel: corner.frontPanel.getLeftPanel(),
		targetPanel: grill.frontPanel.getRightPanel()
	});

	connectIslands({
		sourcePanel: rBar.frontPanel.getLeftPanel(),
		targetPanel: corner.frontPanel.getRightPanel()
	});

	return bp;
};
