export const layer = {
	layer: 'Layer',
	label: {
		appliances: 'Appliances',
		applianceDimensions: 'Appliance Dimensions',
		applianceLabels: 'Appliance Labels',
		breakpoints: 'Breakpoints',
		corners: 'Corners',
		countertops: 'Countertops',
		cutouts: 'Cutouts',
		islandDimensions: 'Island Dimensions',
		title: 'Title',
		islanadOverlaps: 'Island Overlaps',
		overheadDimensions: 'Overhead Dimensions',
		panelInsideDimensions: 'Panel Inside Dimensions',
		panelOutsideDimensions: 'Panel Outside Dimensions',
		developerInsights: 'Developer Insights'
	},
	tooltip: {
		show: 'Show',
		hide: 'Hide'
	},
	message: {

	}
};

export default layer;
