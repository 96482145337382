import { HStack, StackProps, TextProps } from '@chakra-ui/react';

import { Price } from './price';
import { CurrencyOptions, formatPrice } from '../../../helpers';

interface PriceTagProps {
  currency?: CurrencyOptions
  price: number
  rootProps?: StackProps
  priceProps?: TextProps
  salePriceProps?: TextProps,
	type?: string,
	totalAdjustment?: number,
  onClick?: () => void
}


export const PriceTag = (props: PriceTagProps) => {
	const { price, currency, totalAdjustment, rootProps, priceProps, type, onClick } = props;
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};
	return (
		<HStack spacing="1" {...rootProps} onClick={handleClick} style={{ cursor: 'pointer' }}>
			<Price textProps={priceProps}>
				{ type ? (type === 'FIXED' ? formatPrice(price, currency) : `(${Math.abs(price)}%) ${formatPrice(totalAdjustment ?? 0, currency)}`) : formatPrice(price, currency) }
			</Price>
		</HStack>
	);
};
