import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { createAppSlice } from '../create-app-slice';
import { crmLoadCustomer } from '@external';
import { stateInitializer } from './state-initializer';
import { State } from './state.type';

export const loadCustomer = createAsyncThunk(
	'customer/loadCustomer',
	async (email: string, { rejectWithValue, extra }) => {
		const { getAccessTokenSilently } = extra as { getAccessTokenSilently: () => Promise<string> };
		const [project, error] = await crmLoadCustomer({ email, getAccessTokenSilently });
		if (error) return rejectWithValue(error);
		return project;
	}
);
// If you are not using async thunks you can use the standalone `createSlice`.
export const slice = createAppSlice({
	name: 'customer',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState: stateInitializer(),
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: (create) => ({
	}),
	extraReducers: (builder) => {
		builder
			.addCase(loadCustomer.pending, (state) => {
				console.log('state:loadCustomer:pending');

				state.email = '';
				state.firstName = '';
				state.lastName = '';
				state.zipcode = '';
				state.phone = '';
				state.address = '';
				state.country = '';
			})
			.addCase(loadCustomer.fulfilled, (state, action: PayloadAction<any>) => {
				console.log('state:loadCustomer:fulfilled');

				state.email = action.payload.email;
				state.firstName = action.payload.firstName;
				state.lastName = action.payload.lastName;
				state.zipcode = action.payload.zip;
				state.phone = action.payload.phone;
				state.address = action.payload.address;
				state.country = action.payload.country;

				const recentCustomers: Array<State> = JSON.parse(localStorage.getItem('recent-customers') ?? '[]');
				let recentCustomersUpdated: Array<State> = [];
				if (recentCustomers.length > 0) {
					recentCustomersUpdated = recentCustomers.filter((customer, index) => index < 5 && customer.email !== state.email);
					recentCustomersUpdated = [state, ...recentCustomersUpdated];
				} else {
					recentCustomersUpdated.push(state);
				}
				localStorage.setItem('recent-customers', JSON.stringify(recentCustomersUpdated));
			})
			.addCase(loadCustomer.rejected, (state) => {
				console.log('state:loadCustomer:rejected');
			});
	},
	// You can define your selectors here. These selectors receive the slice
	// state as their first argument.
	selectors: {
		customerState: (state) => state,
		selectCustomerName: (state) => `${state.firstName} ${state.lastName}`,
		selectCustomerEmail: (state) => state.email
	}
});
