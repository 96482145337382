import axios from 'axios';
import { BlueprintData, RawBlueprint } from '@rta/rta-blueprint-library';

import { ExternalError } from './types';
import { set } from 'lodash';

export type FetchBlueprintParams = {
	email: string,
	getAccessTokenSilently: () => Promise<string>,
	setData?: (data: []) => void,
	setError?: (error: string) => void

};

export const fetchBlueprints = async (params: FetchBlueprintParams): Promise<[Array<BlueprintData | RawBlueprint> | null, ExternalError | null]> => {
	const { getAccessTokenSilently, email, setData, setError } = params;
	console.log('external:fetchBlueprints', email);

	if (email !== '') {
		const token = await getAccessTokenSilently();
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/customers/${email}/blueprints`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).catch((error) => {
			console.warn('fetchBlueprints (failed)----', error.response);
			return error.response;
		});

		// console.log('response ----', response);
		if (response.status === 200) {
			if (setData) setData(response.data.data);
			return [response.data, null];
		} else if (response.status === 204) {
			if (setData) setData([]);
			return [[], null];
		}

		if (setError) setError('Unable to fetch blueprints for the selected customer.');
		return [null, { status: response.status, message: response.data.message }];
	}

	return [[], null];
};


