import { Kitchen } from '@rta/rta-blueprint-library';
import { Document, Font, Page } from '@react-pdf/renderer';

import { fontConfig } from '../font-config';
import { styleSheet } from '../style-sheet';

import { ProposalDocumentPageFooter } from './proposal-document-page-footer';
import { ProposalDocumentPageHeader } from './proposal-document-page-header';
import { ProposalDocumentPanelFooter } from './propsal-document-panel-footer';
import { CustomerState, ProjectState } from '@state';
import { ProposalDocumentPageBackPanel, ProposalDocumentPageCountertop, ProposalDocumentPageFrontPanel, ProposalDocumentPageIntro, ProposalDocumentPageKitchenOveriew, ProposalDocumentPageOverheadLayout, ProposalDocumentPageRevisionLog, ProposalDocumentPageSidePanel } from './pages';

Font.register(fontConfig);

export type ProposalDocumentProps = {
	data: {
		customer: CustomerState;
		project: ProjectState;
		tax: number;
		kitchen: Kitchen | null;
		date: string;
	};
}

// Create Document Component
export const ProposalDocument = (props: ProposalDocumentProps) => {
	const { customer, kitchen, project, date } = props.data;
	let page = 1;

	if (!customer || !kitchen || !project) return null;

	return (<>
		<Document>
			<Page size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
				<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={''} />
				<ProposalDocumentPageIntro />
				<ProposalDocumentPageFooter page={page} />
			</Page>
			<Page size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
				<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Kitchen Overview'} />
				<ProposalDocumentPageKitchenOveriew kitchen={kitchen} />
				<ProposalDocumentPageFooter page={++page} />
			</Page>
			<Page size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
				<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Revision Log'} />
				<ProposalDocumentPageRevisionLog date={date} project={project} />
				<ProposalDocumentPageFooter page={++page} />
			</Page>
			<Page size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
				<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Overhead Layout'} />
				<ProposalDocumentPageOverheadLayout />
				<ProposalDocumentPageFooter page={++page} />
			</Page>
			{kitchen.design.islands.children.length > 0 && kitchen.design.islands.children.map((island, index) => {
				const frontPanelNotes = [
					'Please Note: This view is the exterior side of the panel',
					'MFG: Please add additional support to back panel'
				];
				const sidePanelNotes = ['Please Note: This view is the exterior side of the panels'];
				const backPanelNotes = ['Please Note: This view is the exterior side of the panel'];
				const countertopNotes = [`Please Note: ${page + 1}” back overhang to account for corner trim pieces`];

				return (<>
					<Page key={index} size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
						<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Front Panel'} />
						<ProposalDocumentPageFrontPanel />
						<ProposalDocumentPanelFooter notes={frontPanelNotes} page={++page} />
					</Page>
					<Page key={index} size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
						<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Side Panels'} />
						<ProposalDocumentPageSidePanel />
						<ProposalDocumentPanelFooter notes={sidePanelNotes} page={++page} />
					</Page>
					<Page key={index} size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
						<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Back Panel'} />
						<ProposalDocumentPageBackPanel />
						<ProposalDocumentPanelFooter notes={backPanelNotes} page={++page} />
					</Page>
					<Page key={index} size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
						<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Counertop'} />
						<ProposalDocumentPageCountertop />
						<ProposalDocumentPanelFooter notes={countertopNotes} page={++page} />
					</Page>
				</>
				);
			})}
		</Document>
	</>);
};
