import { Blueprint, EmptyIslandDefaults, newEmptyIsland } from '@rta/rta-blueprint-library';

import { NewSubmissionParams } from '../types';

export const grillIslandShape = (params: NewSubmissionParams): Blueprint => {
	const { customer, user } = params;

	const bp = new Blueprint({ userEmail: user, dataIn: { email: customer }});
	const kitchen = bp.kitchens.getActiveVersion();
	kitchen.description = 'Grill Kitchen';

	newEmptyIsland(kitchen, EmptyIslandDefaults.grill, 0, true);

	return bp;
};
