import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { createAppSlice } from '../create-app-slice';
import { crmLoadProject } from '@external';
import { Project } from './types';
import { stateInitializer } from './state-initializer';

export const loadProject = createAsyncThunk(
	'project/loadProject',
	async (email: string, { rejectWithValue, extra }) => {
		const { getAccessTokenSilently } = extra as { getAccessTokenSilently: () => Promise<string> };
		const [project, error] = await crmLoadProject({ email, getAccessTokenSilently });
		if (error) return rejectWithValue(error);
		return project;
	}
);

// If you are not using async thunks you can use the standalone `createSlice`.
export const slice = createAppSlice({
	name: 'project',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState: stateInitializer(),
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: (create) => ({
	}),
	extraReducers: (builder) => {
		builder
			.addCase(loadProject.pending, (state) => {
				console.log('state:loadProject:pending');
				state.splice(0, state.length);
				// state[0].totalPaymentRecieved = 0;
				// state[0].dealStage = '';
				// state[0].depositDocument = '';
				// state[0].blueprintApprovalDocument = '';
				// state[0].blueprintLiaison.email = '';
				// state[0].blueprintLiaison.firstName = '';
				// state[0].blueprintLiaison.lastName = '';
				// state[0].designer.email = '';
				// state[0].designer.firstName = '';
				// state[0].designer.lastName = '';
				// state[0].projectManager.email = '';
				// state[0].projectManager.firstName = '';
				// state[0].projectManager.lastName = '';
			})
			.addCase(loadProject.fulfilled, (state, action: PayloadAction<any>) => {
				console.log('state:loadProject:fulfilled');
				state.splice(0, state.length);
				action.payload.forEach((project: Project) => {
					state.push({
						totalPaymentRecieved: project.totalPaymentRecieved,
						dealId: project.dealId,
						dealStage: project.dealStage,
						depositDocument: project.depositDocument,
						blueprintApprovalDocument: project.blueprintApprovalDocument,
						blueprintLiaison: {
							email: project.blueprintLiaison?.email ?? '',
							firstName: project.blueprintLiaison?.firstName ?? '',
							lastName: project.blueprintLiaison?.lastName ?? ''
						},
						designer: {
							email: project.designer?.email ?? '',
							firstName: project.designer?.firstName ?? '',
							lastName: project.designer?.lastName ?? ''
						},
						projectManager: {
							email: project.projectManager?.email ?? '',
							firstName: project.projectManager?.firstName ?? '',
							lastName: project.projectManager?.lastName ?? ''
						}
					});
				});
			})
			.addCase(loadProject.rejected, (state) => {
				console.log('state:loadProject:rejected');
			});
	},
	// You can define your selectors here. These selectors receive the slice
	// state as their first argument.
	selectors: {
		projectState: (state) => state
	}
});
