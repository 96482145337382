/* eslint-disable consistent-return */
import { useContext } from 'react';

import { AppContext } from '@context';
import { Feature } from '..';
import { lang } from '@lang';
import { selectNavigation, setDeleteCustomerDraftsModal, useAppDispatch, useAppSelector } from '@state';

export const useDeleteCustomerDraftsFeature = (): Feature<boolean> => {
	const dispatch = useAppDispatch();
	const appNav = useAppSelector(selectNavigation);

	const { appState: { customerDesigns: designs }} = useContext(AppContext);

	const [enabled, tooltip] = (() => {
		for (const design of designs.designs) {
			if (localStorage.getItem(`autosave-${design.blueprint.id}`)) {
				return [true, lang.customer.tooltip.deleteCustomerDrafts];
			}
		}
		return [false, lang.customer.tooltip.noCustomerDrafts];
	})();

	return {
		name: 'deleteCustomerDrafts',
		label: lang.customer.label.deleteCustomerDrafts,
		shortcut: '',
		tooltip,
		icon: () => <></>,
		disabled: !enabled,
		value: enabled,
		hidden: appNav.email === null ?? false,
		handler: () => {
			dispatch(setDeleteCustomerDraftsModal(true));
		}
	};
};
