import { ItemizedLineItem, Kitchen } from '@rta/rta-blueprint-library';
import { Font, Text, View } from '@react-pdf/renderer';

import { fontConfig } from '../font-config';
import { formatPrice } from '../../../helpers';
import { styleSheet } from '../style-sheet';

Font.register(fontConfig);

export type EstimateItemizedProps = {
	data: {
		kitchen: Kitchen;
		tax: number;
	};
}
// Create Document Component
export const EstimateItemized = (data: EstimateItemizedProps) => {
	const { kitchen, tax } = data.data;

	const kitchenSubtotal = kitchen.itemizedMsrp.subtotal - kitchen.itemizedMsrp.promotional;
	const kitchenTax = tax ?? 'TBD';
	const kitchenTotal = kitchenSubtotal + (tax ?? 0);

	return (<>
		<View style={styleSheet.container}>
			<Text style={styleSheet.headingLarge}>Estimate</Text>
		</View>
		<View>
			<View style={styleSheet.tableHeaderRow}>
				<Text style={[styleSheet.tableCellFirst, styleSheet.tableCellBorderedRight]}>Name</Text>
				<Text style={[styleSheet.tableCellPrice, styleSheet.tableCellBorderedRight]}>Price</Text>
				<Text style={[styleSheet.tableColumnQty, styleSheet.tableCellBorderedRight]}>QTY</Text>
				<Text style={styleSheet.tableCellSubtotal}>Subtotal</Text>
			</View>
			{kitchen.itemizedMsrp?.islands.map((island: any) => {
				return (
					<View style={styleSheet.tableRow} key={island.sku}>
						<View style={styleSheet.tableCellFirst}>
							<Text>{island.sku}</Text>
							<Text>{`${island.description} | ${kitchen?.material.surround.displayName}`}</Text>
						</View>
						<Text style={styleSheet.tableCellPrice}>{formatPrice(island.each)}</Text>
						<Text style={styleSheet.tableColumnQty}>{island.qty}</Text>
						<Text style={styleSheet.tableCellSubtotal}>{formatPrice(island.total)}</Text>
					</View>
				);
			})
			}
			{kitchen.itemizedMsrp?.countertops?.filter((countertop: ItemizedLineItem) => !countertop.sku.startsWith('RTA-Chiseled'))
				.map((item: ItemizedLineItem) => {
					return (
						<View style={styleSheet.tableRow} key={item.sku}>
							<View style={styleSheet.tableCellFirst}>
								<Text>{item.sku}</Text>
								<Text>{item.description}</Text>
							</View>
							<Text style={styleSheet.tableCellPrice}>{formatPrice(item.each)}</Text>
							<Text style={styleSheet.tableColumnQty}>{item.qty}</Text>
							<Text style={styleSheet.tableCellSubtotal}>{formatPrice(item.total)}</Text>
						</View>
					);
				})
			}
			{kitchen.itemizedMsrp?.items.map((item: any) => {
				return (
					<View style={styleSheet.tableRow} key={item.sku}>
						<View style={styleSheet.tableCellFirst}>
							<Text>{item.sku}</Text>
							<Text>{item.description}</Text>
						</View>
						<Text style={styleSheet.tableCellPrice}>{formatPrice(item.each)}</Text>
						<Text style={styleSheet.tableColumnQty}>{item.qty}</Text>
						<Text style={styleSheet.tableCellSubtotal}>{formatPrice(item.total)}</Text>
					</View>
				);
			})}
			{kitchen.promotionalSkus.children.map((promotional: any) => {
				return (
					<View style={styleSheet.tableRow} key={promotional.sku}>
						<View style={styleSheet.tableCellFirst}>
							<Text>{promotional.sku}</Text>
							<Text>{promotional.adjustmentType === 'FIXED' ? promotional.description : `${promotional.description} (${promotional.priceAdjustment}%)`}</Text>
						</View>
						<Text style={styleSheet.tableCellPrice}>-{promotional.adjustmentType === 'FIXED' ? formatPrice(promotional.priceAdjustment) : formatPrice(promotional.totalAdjustment)}</Text>
						<Text style={styleSheet.tableColumnQty}>1</Text>
						<Text style={styleSheet.tableCellSubtotal}>-{promotional.adjustmentType === 'FIXED' ? formatPrice(promotional.priceAdjustment) : formatPrice(promotional.totalAdjustment)}</Text>
					</View>
				);
			})}
		</View>
		<View style={[styleSheet.containerRight]}>
			<View style={[styleSheet.flex, styleSheet.marginBottom]}>
				<Text style={styleSheet.heading}>Subtotal</Text>
				<Text style={styleSheet.text}>{formatPrice(kitchenSubtotal)}</Text>
			</View>
			<View style={[styleSheet.flex, styleSheet.marginBottom]}>
				<Text style={styleSheet.heading}>Tax</Text>
				<Text style={styleSheet.text}>{formatPrice(kitchenTax)}</Text>
			</View>
			<View style={[styleSheet.flex, styleSheet.marginBottom]}>
				<Text style={styleSheet.heading}>Total</Text>
				<Text style={styleSheet.text}>{formatPrice(kitchenTotal)}</Text>
			</View>
		</View>
	</>);
};
