import {
	Box,
	List
} from '@chakra-ui/react';

import { EstimateItem } from './estimate-item';
import { EstimateItemAddPromotionalSku } from './estimate-item-add-promotional-sku';
import { ItemizedLineItem, Kitchen, KitchenItemizedMsrp } from '@rta/rta-blueprint-library';

export type EstimateItemizedContentProps = {
	kitchenData: Kitchen;
	itemData: KitchenItemizedMsrp | null;
}

export const EstimateItemizedContent = ({ kitchenData, itemData }: EstimateItemizedContentProps) => {
	const islandData = itemData?.islands ?? null;

	const promotionalData = kitchenData?.promotionalSkus?.children ?? null;
	const surround = kitchenData?.material?.surround.displayName ?? null;
	// const countertopsData = kitchenData?.material?.countertop ?? null;
	return (
		<Box pl="10px" pr="10px" overflowY={'scroll'}>
			{islandData?.map((island) => (
				<EstimateItem
					key={island.sku}
					sku={island.sku}
					description={`${island.description} | ${surround}`}
					quantity={island.qty}
					price={island.total}
					currency={{ locale: 'en-US', currency: 'USD' }}
				/>
			))}
			{itemData?.items?.map((item) => (
				<EstimateItem
					key={item.sku}
					sku={item.sku}
					description={item.description}
					quantity={item.qty}
					price={item.total}
					currency={{ locale: 'en-US', currency: 'USD' }}
				/>
			))}
			{itemData?.countertops?.filter((countertop: ItemizedLineItem) => !countertop.sku.startsWith('RTA-Chiseled'))
				.map((countertop: ItemizedLineItem) => (
					<EstimateItem
						key={countertop.sku}
						sku={countertop.sku}
						description={countertop.description}
						quantity={countertop.qty}
						price={countertop.total}
						currency={{ locale: 'en-US', currency: 'USD' }}
					/>
				))
			}
			<List spacing={2}>
				{promotionalData?.map((pSku) => (
					<EstimateItem
						key={pSku.sku}
						sku={pSku.sku}
						description={pSku.description}
						quantity={1}
						price={pSku.priceAdjustment}
						totalAdjustment={pSku.totalAdjustment}
						currency={{ locale: 'en-US', currency: 'USD' }}
						type={pSku.adjustmentType}
						isPromotional={true}
					/>
				))}
			</List>
			<EstimateItemAddPromotionalSku />
		</Box>

	);
};
