/* eslint-disable max-statements */
import axios from 'axios';

import { Controller } from '@context';
import { CustomerState } from '@state';
import { fetchTax } from './fetch-tax';
import { ItemizedLineItem } from '@rta/rta-blueprint-library';

export type SaveQuoteParams = {
	controller: Controller,
	customer: CustomerState,
	dealId: string,
	getAccessTokenSilently: () => Promise<string>,
	setQuoteId: (quoteId: string) => void,
	setError?: (error: string) => void
};

export const saveQuote = async (params: SaveQuoteParams): Promise<[string | null, string | null]> => {
	// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
	const { controller, customer, dealId, getAccessTokenSilently, setQuoteId, setError = (e) => {} } = params;

	const kitchenData = controller?.kitchen ?? null;
	const itemData = kitchenData?.itemizedMsrp ?? null;
	const islandData = itemData?.islands ?? null;
	const promotionalData = kitchenData?.promotionalSkus?.children ?? null;

	const items: Array<{ quantity: number; amount: number; sku: string; }> = [];
	islandData?.forEach((item) => {
		items.push({
			quantity: item.qty,
			amount: item.each,
			sku: item.sku
		});
	});

	itemData?.items.forEach((item) => {
		items.push({
			quantity: item.qty,
			amount: item.each,
			sku: item.sku
		});
	});

	itemData.countertops.filter((countertop: ItemizedLineItem) => !countertop.sku.startsWith('RTA-Chiseled')).forEach((item) => {
		items.push({
			quantity: item.qty,
			amount: item.each,
			sku: item.sku
		});
	});

	promotionalData?.forEach((item) => {
		items.push({
			quantity: 1,
			amount: item.priceAdjustment > 0 ? -item.priceAdjustment : 0,
			sku: item.sku
		});
	});

	const customerCode = process.env.REACT_APP_AUTH0_AUDIENCE === 'blueprint-api-live'
		? `${customer.firstName} ${customer.lastName}`
		: 'test test';

	const [tax, taxError] = await fetchTax({ kitchen: kitchenData, customer, getAccessTokenSilently });
	if (taxError) return [null, taxError];
	kitchenData.tax = tax as number;

	const quote = {
		customerCode,
		dealId,
		tax,
		items
	};

	try {
		const token = await getAccessTokenSilently();
		const response = await axios.post(`${process.env.REACT_APP_API_URL}/create/quote`, quote, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		if (response.status === 200) {
			if (setQuoteId) setQuoteId(response.data);
			kitchenData.quoteId = response.data;
			return [response.data ?? null, null];
		}

		throw new Error('Error submitting quote');
	} catch (error) {
		if (setError) setError('Error submitting quote');
		return [null, 'Error submitting quote'];
	}

	return [null, null];
};
