import { Kitchen } from '@rta/rta-blueprint-library';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';

import { EstimateDocumentHeader } from './estimate-document-header';
import { EstimateItemized } from './estimate-itemized-table';
import { fontConfig } from '../font-config';
import { styleSheet } from '../style-sheet';
import { CustomerState, ProjectState } from '@state';

import { Kitchen3dController as TdComponentController } from '@rta/rta-blueprint-3d-component';

Font.register(fontConfig);

export type EstimateDocumentProps = {
	data: {
		customer: CustomerState;
		project: ProjectState;
		tax: number;
		kitchen: Kitchen | null;
		image: string | null;
	};
}
// Create Document Component
export const EstimateDocument = (props: EstimateDocumentProps) => {
	const { customer, kitchen, project, tax, image } = props.data;

	if (!customer || !kitchen || !project) return null;

	return (<>
		<Document>
			<Page size="LETTER" style={styleSheet.page}>
				<Image src={process.env.REACT_APP_RTA_LOGO_URL} style={styleSheet.logo} />
				<EstimateDocumentHeader data={{ customer, kitchen, project }} />
				<View style={styleSheet.body}>
					<EstimateItemized data={{ kitchen, tax }} />
					<View>
						<Text style={styleSheet.heading}>Note: <Text style={styleSheet.text}>
					If sales tax is not included on this order but you live in a state with sales tax, it will either be
					collected by RTA at the same time of shipping, or must be paid to the state directly by the consumer.
						</Text></Text>
					</View>
				</View>
			</Page>
			<Page size="LETTER" style={styleSheet.page}>
				<Image src={process.env.REACT_APP_RTA_LOGO_URL} style={styleSheet.logo} />
				{ image && <Image src={image} style={styleSheet.estimateBPImage} /> }
			</Page>
		</Document>
	</>);
};
