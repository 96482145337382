import { Kitchen } from '@rta/rta-blueprint-library';
import { Font, Image, Text, View } from '@react-pdf/renderer';

import { fontConfig } from '../font-config';
import { styleSheet } from '../style-sheet';

Font.register(fontConfig);

export type EstimateDocumentHeaderProps = {
	data: {
		customer: any;
		project: any;
		kitchen: Kitchen;
	};
}
// Create Document Component
export const EstimateDocumentHeader = (data: EstimateDocumentHeaderProps) => {
	const { customer, project, kitchen } = data.data;

	const date = new Date();
	const dateString = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
	const timeString = date.toLocaleTimeString('en-US');
	const formattedDate = `${dateString} (${timeString})`;

	return (
		<View style={styleSheet.header}>
			<View style={styleSheet.block}>
				<Text style={[styleSheet.heading, styleSheet.marginBottom]}>{customer?.firstName} {customer?.lastName}</Text>
				<Text style={styleSheet.heading}>{customer?.address}</Text>
			</View>
			<View style={[styleSheet.block, styleSheet.flex]}>
				<Text style={styleSheet.heading}>RTA Designer:</Text>
				<Text style={styleSheet.text}>{project[0].designer?.firstName} {project[0].designer?.lastName}</Text>
			</View>
			<View style={[styleSheet.block, styleSheet.flex]}>
				<Text style={styleSheet.heading}>Estimate Date:</Text>
				<Text style={styleSheet.text}>{formattedDate}</Text>
			</View>
			<View>
				<View style={styleSheet.flex}>
					<Text style={[styleSheet.heading, styleSheet.marginBottom]}>Island Surround Finish:</Text>
					<Text style={styleSheet.text}>{kitchen?.material.surround.displayName}</Text>
				</View>
				<View style={styleSheet.flex}>
					<Text style={[styleSheet.heading, styleSheet.marginBottom]}>Countertop Finish:</Text>
					<Text style={styleSheet.text}>{kitchen?.material.countertop.displayName}</Text>
				</View>
				<View style={styleSheet.flex}>
					<Text style={[styleSheet.heading, styleSheet.marginBottom]}>Island Location:</Text>
					<Text style={styleSheet.text}>{kitchen.description}</Text>
				</View>
				<View style={styleSheet.flex}>
					<Text style={[styleSheet.heading, styleSheet.marginBottom]}>Fuel Source:</Text>
					<Text style={styleSheet.text}>{kitchen.fuelchoice}</Text>
				</View>
			</View>
		</View>
	);
};
