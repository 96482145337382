import {
	Box,
	Grid,
	GridItem,
	useBreakpointValue,
	useTheme
} from '@chakra-ui/react';
import { ContentPanel, LeftDock, RightDock } from '..';

import { dockConfig } from '../..//config';
import { selectLeftDock, selectRightDock, useAppSelector } from '@state';

export const MainArea = () => {
	const theme = useTheme();
	const screenWidthIsLow = useBreakpointValue({ base: true, md: false });

	const leftDock = useAppSelector(selectLeftDock);
	const rightDock = useAppSelector(selectRightDock);

	const topDockHeight = screenWidthIsLow ? dockConfig.top.header.height : dockConfig.top.height;

	const leftWidth = screenWidthIsLow
		? 0
		: dockConfig.left.maxWidth - (leftDock.isActionPanelOpen ? 0 : dockConfig.left.action.width);
	const rightWidth = screenWidthIsLow
		? 0
		: rightDock.isOpen ? dockConfig.right.maxWidth : dockConfig.right.minWidth;

	const mainArea = screenWidthIsLow
		? <>
			<Box
				pos={'fixed'}
				overflow={'hidden'}
				top={`${topDockHeight}px`}
				w={'100%'}
				h={`calc(100% - ${topDockHeight + dockConfig.bottom.height}px)`}>
				<ContentPanel />
			</Box>
		</>
		: <>
			<Box
				pos={'fixed'}
				overflowX={'hidden'}
				overflowY={'auto'}
				top={`${topDockHeight}px`}
				w={'100%'}
				h={`calc(100% - ${topDockHeight + dockConfig.bottom.height}px)`}>
				<Grid templateColumns={`${leftWidth}px 1fr ${rightWidth}px`} h={'100%'} overflow="hidden">
					<GridItem w="100%" h="100%" overflow="auto" zIndex={theme.z.docked}>
						<LeftDock dockWidth={leftWidth} isOpen={leftDock.isOpen} />
					</GridItem>
					<GridItem w="100%" h="100%" overflow="hidden" zIndex={theme.z.base}>
						<ContentPanel />
					</GridItem>
					<GridItem w="100%" h="100%" overflow="auto" zIndex={theme.z.docked}>
						{/* <RightDock width={`${rightDock.width}px`}/> */}
						<RightDock isOpen={rightDock.isOpen} />
					</GridItem>
				</Grid>
			</Box>
		</>;

	return mainArea;
};
