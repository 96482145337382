import { useRef } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
import { selectConfirmCustomerClose, setConfirmCustomerClose, useAppDispatch, useAppSelector } from '@state';

export const ConfirmCustomerCloseAlert = () => {
	const dispatch = useAppDispatch();
	const { isOpen } = useAppSelector(selectConfirmCustomerClose);

	const cancelRef = useRef<any>();
	const onClose = (confirmed: boolean) => dispatch(setConfirmCustomerClose({ isOpen: false, confirmed }));

	return (<>
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={() => onClose(false)}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						Add New Customer
					</AlertDialogHeader>

					<AlertDialogBody>
						This operation will close the current customer, leaving any unsaved changes as local drafts. Are you sure?
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={() => onClose(false)}>
							Cancel
						</Button>
						<Button colorScheme="red" onClick={() => onClose(true)} ml={3}>
							Continue
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	</>);
};
