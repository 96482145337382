import { showBlueprintStatusFeature } from '@features';
import { Box, HStack } from '@chakra-ui/react';

import { RtaFeatureIconButton } from '@components';

export const StatusControls = () => {
	const blueprintStatus = showBlueprintStatusFeature();

	return (
		<Box w={'100%'} h={'100%'} m={'1px'} border={'0px'}>
			<HStack h={'100%'} gap={'0px'}>
				<RtaFeatureIconButton feature={blueprintStatus} />
			</HStack>
		</Box>
	);
};
