import { PromotionalSkus } from '@rta/rta-blueprint-library';
import { useDesign } from '@hooks';
import { Select, useChakraSelectProps } from 'chakra-react-select';

import { useAddPromotionalSkuFeature, FeatureHoookParams, useShowPromotionalSkuDropdownFeature } from '@features';
import { Box, Button, Tooltip } from '@chakra-ui/react';
import { selectPromotionalSkuDropdown, setSelectedPromotionalSku, useAppDispatch, useAppSelector } from '@state';
import { useEffect, useState } from 'react';

export const EstimateItemAddPromotionalSku = () => {
	const dispatch = useAppDispatch();

	const promotionalSku = useShowPromotionalSkuDropdownFeature();
	const promotionalSkuDropdown = useAppSelector(selectPromotionalSkuDropdown);
	const addPromotionalSku = useAddPromotionalSkuFeature();

	const [selectedSkus, setSelectedSkus] = useState<string[]>([]);


	useEffect(() => {
		dispatch(setSelectedPromotionalSku(selectedSkus));
	}, [selectedSkus]);


	const multiSelectOptions: any = [];

	PromotionalSkus.promotionalSkus.map((singleSku) => (
		multiSelectOptions.push({
			fontSize: 'sm',
			label: singleSku
		})
	));

	const selectProps = useChakraSelectProps({
		isMulti: false,
		colorScheme: 'blue',
		value: selectedSkus.map((sku) => ({ label: sku })),
		options: multiSelectOptions,
		onChange: (newValue, actionMeta) => {
			if (actionMeta.action === 'clear') {
				setSelectedSkus([]);
			} else if (actionMeta.action === 'select-option' || actionMeta.action === 'remove-value') {
				console.log('newValue', newValue);
				if (newValue) setSelectedSkus([newValue.label]);
			}
		},
		placeholder: 'Select Promotional Sku'
	});


	return (
		<Box textAlign="center" marginTop="5px" borderTop={'1px'} pt={'10px'}>
			{promotionalSkuDropdown && (
				<Box display="flex" flexDirection="column" gap="5px" marginBottom="10px">
					<Select {...selectProps}/>

					<Tooltip label={addPromotionalSku.tooltip} aria-label={addPromotionalSku.tooltip}>
						<Button size="xs" isDisabled={addPromotionalSku.disabled} onClick={() => {
							addPromotionalSku.handler(); setSelectedSkus([]);
						}}>
							{addPromotionalSku.label}
						</Button>
					</Tooltip>
				</Box>
			)}
			<Tooltip label={promotionalSku.tooltip} aria-label={promotionalSku.tooltip}>
				<Button onClick={promotionalSku.handler} disabled={promotionalSku.disabled}>{promotionalSku.label}</Button>
			</Tooltip>
		</Box>
	);
};
