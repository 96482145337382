import { MdOutlineZoomOut } from 'react-icons/md';
import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { ControllerAutoZoomSupport } from './types';
import { defaultConfig } from './default-config';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type ZoomOutFeatureParams = FeatureHoookParams;

export const useZoomOutFeature = (params: ZoomOutFeatureParams = {}): Feature<number> => {
	const [controller] = useDesign<Controller, ControllerAutoZoomSupport>();
	// const controller = params.controller as (typeof params.controller) & ControllerAutoZoomSupport;

	const controllerSupportsAutozoomFeature = (controller?.options?.autoZoom ?? null) !== null;
	const autozoom = controllerSupportsAutozoomFeature ? controller?.options.autoZoom ?? false : false;

	useSubscription(controller as Controller, false, [['zoom', '*']]);

	const [enabled, tooltip] = (() => {
		if (autozoom) return [false, lang.zoom.message.disabled4AutoZoom];
		if (!controller || controller.zoom <= defaultConfig.minZoom) return [false, lang.zoom.message.minZoomAcheived];
		return [true, lang.zoom.tooltip.zoomOut];
	})();

	return {
		name: 'zoomOut',
		label: lang.zoom.tooltip.zoomOut,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <MdOutlineZoomOut {...props}/>,
		disabled: !enabled,
		value: controller?.zoom ?? 100,
		hidden: false,
		handler: () => {
			const newZoom = (controller?.zoom ?? 100) - defaultConfig.zoomStep;
			if (controller) controller.zoom = newZoom < defaultConfig.minZoom ? defaultConfig.minZoom : newZoom;
		}
	};
};
