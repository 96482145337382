
import { Box, HStack } from '@chakra-ui/react';
import { MdOutlineKeyboardArrowUp, MdOutlinePersonPin } from 'react-icons/md';

import { RtaIconButton } from '@components';

export const CommentControls = () => {
	return (
		<Box w={'100%'} h={'100%'} m={'1px'} border={'0px'}>
			<HStack h={'100%'} gap={0}>
				<RtaIconButton enabled={false} icon={<MdOutlinePersonPin/>} label="Add Comment"/>
				<RtaIconButton enabled={false} icon={<MdOutlineKeyboardArrowUp/>} label="Open Comment Panel"/>
				{/* <Divider orientation="vertical" h={'100%'} /> */}
			</HStack>
		</Box>
	);
};
