/* eslint-disable max-statements */
import { ComponentController as BpController } from '@rta/rta-blueprint-component';
import { useContext, useEffect, useState } from 'react';

import { Controller } from '../context/customer-designs/controller.interface';
import { useNavigate } from 'react-router';
import { AppContext, DesignState } from '@context';
import { ContentPanelMode, selectContentPanelMode, selectFeatureState, selectNavigation, setContentPanelMode, useAppDispatch, useAppSelector } from '@state';

export const useDesign = <C = Controller, S = object>(): [C & S | null, DesignState | null] => {
	const { appState: { customerDesigns: designs }} = useContext(AppContext);
	const [designContext, setDesignContext] = useState<DesignState | null>(null);
	const navigate = useNavigate();

	const appNav = useAppSelector(selectNavigation);
	const contentMode = useAppSelector(selectContentPanelMode);
	const featureState = useAppSelector(selectFeatureState);
	const dispatch = useAppDispatch();

	useEffect(() => {
		let design = designs.designs.find((bp) => bp.blueprint.id === appNav.designId) ?? null;
		if (appNav.designId && !design) {
			// if design is not found, navigate to the user's first design
			design = designs.designs[0] ?? null;
			if (appNav.designId && design) {
				navigate(`/r/${appNav.email}/${design.blueprint.id}`);
			} else {
				// no designs, default to newSubmission
				navigate(`/r/${appNav.email}`);
			}
		} else {
			if (contentMode !== ContentPanelMode.Designer && contentMode !== ContentPanelMode.ThreeD && appNav.designId !== null) {
				dispatch(setContentPanelMode(ContentPanelMode.Designer));
			}

			setDesignContext(design);
		}
	}, [appNav.designId]);

	if (!appNav.designId) return [null, null];

	let controller = null;
	if (contentMode === ContentPanelMode.ThreeD) {
		controller = designContext?.tdController;
	} else if (contentMode === ContentPanelMode.Designer) {
		controller = designContext?.bpController;
	}

	if (appNav.designId !== designContext?.blueprint.id) return [null, null];

	// setup controllers based on feature state
	if (controller instanceof BpController) {
		controller.options.autoZoom = featureState.zoom.autoZoom;
	}

	// console.log('useController -> Exit', controller?.id ?? null, bpId, contentMode, controller?.zoom);
	return [controller as C & S ?? null, designContext ?? null];
};
