/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';

import { Feature } from '.';

export const featureHook = <R>(feature: Feature<R>): Feature<R> => {
	const { confirm = null, modal = null } = feature;
	const [confirmState, confirmCb] = confirm ?? [null, null];
	const [modalState, modalCb] = modal ?? [null, null];

	if (!feature._handler && feature.handler) feature._handler = feature.handler;

	feature.handler = () => {
		if (feature.reset) feature.reset();

		if (confirmState && confirmCb) {
			confirmCb();
		} else if (modalState && modalCb) {
			modalCb();
		} else if (feature._handler) {
			feature._handler();
			if (feature.reset) feature.reset();
		}
	};


	useEffect(() => {
		if (confirmState && confirmState.confirmed !== null) {
			if (modalState && modalState.data === null) {
				if (modalCb) modalCb();
			} else {
				if (feature._handler) feature._handler();
				if (feature.reset) feature.reset();
			}
		}
	}, [confirmState]);

	useEffect(() => {
		if (modalState && modalState.data !== null) {
			feature.value = modalState.data as R;
			if (feature._handler) feature._handler();
			if (feature.reset) feature.reset();
		}
	}, [modalState]);

	return feature;
};
