const dockConfigFactory = () => {
	const h = {
		headerBar: 65,
		optionsBar: 35
	};

	const w = {
		action: 175,
		menu: 45
	};

	const config = {
		top: {
			height: h.headerBar + h.optionsBar,
			bgColor: 'unset',
			header: {
				height: h.headerBar
			},
			options: {
				height: h.optionsBar
			}
		},
		bottom: {
			height: 40,
			bgColor: 'unset'
		},
		left: {
			minWidth: 0,
			maxWidth: w.action + w.menu,
			duration: 1,
			bgColor: 'unset',
			menu: {
				width: w.menu
			},
			action: {
				width: w.action
			}
		},
		right: {
			minWidth: 0,
			maxWidth: 300,
			duration: 1,
			bgColor: 'unset'
		}
	};

	return config;
};

export const dockConfig = dockConfigFactory();
