import {
	Box,
	Flex,
	Grid,
	HStack,
	List,
	ListItem,
	Text
} from '@chakra-ui/react';

import { EstimateItemizedContent } from './estimate-itemized-content';
import { formatPrice } from '../../../helpers';
import { RtaFeatureIconButton } from '@components';
import { useAppSelector } from '@state';
import { useEstimateDownloadFeature } from '@features/document';
import { useDesign, useTax } from '@hooks';

export const EstimatePanel = () => {
	const [controller] = useDesign();
	const [tax] = useTax();

	const kitchenData = controller?.kitchen ?? null;
	const itemData = kitchenData?.itemizedMsrp ?? null;

	const estimate = useEstimateDownloadFeature({ tax });

	if (!kitchenData) return null;

	let total = 0;
	if (itemData !== null) {
		total = (itemData?.subtotal ?? 0) + (itemData?.tax ?? 0);
	}

	const headerHeight = '35px';

	return kitchenData
		? (<>
			<Box width="100%" height="100%" overflowY="hidden">
				<Grid width="100%" height="100%" templateRows="auto 1fr 100px">
					<HStack h={headerHeight} w="full" background="blue.400" px={6} py={2} justifyContent={'center'} mb={2}>
						<Text textAlign={'center'} color={'white'} fontSize={18} position="absolute">ITEMIZED ESTIMATE</Text>
						<RtaFeatureIconButton feature={estimate} iconProps={{ fontSize: '0.75em' }} alignContent="end" position="absolute" top="5px" right="5px"/>
					</HStack>
					<EstimateItemizedContent kitchenData={kitchenData} itemData={itemData}/>
					<Box pl="10px" pr="10px" pt=".5em" mt="5px" borderTop={'1px'} >
						<List spacing={2} fontSize={14}>
							<ListItem key="subtotal">
								<Flex justifyContent={'space-between'}>
									<Text>SUBTOTAL</Text>
									<Text>{formatPrice(itemData?.subtotal ?? 0)}</Text>
								</Flex>
							</ListItem>
							<ListItem key="salestax">
								<Flex justifyContent={'space-between'}>
									<Text>SALES TAX</Text>
									<Text>{itemData?.tax ? formatPrice(itemData.tax) : '---.--'}</Text>
								</Flex>
							</ListItem>
							<ListItem key="total">
								<Flex justifyContent={'space-between'}>
									<Text>TOTAL</Text>
									<Text>{formatPrice(total)}</Text>
								</Flex>
							</ListItem>
						</List>
					</Box>
				</Grid>
			</Box>
		</>)
		: (
			<Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
				<Text>Select a blueprint to view the estimate</Text>
			</Box>
		);
};
