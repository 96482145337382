import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';
import { selectSelectedPromotionalSku, setSelectedPromotionalSku, useAppDispatch, useAppSelector } from '@state';

type PromotionalSkuFeatureParams = FeatureHoookParams;
export const useAddPromotionalSkuFeature = (_params: PromotionalSkuFeatureParams = {}): Feature<string> => {
	const [controller] = useDesign();
	const dispatch = useAppDispatch();
	const selectedPromotionalSku = useAppSelector(selectSelectedPromotionalSku);

	const kitchen = controller?.kitchen ?? null;

	const [disabled, tooltip] = (() => {
		if (selectedPromotionalSku.length !== 0) return [false, lang.promotionalSku.tooltip.addSku];
		return [true, lang.promotionalSku.tooltip.selectSku];
	})();

	return {
		name: 'add-promotional-sku',
		label: lang.promotionalSku.label.add,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled,
		value: selectedPromotionalSku[0],
		hidden: false,
		handler: () => {
			selectedPromotionalSku.forEach((option) => {
				kitchen?.promotionalSkus.newSku(option);
			});
			dispatch(setSelectedPromotionalSku([]));
		}
	};
};
