import { styleSheet } from '../../style-sheet';
import { Text, View } from '@react-pdf/renderer';

export type ProposalDocumentRevisionLogProps = {
	project: any,
	date: string
}

export const ProposalDocumentPageRevisionLog = ({ project, date }:ProposalDocumentRevisionLogProps) => {
	return (
		<View style={styleSheet.containerCenter}>
			<View style={[styleSheet.tableRevisionHeaderRow, styleSheet.tableRevision2BorderBottom]}>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}>Rev</Text>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}>Date</Text>
				<Text style={[styleSheet.tableRevisionCellDescription, styleSheet.tableRevisionBorderRight]}>Description</Text>
				<Text style={[styleSheet.tableRevisionCell]}>Engineer</Text>
			</View>
			<View style={[styleSheet.tableRevisionRow, styleSheet.tableRevisionBorderBottom]}>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}>-</Text>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}>{date}</Text>
				<Text style={[styleSheet.tableRevisionCellDescription, styleSheet.tableRevisionBorderRight]}>Initial BP Creation</Text>
				<Text style={styleSheet.tableRevisionCell}>
					{project[0].designer?.firstName.charAt(0).toUpperCase()}{project[0].designer?.lastName.charAt(0).toUpperCase()}</Text>
			</View>
			<View style={[styleSheet.tableRevisionRowAlt, styleSheet.tableRevisionBorderBottom]}>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={[styleSheet.tableRevisionCellDescription, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={styleSheet.tableRevisionCell}> </Text>
			</View>
			<View style={[styleSheet.tableRevisionRow, styleSheet.tableRevisionBorderBottom]}>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={[styleSheet.tableRevisionCellDescription, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={styleSheet.tableRevisionCell}> </Text>
			</View>
			<View style={[styleSheet.tableRevisionRowAlt, styleSheet.tableRevisionBorderBottom]}>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={[styleSheet.tableRevisionCellDescription, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={styleSheet.tableRevisionCell}> </Text>
			</View>
			<View style={[styleSheet.tableRevisionRow, styleSheet.tableRevisionBorderBottom]}>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={[styleSheet.tableRevisionCellDescription, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={styleSheet.tableRevisionCell}> </Text>
			</View>
			<View style={[styleSheet.tableRevisionRowAlt, styleSheet.tableRevisionBorderBottom]}>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={[styleSheet.tableRevisionCell, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={[styleSheet.tableRevisionCellDescription, styleSheet.tableRevisionBorderRight]}> </Text>
				<Text style={styleSheet.tableRevisionCell}> </Text>
			</View>
		</View>
	);
};
