import { extendTheme } from '@chakra-ui/react';
import { theme } from '@chakra-ui/pro-theme';

import { initialColorMode } from './initial-color-mode';
import { zIndices } from './z-indices';

const proTheme = extendTheme(theme);

const extendedConfig = {
	colors: { ...proTheme.colors,
		brand: proTheme.colors.teal },
	fonts: {
		heading: '\'Proxima Nova\', \'Montserrat\', -apple-system, system-ui, sans-serif',
		body: '\'Proxima Nova\', \'Montserrat\', -apple-system, system-ui, sans-serif'
	},
	z: { ...zIndices },
	...initialColorMode
};

export const rtaTheme = extendTheme(extendedConfig, proTheme);
