// import crypto from 'crypto';

// crypto is not working with webpack 5, defaulting to pure base64
export const encrypt = (text: string) => {
	// const algorithm = 'aes-256-ctr';
	// // deepcode ignore HardcodedSecret: not to concerned here, no personal data if exposed, just don't want to make it easy
	// const secretKey = process.env.REACT_APP_SECRET_KEY ?? null;

	// if (secretKey === null) { throw new Error('REACT_APP_SECRET_KEY is not defined'); }

	// const iv = crypto.randomBytes(16);
	// // deepcode ignore InsecureCipherNoIntegrity: <please specify a reason of ignoring this>
	// const cipher = crypto.createCipheriv(algorithm, secretKey, iv);

	// const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
	// const encryptedValue = JSON.stringify({
	// 	iv: iv.toString('hex'),
	// 	content: encrypted.toString('hex')
	// });

	// return Buffer.from(encryptedValue).toString('base64');
	return btoa(text);
};
