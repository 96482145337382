import { slice } from './slice';

// Action creators are generated for each case reducer function.
export const {
	setActionPanelIsOpen,
	setActionPanelMode,
	setContentPanelMode,
	setContextPanelIsOpen,
	setEstimatePanelIsOpen,
	setFullScreenMode,
	pushContextPanel,
	popContextPanel
} = slice.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
	selectActionPanelIsOpen,
	selectActionPanelMode,
	selectContentPanelMode,
	selectContextPanel,
	selectContextPanelCount,
	selectContextPanelIsOpen,
	selectEstimatePanelIsOpen,
	selectIsFullScreen,
	selectLeftDock,
	selectLeftDockIsOpen,
	selectRightDock,
	selectRightDockIsOpen
} = slice.selectors;
