import { Provider } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useRef } from 'react';

import { AppRootContextProvider } from './app-root-context-provider';
import { makeStore } from '../state-management/store';

export const AppGlobalProvider = () => {
	const auth = useAuth0();
	const { getAccessTokenSilently } = auth;
	const storeRef = useRef<ReturnType<typeof makeStore>>();

	if (!storeRef.current) {
		storeRef.current = makeStore(getAccessTokenSilently, {});
	}

	// console.log('render:AppGlobalProvider');
	return (
		<Provider store={storeRef.current}>
			<AppRootContextProvider />
		</Provider>
	);
};
