import {
	createBrowserRouter,
	Navigate,
	RouterProvider
} from 'react-router-dom';

import { AppAuthProvider } from '.';

export const AppRouter = () => {
	const child = <AppAuthProvider/>;
	const router = createBrowserRouter([
		{
			path: '/',
			element: child,
			children: [
				{ path: '/r', element: child },
				{ path: '/r/:email', element: child },
				{ path: '/r/:email/:designId', element: child },
				{ path: '/r/:email/:designId/:version', element: child },
				{ path: '/v/:email/:designId/:version', element: child },
				{ path: '/v/:designHash', element: child },
				// { path: '/', element: <Navigate to="/r/brianbirdmail@gmail.com"/> },
				{ path: '*', element: <Navigate to="/r"/> }
			]
		}
	], {
		future: {
			// eslint-disable-next-line camelcase
			// v7_normalizeFormMethod: true
		}
	});

	// console.log('render:AppRouter');
	return <RouterProvider router={router}/>;
};
